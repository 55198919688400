/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Common Application utilities
 *
 * @file CommonUtils.js
 * @author Prithviraj K
 */
import React from 'react';
import Framework from '@greenville/framework';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getSnapshot } from 'mobx-state-tree';
import { EventProvider } from '@aquila/core';
import {
  deviceType, isChrome, isFirefox, isSafari, isOpera, isIE, isEdge, osName
} from 'react-device-detect';
import { address } from 'ip';
import publicIp from 'public-ip';
import ipaddr from 'ipaddr.js';
import env from '../env';
import PathUtils from './PathUtils';
import * as constants from '../constants';
import * as GAConstants from '../GAConstants';
import Chatbot from './ChatbotScript';
import { version } from '../../../package.json';
import UserPreferencesService from '../../modules/authhome/service/UserPreferencesService';
import BrazePlugin from '../../modules/braze';

const userPreferencesService = new UserPreferencesService();
export default class CommonUtils {
  static loadUuid = this.getUUID();

  static loadDt = new Date().toISOString();

  static userLocalLoadDt = CommonUtils.getLocalIsoTime();

  static loadTime = new Date().getTime();

  static observer = null;

  static elementIntersectObserver = null;

  static userEntitlementMixLabel = null;

  static isHEIUserStatus = false;

  static controller = null;

  /**
   * Get user_entitlement_mix
   *
   * @param {*} books
   * @returns
   */

  static getUserEntitlementMix = (books) => {
    if (this.userEntitlementMixLabel) {
      return this.userEntitlementMixLabel;
    }
    const {
      DIRECT_TO_CONSUMER,
      DIRECT_TO_CONSUMER_IA,
      DIRECT_TO_CONSUMER_HEI,
      COURSE_WARE,
      CG_RENEWALS,
      DIRECT_TO_LEARNER,
      DIRECT_TO_LEARNER_RENEWAL,
      INTEGRATED_SMS,
      INTEGRATED_RUMBA,
      DIGITAL_SAMPLE_MARKETING,
      DIGITAL_SAMPLE_SELF,
      DIGITAL_SAMPLE_REP
    } = constants;
    const userEntitlementMixLabel = [];

    const bmcMapping = {
      [DIRECT_TO_CONSUMER]: 1,
      [DIRECT_TO_CONSUMER_IA]: 2,
      [DIRECT_TO_CONSUMER_HEI]: 3,
      [COURSE_WARE]: 4,
      [CG_RENEWALS]: 5,
      [DIRECT_TO_LEARNER]: 6,
      [DIRECT_TO_LEARNER_RENEWAL]: 7,
      [INTEGRATED_SMS]: 8,
      [INTEGRATED_RUMBA]: 9,
      [DIGITAL_SAMPLE_MARKETING]: 10,
      [DIGITAL_SAMPLE_SELF]: 11,
      [DIGITAL_SAMPLE_REP]: 12,
      [constants.USER_ENTITLEMENT_MIX_LABEL.OTHER]: 13
    };

    const activeBooks = this.getActiveBooks(books);

    activeBooks.forEach((book) => {
      const businessModeCode = book.product_entitlements?.business_model_code;
      if (businessModeCode) {
        const bmcMappingNumber = bmcMapping[businessModeCode] || bmcMapping[constants.USER_ENTITLEMENT_MIX_LABEL.OTHER];
        if (bmcMappingNumber && !userEntitlementMixLabel.includes(bmcMappingNumber)) {
          userEntitlementMixLabel.push(bmcMappingNumber);
        }
      }
    });

    this.userEntitlementMixLabel = userEntitlementMixLabel.join(',') || null;

    return this.userEntitlementMixLabel;
  }

  /**
   * Function to Initiate Google Analytics
   */
  static initializeGTM() {
    if (!window.google_tag_manager) {
      Framework.getEventManager().publish(constants.GTM_REQUESTED);
    }
  }

  /**
   * Function to lazyload and preload component
   *
   * @param {Module} component
   */
  static lazyWithPreload(component) {
    const lazyComponent = React.lazy(component);
    lazyComponent.preload = component;
    return lazyComponent;
  }

  static tocList(toc, pageId) {
    let found;
    const findAsset = (tocData) => {
      tocData.forEach((child) => {
        if (child.id === pageId) {
          found = child;
        }
        if (child.children) { findAsset(child.children); }
      });
      return found;
    };
    findAsset(toc);
    return found;
  }

  static getBrowserName() {
    let browserName = '';
    switch (true) {
      case isChrome:
        browserName = 'Chrome';
        break;
      case isFirefox:
        browserName = 'Firefox';
        break;
      case isSafari:
        browserName = 'Safari';
        break;
      case isOpera:
        browserName = 'Opera';
        break;
      case isIE:
        browserName = 'Internet Explorer';
        break;
      case isEdge:
        browserName = 'Edge';
        break;
      default:
        browserName = navigator.appName;
        break;
    }
    return browserName;
  }

  static getIpAddressV6 = () => {
    const ip4 = ipaddr.parse(address());
    return ip4.toIPv4MappedAddress().toString();
  };

  static getDeviceType = () => {
    if (deviceType === 'browser') return 'PC';
    return deviceType;
  }

  static pushGlobalParams() {
    const globalParams = {
      app_id: env.PRODUCT && env.PRODUCT.toUpperCase(),
      app_version: version,
      user_timezone_offset: Math.abs(new Date().getTimezoneOffset() / 60),
      web_browser: this.getBrowserName(), // Browvser Name
      device_type: this.getDeviceType(), // Device Type, Ex: PC, Tablet, Phone
      device_id: (window.piSession && window.piSession.getDeviceId()) || null,
      operating_system_code: osName,
      environment_code: env.ENVIRONMENT.toUpperCase(),
      ga_client_id: env.CLIENT_ID
    };

    return EventProvider.getEventManager().dispatch(globalParams);
  }

  static initTelemetryPageAction() {
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMTRY_INIT
    });
  }

  static logUserTelemetryEvent(course, userId) {
    const userParams = {
      person_id: userId,
      person_id_type: constants.ID_TYPE,
      person_role_code: course.role || 'Student',
      login_session_id: window.piSession && window.piSession.getContextId(), // PI Session Id of the logged user
      secondary_person_id: null,
      secondary_person_id_type: null,
      secondary_person_role_code: null,
      organization_id: course.organizationId || null,
      organization_id_type: constants.ORGANIZATION_ID_TYPE
    };
    return EventProvider.getEventManager().dispatch(userParams);
  }

  static logTelemetryPageAction(event) {
    const {
      category,
      action,
      label,
      value
    } = event;
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMETRY_PAGE_ACTION,
      eventCategory: category || 'NA',
      eventAction: action || 'NA',
      eventLabel: label || 'NA',
      eventValue: value || 'NA',
      transactionDt: new Date().toISOString()
    });
  }

  static logTelemetryPageError(error) {
    const {
      errorCode,
      errorUrl,
      errorMessage,
      errorStateCode,
      errorDetail
    } = error;
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMETRY_PAGE_ERROR,
      errorCode,
      errorUrl,
      errorMessage,
      errorStateCode,
      errorDetail,
      transactionDt: new Date().toISOString()
    });
  }

  static getUUID() {
    const crypto = window.crypto || window.msCrypto;
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (crypto && (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)));
  }

  static getLocalIsoTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
  }

  /**
   * Function to load the PLA events
   * @param {object} model - Model
   * @param {string} pageId - pageId
   * @param {Boolean} isFromCourse - Is from course
   * @param {string} previousPageId - pageId
   */

  static sendCommonPLAEvents(model, pageId, isFromCourse, previousPageId, loadType, navData) {
    const product = isFromCourse ? model.getProduct() : model;
    const currentTime = new Date().getTime();
    const durationSeconds = ((currentTime - this.loadTime) / 1000).toFixed(0);
    const unloadUuId = this.loadUuid;
    const updatedLoadId = this.getUUID();
    const unloadDt = this.loadDt;
    const updatedLoadDt = new Date().toISOString();
    const unloadUserLocalLoadDt = this.userLocalLoadDt;
    const updatedUserLocalLoadDt = this.getLocalIsoTime();
    product.setPLAEvents(model, pageId, isFromCourse, previousPageId, durationSeconds, updatedLoadId, updatedLoadDt,
      updatedUserLocalLoadDt, unloadUuId, unloadDt, unloadUserLocalLoadDt, loadType, navData);
    this.loadTime = new Date().getTime();
    this.loadUuid = updatedLoadId;
    this.loadDt = updatedLoadDt;
    this.userLocalLoadDt = updatedUserLocalLoadDt;
  }

  /**
   * Function to set audio  url
   * @param {array} audioUri - File Name
   * @param {string} metaDataVersionId - Version ID
   * @param {string} metaDataAssetTypeUri - Asset Type URL
   * @param {string} metaBasePath - Base Path
   */

  static setUrl(audioUri, metaDataVersionId, metaDataAssetTypeUri, metaBasePath) {
    // TODO: Need to fix it from service end by adding flag for human readable audio
    const audioUrl = audioUri.map(val => (val.startsWith('http') ? `${val}` : `${metaBasePath}${metaDataVersionId}${metaDataAssetTypeUri}${val}`));
    return audioUrl;
  }

  /**
   * Function to set pdf audio url
   * @param {array} audioUri - File Name
   * @param {object} asset - Asset values
   */
  static setPdfAudioUrl(audioUri, asset) {
    const metaBasePath = asset.audioMetadata.basePath;
    const metaDataVersionId = asset.audioMetadata.assetTypes[0].versionId;
    const metaDataAssetTypeUri = asset.audioMetadata.assetTypes[0].uri;
    const pdfAudioUrl = this.setUrl(audioUri, metaDataVersionId, metaDataAssetTypeUri, metaBasePath);

    return pdfAudioUrl;
  }

  /* Function to decode string to object
   * @param {string} value
   */

  static deCodeBase64(value) {
    // eslint-disable-next-line no-undef
    const base64Str = atob(value);
    const base64 = JSON.parse(base64Str);
    return base64;
  }

  /**
   * Function to encode from object to string
   * @param {object} obj
   */

  static enCodeBase64(obj) {
    const base64 = JSON.stringify(obj);
    // eslint-disable-next-line no-undef
    const base64Str = btoa(base64);
    return base64Str;
  }

  /**
   * Function to set cookie
   * @param {string} cookiename
   * @param {string} value
   * @param {integer} maxAge
   */

  static setCookie(name, value, maxAge) {
    const date = new Date();
    date.setTime(date.getTime() + maxAge);
    const domain = '.pearson.com';
    document.cookie = `${name}=${value};path=/;domain=${domain};expires=${date.toGMTString()}`;
  }

  /**
   * Function to get cookie token
   * @param {string} cname
   */
  static getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Function to trigger GTM
   * when user clicks on disconnect
   * @param {string} deviceId
   */
  static deviceDisconnectEvent(deviceId) {
    const deviceDisconnectEvent = {
      category: constants.DISCONNECT_DEVICE,
      event_category: 'Device Management',
      event_action: 'Disconnect Device',
      event_label: 'deviceId',
      event_value: deviceId,
      event: constants.DISCONNECT_DEVICE,
      disconnected_device_id: deviceId,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      message_id: this.getUUID(),
      event_unix_timestamp: new Date().getTime()
    };

    publicIp.v4().then(
      (ipAddress) => {
        deviceDisconnectEvent.disconnected_device_ip = ipAddress;
        EventProvider.getEventManager().dispatch(deviceDisconnectEvent);
      }
    );
  }
  /**
    * Function to trigger device mangement otp and link events
    * when otp and link is clicking
    * 
    */
  static deviceManagementOTPEvents(eventName, category, action, status) {
    const otpEvent = {
      event: eventName,
      event_category: category,
      event_label: status,
      transaction_local_dt: CommonUtils.getLocalIsoTime(),
      user_local_event_dt: CommonUtils.getLocalIsoTime(),
      message_id: CommonUtils.getUUID(),
      event_unix_timestamp: new Date().getTime(),
      event_action: action
    };
    EventProvider.getEventManager().dispatch(otpEvent);
  }
  /**
    * Function to trigger device mangement send and resend otp and link events
    * when otp and link is clicking
    * 
    */
  static deviceManagementReSentOTPEvents(eventName) {
    let otpEvent = {
      event: eventName,
      transaction_dt: new Date().toISOString(),
      transaction_local_dt: CommonUtils.getLocalIsoTime(),
      message_id: CommonUtils.getUUID()
    };
    EventProvider.getEventManager().dispatch(otpEvent);
  }

  /**
   * Function to trigger Account setting
   */
  static accountSettingEvent() {
    const deviceDisconnectEvent = {
      event: constants.ACCOUNT_SETTING,
      event_action: '',
      transaction_local_dt: this.getLocalIsoTime(),
      message_id: this.getUUID()
    };
    EventProvider.getEventManager().dispatch(deviceDisconnectEvent);
  }

  /**
   * Function to trigger page view for bookshelf
   */
  static sendPageViewEvent() {
    const navigationEvent = {
      event: constants.PAGE_VIEW,
      page_name: constants.MOJO_HOME,
      content_id_type: env.PRODUCT && env.PRODUCT.toUpperCase(),
      message_id: this.getUUID(),
      transaction_dt: new Date().toISOString(), // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      transaction_local_dt: CommonUtils.getLocalIsoTime() // "2021-05-04T17:38:48:195"
    };
    EventProvider.getEventManager().dispatch(navigationEvent);
  }

  /**
   * Function to get cookie expire time
   */

  static getExpireTime() {
    const currentTime = new Date();
    const currentGetTime = currentTime.getTime();
    const expireTime = currentGetTime + 1000 * 36000;
    currentTime.setTime(expireTime);
    return currentTime.toUTCString();
  }

  /**
   * Function to push the launch study telemetry event
   */
  static launchStudyPush = () => {
    const launchStudy = {
      event: constants.LAUNCH_STUDY_EVENT,
      event_action: constants.LAUNCH_STUDY_EVENT_ACTION,
      event_category: constants.DRAWER_PANEL_STUDY,
      event_label: constants.LAUNCH_STUDY_EVENT_LABEL,
      event_unix_timestamp: new Date().getTime(),
      event_value: constants.DRAWER_PANEL_STUDY,
      launch_method: constants.BOOKSHELF,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime()
    };
    EventProvider.getEventManager().dispatch(launchStudy);
  }

  /**
   * Function to get page name
   * @param {string} pageId
   */
  static getPageName(pageId) {
    const assetInfo = Framework.getStoreRegistry().getStore('asset');
    const slatesInfo = assetInfo && assetInfo.slates && assetInfo.slates.length > 0 && CommonUtils.tocList(assetInfo.slates, pageId);
    const childrenInfo = assetInfo && assetInfo.children && assetInfo.children.length > 0 && CommonUtils.tocList(assetInfo.children, pageId);
    const pageName = (slatesInfo && slatesInfo.title) || (childrenInfo && childrenInfo.title) || this.getAppTitle();
    return pageName;
  }


  /**
    * get Theme based on Domain
    */
  static getAppTheme() {
    this.theme = constants.STANDARD_THEME;
    if (window.location.hostname.includes(constants.PLUS)) {
      this.theme = constants.PLUS;
    }

    return this.theme;
  }

  static getAppTitle() {
    this.title = constants.TITLE;
    if (window.location.hostname.includes(constants.PLUS)) {
      this.title = constants.PEARSON_PLUS_TITLE;
    }

    return this.title;
  }

  /**
   * Function to redirect
   * @param {string} businessModelCode
   * @param {boolean} tpiFlag
   */
  static redirectToUrl(businessModelCode, tpiFlag, sourceFrom = null) {
    const {
      INTEGRATED_RUMBA,
      EREADER_UTM_SOURCE
    } = constants;
    if (sourceFrom) {
      const mlmHome2RedirectUrl = CommonUtils.getBase64Decoded(sourceFrom);

      if (mlmHome2RedirectUrl) {
        return mlmHome2RedirectUrl;
      }
    }

    const isIntegratedRumba = businessModelCode === INTEGRATED_RUMBA || (this.canadianProxy && this.canadianProxy === '1');
    const queryParamUrl = PathUtils.getQueryParameterbyName('redirectURL');
    // To identify source from normal home or home2 for MLM route
    const authHomeRoute = (sourceFrom && typeof sourceFrom === 'string' && sourceFrom.toLowerCase() === constants.HOME2) ? 'home2' : 'home';
    // commented below for redirecting console page to authhome
    // || PathUtils.getQueryParameterbyName('returnUrl')
    // || PathUtils.getQueryParameterbyName('returnurl');
    let redirectUrl = `${env.CONTENT_HOST}/${authHomeRoute}?${EREADER_UTM_SOURCE}`;
    if (tpiFlag || (window.location.hostname.includes(constants.ETEXT) && !queryParamUrl)) {
      redirectUrl = `${env.CONTENT_HOST}/${authHomeRoute}?${EREADER_UTM_SOURCE}&isTpi=Y`;
    } else if (!sourceFrom && ((queryParamUrl && isIntegratedRumba) || (queryParamUrl))) {
      redirectUrl = PathUtils.addQueryParameter(EREADER_UTM_SOURCE, queryParamUrl);
    }
    return redirectUrl;
  }

  /**
   * Function to get glossary language from pdf locale
   * @param {string} language
   */
  static getGlossaryLanguage(language) {
    const frenchLocale = [constants.FR_FR, constants.FR_CA];
    let glossaryLanguage = '';

    if (language.indexOf('-') > -1) {
      if (frenchLocale.includes(language)) {
        glossaryLanguage = constants.FR_FR.replace('-', '_');
      } else {
        glossaryLanguage = language.replace('-', '_');
      }
    } else {
      switch (language) {
        case constants.JA:
          glossaryLanguage = constants.JA_JP;
          break;
        case constants.KO:
          glossaryLanguage = constants.KO_KR;
          break;
        default:
          glossaryLanguage = language.concat('_', language);
          break;
      }
    }

    return glossaryLanguage;
  }

  /**
   * Check session and logout if empty
  */
  static checkSession() {
    // TODO: Session needs to be checked properly since hasValidSession doesn't work as expected
    if (window.piSession.getContextId() === '') {
      window.location.reload();
    }
  }

  /**
   * Function to push telementry/GA event
   * @param {string} event_category
   * @param {string} event_name
   * @param {string} event_action
   * @param {string} event_label
   * @param {object} customFields or extraFields
   */
  static dispatchGaEvent(eventCategory, event, action, label, customFields = null) {
    let eventContent = {
      event_category: eventCategory,
      event_action: action,
      event_label: label,
      event,
      event_name: event,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      event_unix_timestamp: new Date().getTime(),
      message_id: this.getUUID()
    };
    if (customFields) {
      eventContent = { ...customFields, ...eventContent };
    }
    EventProvider.getEventManager().dispatch(eventContent);
  }

  /**
   * Function to push Resend telementry/GA event
   * @param {string} event_category
   * @param {string} event_name
   * @param {string} event_action
   */
  static dispatchResendGaEvent(eventCategory, event, action) {
    const eventContent = {
      event_category: eventCategory,
      event_action: action,
      event,
      event_name: event,
      transaction_dt: new Date().toISOString(),
      transaction_local_dt: CommonUtils.getLocalIsoTime(),
      message_id: CommonUtils.getUUID()
    };
    EventProvider.getEventManager().dispatch(eventContent);
  }

  /**
   * Returns true if user has a subscription with the provided source
   *
   * @param {Object} userDetail
   * @param {string} subscriptionSource
   * @param {Array} statusList
   * @param {Array} entitlementLevelList
   */
  static hasSubscriptionWithSource(
    userDetail,
    subscriptionSource,
    statusList = [constants.ACTIVE, constants.PAUSED],
    entitlementLevelList = [constants.ENTITLEMENTLEVEL_SINGLE, constants.BUNDLE, constants.ENTITLEMENTLEVEL_MULTI]
  ) {
    return userDetail.gpsSubscriptions.some(
      subscription => subscription?.subscriptionSource === subscriptionSource
        && (subscription?.status && statusList.includes(subscription.status))
        && subscription?.entitlementLevel && entitlementLevelList.includes(subscription.entitlementLevel)
    );
  }

  /**
   * Returns true if user has a subscription
   *
   * @param {Object} userDetail
   * @param {Array} statusList
   * @param {Array} entitlementLevelList
   */
  static hasSubscription(
    userDetail,
    statusList = [constants.ACTIVE, constants.PAUSED],
    entitlementLevelList = [constants.ENTITLEMENTLEVEL_SINGLE, constants.BUNDLE, constants.ENTITLEMENTLEVEL_MULTI]
  ) {
    let hasSubscription = false;

    if (userDetail?.gpsSubscriptions) {
      hasSubscription = userDetail.gpsSubscriptions.some(
        subscription => (subscription?.status && statusList.includes(subscription.status))
          && subscription?.entitlementLevel && entitlementLevelList.includes(subscription.entitlementLevel)
      );
    }

    return hasSubscription;
  }

  /**
   * Method to get books by businessModelCode
   *
   * @param {object} bookShelf
   * @param {string} businessModelCode
   * @param {boolen} expired
   */
  // TODO: This function should adpot with all books not only for pplus
  static getProductByBMC(bookShelf, businessModelCode = [], expired = false) {
    const { books, bannerBook } = bookShelf;
    const originalBooks = bannerBook ? [...books, bannerBook] : [...books];
    return originalBooks.filter(book => book.product_entitlements
      && book.product_entitlements.business_model_code
      && businessModelCode.includes(book.product_entitlements.business_model_code)
      && book.product_entitlements.expired === expired
      && book?.gpsSubscriptionOfBook?.status === constants.ACTIVE);
  }

  /**
   * Function to decode URL
   * @param {string} uri
   * @param {number} mod
   */
  static decodeURIComponentSafe(uri, mod = 0) {
    var out = new String(),
      arr,
      i = 0,
      l,
      x;
    arr = uri.split(/(%(?:d0|d1)%.{2})/);
    for (l = arr.length; i < l; i++) {
      try {
        x = decodeURIComponent(arr[i]);
      } catch (e) {
        x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
      }
      out += x;
    }
    return out;
  }

  static getNoOfDays(date1, date2) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const diffTime = startDate.getTime() - endDate.getTime();
    const noOfDays = Math.ceil(diffTime / (1000 * 3600 * 24));
    return noOfDays;
  }

  //  return true if user role is student else it is instructor
  static getUserRole(books) {
    let isStudent = true;
    if (books && Array.isArray(books) && books.length > 0) {
      isStudent = !books.some((title) => {
        let hasInstructor = false;
        if ((title.product_entitlements.user_role === constants.PDF_CONSTANTS.USER_ROLE.INSTRUCTOR)
          || (title.product_entitlements.user_role === constants.PDF_CONSTANTS.USER_ROLE.EDUCATOR)) {
          hasInstructor = true;
        }
        if (!hasInstructor && title.courses.length > 0) {
          hasInstructor = title.courses.some(course => (course.user_role === constants.PDF_CONSTANTS.USER_ROLE.INSTRUCTOR) || (course.user_role === constants.PDF_CONSTANTS.USER_ROLE.EDUCATOR))
        }
        return hasInstructor;
      });
    } else if (books) {
      const { role, products } = books;
      const cousreProdcut = (products && products.length) ? products[0] : {};
      if ((role && role !== constants.USER_ROLE_STUDENT) || (cousreProdcut.role && cousreProdcut.role !== constants.USER_ROLE_STUDENT)) {
        isStudent = false;
      }
    }
    return isStudent;
  }

  /**
   *
   * @param {Object} user
   * return menu array based on user
   */
  static getAccountMenuListItems(user, isGhostAccount = false) {
    const language = Framework.getStoreRegistry().getStore('language');
    const isPPlusUser = this.isPPlusUser(user);
    const isSsoUser = !!user.socialLink;
    let accountMenuList = [];
    const helpCenterMenu = {
      title: language.getMessage('menu.helpCenter'),
      url: isPPlusUser ? env.ALGOLIA_SEARCH_CONFIG.helpCenter : env.HELP_CENTER_NON_MOJO,
      original: constants.ACCOUNT_SETTINGS_HELP_CENTER,
      isNewTarget: true,
      disabled: false
    };
    if (!isGhostAccount) {
      accountMenuList = [
        {
          title: language.getMessage('menu.myAccount'),
          url: env.ALGOLIA_SEARCH_CONFIG.accountUrl,
          original: constants.ACCOUNT_SETTINGS_MYACCOUNT,
          isNewTarget: false,
          disabled: false
        },
        {
          title: language.getMessage('menu.preferencesCenter'),
          url: env.ALGOLIA_SEARCH_CONFIG.preferencesCenter,
          original: constants.ACCOUNT_SETTINGS_PREFERENCES_CENTER, // original is used for Eventing - need to change the name of the attr
          isNewTarget: true,
          disabled: false
        },
        {
          title: !isSsoUser ? language.getMessage('device.ACCESS_CHANGE_PASSWORD') : language.getMessage('device.ACCESS_RESET_PASSWORD'),
          url: !isSsoUser ? env.ALGOLIA_SEARCH_CONFIG.changePassword : env.ALGOLIA_SEARCH_CONFIG.resetPassword,
          original: !isSsoUser ? constants.ACCOUNT_SETTINGS_CHANGE_PASSWORD : constants.ACCOUNT_SETTINGS_RESET_PASSWORD,
          isNewTarget: true,
          disabled: this.isInternalUser(user)
        }
      ];
    }
    accountMenuList = [...accountMenuList, helpCenterMenu];

    return accountMenuList;
  }

  /**
   * Returns true if gps subscription's status contains either ACTIVE or PAUSED
   * In authome we will get userSubscription and for ereader we will get productSubscription
   *
   * @param {Object} userOrProductSubscription
   * @returns
   */
  static isPPlusUser(userOrProductSubscription, location = constants.AUTH_HOME) {
    let isPPlusUser = false;

    if (location === constants.AUTH_HOME) {
      if (userOrProductSubscription && userOrProductSubscription.gpsSubscriptions) {
        isPPlusUser = userOrProductSubscription.gpsSubscriptions.some(subscription => subscription.status === constants.ACTIVE
          || subscription.status === constants.PAUSED);
      }
    } else {
      isPPlusUser = userOrProductSubscription?.status === constants.ACTIVE || userOrProductSubscription?.status === constants.PAUSED;
    }

    return isPPlusUser;
  }

  /**
   *
   * @param {string} flagName
   * @param {object} flags
   * @param {object} language
   * @param {language} language
   * return to show or hide the Mondly,Trubo,ITpro component
   */

  static hasShown = (flagName, flags, userdetails, ldClient) => {
    const { dob, studyLevel, homeCountryCode } = userdetails || {};
    let isShown = false;
    switch (flagName) {
      case constants.LD_COMPONENTS_NAME.TURBO: {
        const currentUserAge = CommonUtils.getAge(dob);
        const isHighSchoolStudent = studyLevel === constants.HIGH_SCHOOL_STUDENT;
        const isInstructor = studyLevel === constants.FACULTY_OR_INSTRUCTOR;
        const isRoleAndAgeNotSet = !studyLevel && currentUserAge === null;
        // User age should be greater then or equal to 18 , except if the user role and age is not specified or if the user is an instructor
        const isEligibleAge = (isInstructor || isRoleAndAgeNotSet) ? true
          : currentUserAge >= constants.MINIMUM_TURBO_ELIGIBLE_AGE;
        const isEligibleCountry = (homeCountryCode && homeCountryCode.toUpperCase()) === 'US';

        /* We show turbo if below logic are satisfied
        1) Turbo LD enabled
        2) And user country should be US
        3) AND user role/studylevel should not be HIGH_SCHOOL_STUDENT
        4) AND age should be greater then or equal to 18 ,
        except if the user role and age is not specified or if the user is an instructor
        */
        if (ldClient.variation(flagName, 'false')
          && isEligibleCountry
          && !isHighSchoolStudent
          && isEligibleAge) {
          isShown = true;
        }
        break;
      }
      default:
        isShown = flags[flagName];
        break;
    }
    return isShown;
  }

  /**
   * 
   * @returns footer menu list
   */
  static footerMenuList = (isGhostAccount = false, enableQRcodeFlag) => {
    return ({
      showAppIcon: !enableQRcodeFlag && !isGhostAccount,
      downloadApp: {
        url: env.BOOKSHELF_CONFIG.more.MOJO_APP
      },
      menuList: [{
        id: 'termsofuse',
        title: 'footer.termsOfUse',
        url: env.BOOKSHELF_CONFIG.more.MOJO_TERMS,
        target: '_blank',
        localeString: 'footerterms'
      },
      {
        id: 'privacy',
        title: 'footer.privacy',
        url: env.BOOKSHELF_CONFIG.more.MOJO_PRIVACY,
        target: '_blank',
        localeString: 'footerprivacy'
      },
      {
        id: 'cookies',
        title: 'footer.cookies',
        url: env.BOOKSHELF_CONFIG.more.MOJO_COOKIES,
        target: '_blank',
        localeString: 'footercookies'
      },
      {
        id: 'donotsellmypersonalinformation',
        title: 'footer.doNotSellMyPersonalInformation',
        url: env.BOOKSHELF_CONFIG.more.MOJO_COOKIES,
        target: '_blank',
        localeString: 'footerinfo'
      },
      {
        id: 'accessibility',
        title: 'footer.accessibility',
        url: env.BOOKSHELF_CONFIG.more.MOJO_ACCESSIBILITY,
        target: '_blank',
        localeString: 'footeraccessibility'
      },
      {
        id: 'patentnotice',
        title: 'footer.patentNotice',
        url: env.BOOKSHELF_CONFIG.more.MOJO_NOTICE,
        target: '_blank',
        localeString: 'footerpatentnotice'
      }
      ]
    });
  }

  static algoliaConfig = () => {
    return (
      {
        appId: env.ALGOLIA_SEARCH_CONFIG.appId,
        apiKey: env.ALGOLIA_SEARCH_CONFIG.apiKey,
        productIndex: env.ALGOLIA_SEARCH_CONFIG.productIndex,
        categoryIndex: env.ALGOLIA_SEARCH_CONFIG.categoryIndex,
        algoliaUrl: env.BOOKSHELF_CONFIG.pmcSearch,
        accountUrl: env.ALGOLIA_SEARCH_CONFIG.accountUrl,
        changePassword: env.ALGOLIA_SEARCH_CONFIG.changePassword,
        resetPassword: env.ALGOLIA_SEARCH_CONFIG.resetPassword,
        helpCenter: env.ALGOLIA_SEARCH_CONFIG.helpCenter,
        searchQuery: env.ALGOLIA_SEARCH_CONFIG.searchQuery,
        preferencesCenter: env.ALGOLIA_SEARCH_CONFIG.preferencesCenter,
        searchBarUtmParams: constants.SEARCH_BAR_UTM_PARAM
      }
    );
  }

  static initChatbotScript = (user) => {
    const bookshelfConfig = env.BOOKSHELF_CONFIG;
    if (this.isPPlusUser(user)) {
      if (!window.embedded_svc) {
        const s = document.createElement('script');
        s.async = true;
        s.setAttribute('src', bookshelfConfig.chatbot.url);
        // eslint-disable-next-line func-names
        s.onload = function () { Chatbot(null, bookshelfConfig.chatbot); };
        document.body.appendChild(s);
      } else {
        const script1 = document.createElement('SCRIPT');
        script1.src = Chatbot('https://service.force.com', bookshelfConfig.chatbot);
        script1.async = true;
        document.body.appendChild(script1);
      }
      this.createSessionMirror();
    }
  }

  static createSessionMirror = () => {
    const objectsReady = (window.toolbar instanceof Object) && (window.piSession instanceof Object);

    if (!objectsReady || window.isPiSessionUsageNotPossible) {
      return;
    }

    Object.defineProperty(window.toolbar, 'piToken', {
      get: () => {
        let token = null;
        window.piSession.getToken((status, rToken) => {
          token = rToken;
        });
        return token;
      }
    });
    Object.defineProperty(window.toolbar, 'piUserId', {
      get: () => { return window.piSession.userId(); }
    });
  }

  static pageContainerTracker(flags = {}) {
    const reactHandler = this;
    const containersNodeLists = [];
    constants.TRACKING_CONTAINERS.forEach((querySelector) => {
      const ele = document.querySelector(querySelector);
      if (ele) {
        containersNodeLists.push(ele);
      }
    });
    const callback = function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const contanierDataOrder = entry.target.getAttribute('data-order');
          const containerTitle = entry.target.getAttribute('data-title');
          if (contanierDataOrder) {
            // GA event for user contaniers
            const personId = window.piSession.userId();
            const customFields = { event_value: contanierDataOrder, personId, pageName: constants.AUTH_HOME_SCROLL_EVENT };
            CommonUtils.dispatchGaEvent(constants.AUTH_HOME_SCROLL_EVENT, constants.USER_CONTAINER, constants.USER_SEES_CONTAINER, containerTitle, customFields);
          }
          reactHandler.viewPortIntersecting(containerTitle, flags);

          // Log view impression for braze content cards
          CommonUtils.logBrazeViewimpression(entry);
          CommonUtils.logBannerViewImpression(entry);
        }
      });
    };
    if (!this.observer) {
      const options = {
        root: null,
        rootMargin: '-200px 0px -200px 0px',
        threshold: 0
      };
      this.observer = new window.IntersectionObserver(callback, options);
    } else {
      this.observer.disconnect();
    }
    containersNodeLists.forEach((target) => {
      reactHandler.observer.observe(target);
    });
  }

  /**
   * Function to log view impression for braze content cards
   */
  static logBrazeViewimpression = (entry) => {
    const containerId = entry.target.getAttribute('id');
    const cardId = entry.target.getAttribute('data-card-id');
    const { BRAZE_CONTENT_CARD_FOR_VIEW_IMPRESSION } = constants;

    if (BRAZE_CONTENT_CARD_FOR_VIEW_IMPRESSION.includes(containerId)) {
      BrazePlugin.viewContentCard(cardId);
    }
  }

  static viewPortIntersecting = (locationInApp) => {
    // eslint-disable-next-line camelcase
    const is_ghost_account = CommonUtils.isGhostAccount();
    const {
      ETEXT_BOOK_LABEL
    } = constants;
    const {
      LIBRARY,
      LIBRARYL2,
      LIBRARY_L2,
      COURSE_TAB
    } = constants.LOCATION_IN_APP;
    const {
      BOOK,
      BOOK_WITH_CHANNEL
    } = constants.HERO_BANNER_TYPES;
    const { AUDIO_QUERY_SELECTOR } = constants;
    const isLibraryAudioBook = !!(document.querySelector(AUDIO_QUERY_SELECTOR.LIBRARY));
    const HERO_BANNER_LABEL_MAP = {
      [BOOK]: ETEXT_BOOK_LABEL,
      [BOOK_WITH_CHANNEL]: ETEXT_BOOK_LABEL
    };

    switch (locationInApp) {
      case COURSE_TAB: {
        const heroBanner = Framework.getStoreRegistry().getStore('herobanner');
        const heroBannerType = heroBanner ? getSnapshot(heroBanner).bannerType : '';

        if (heroBannerType === BOOK || heroBannerType === BOOK_WITH_CHANNEL) {
          CommonUtils.dispatchGaEvent(
            constants.AUTH_HOME_CATEGORY,
            constants.EVENT_AUTHHOMEEVENT,
            constants.BOOK_VIEW_EVENT_ACTION,
            HERO_BANNER_LABEL_MAP[heroBannerType],
            {
              location_in_app: COURSE_TAB,
              is_ghost_account,
              container_value: CommonUtils.getCurrentScreenEventLabel()
            }
          );
        }
        break;
      }
      case LIBRARY: {
        const libraryEle = document.querySelector('.one-reader-lib-bookCard');
        const libarayAddTitleEle = document.querySelector('#one-reader-lib-addtitle');
        const libraryMarketingCardEle = document.querySelector('#one-reader-lib-etext-content-card');

        if (libraryEle) {
          CommonUtils.dispatchGaEvent(
            constants.AUTH_HOME_CATEGORY,
            constants.EVENT_AUTHHOMEEVENT,
            constants.BOOK_VIEW_EVENT_ACTION,
            ETEXT_BOOK_LABEL,
            {
              location_in_app: LIBRARY,
              is_ghost_account,
              audio: isLibraryAudioBook,
              container_value: CommonUtils.getCurrentScreenEventLabel()
            }
          );
        }
        if (libarayAddTitleEle) {
          CommonUtils.dispatchGaEvent(
            constants.AUTH_HOME_CATEGORY,
            constants.EVENT_AUTHHOMEEVENT,
            constants.BOOK_VIEW_EVENT_ACTION,
            constants.ADD_TITLE_EVENT_LABELV1,
            {
              location_in_app: LIBRARY,
              is_ghost_account,
              audio: isLibraryAudioBook,
              container_value: CommonUtils.getCurrentScreenEventLabel()
            }
          );
        }
        if (libraryMarketingCardEle) {
          CommonUtils.dispatchGaEvent(
            constants.AUTH_HOME_CATEGORY,
            constants.EVENT_AUTHHOMEEVENT,
            constants.BOOK_VIEW_EVENT_ACTION,
            constants.ETEXT_CONTENT_CARD,
            {
              location_in_app: LIBRARY,
              is_ghost_account,
              audio: isLibraryAudioBook,
              container_value: CommonUtils.getCurrentScreenEventLabel()
            }
          );
        }
      }
        break;
      case LIBRARYL2:
        CommonUtils.dispatchGaEvent(
          constants.AUTH_HOME_CATEGORY,
          constants.EVENT_AUTHHOMEEVENT,
          constants.BOOK_VIEW_EVENT_ACTION,
          ETEXT_BOOK_LABEL,
          {
            location_in_app: LIBRARY_L2,
            is_ghost_account,
            container_value: CommonUtils.getCurrentScreenEventLabel()
          }
        );
        break;
      default:
        break;
    }
  }

  static isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  static getBooksLength(books) {
    const booksLength = {
      activeBooksLength: 0,
      inactiveBooksLength: 0
    }
    const activeBooks = books && books.filter(book => (
      book.product_entitlements &&
      !book.product_entitlements.expired && !book.course_expired
    ));

    const inactiveBooks = books && books.filter(book => book.product_entitlements
      && (book.product_entitlements.expired || book.course_expired));
    booksLength.activeBooksLength = activeBooks.length;
    booksLength.inactiveBooksLength = inactiveBooks.length;

    return booksLength
  }

  static toString(value) {
    return (value && value !== 'n/a') ? value.toString() : null
  }

  static isInternalUser(user) {
    return user.socialLink && user.socialLink.toLowerCase() === 'internalazure';
  }

  /**
      * Function debounce for performance optimization while repeat excution
      * @param {function} fn
      * @param {millseconds} ms
      */
  static debounce(fn, ms = 500) {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { fn.apply(this, args); }, ms);
    };
  }

  /**
   * To check screen resoultion below threshhold breakPoint
   * @param {*} breakPoint
   * @returns
   */
  static isScreenResoultionBelow = (breakPoint) => (((window.innerWidth || document.documentElement.clientWidth) < breakPoint))

  /**
   * Returns sorted books based on start date, active and inactive status
   * @param {*} breakPoint
   * @returns
   */
  static sortBooksOnStartDateAndStatus = (bookshelf) => {
    const updatedBookshelf = {};

    if (bookshelf.books && bookshelf.books.length > 0) {
      bookshelf.books.sort((a, b) => new Date(a.product_entitlements.start_date).getTime()
        - new Date(b.product_entitlements.start_date).getTime()).reverse();
      const activeBooks = bookshelf.books.filter(book => !book.course_expired && !book.product_entitlements.expired);
      const nonActiveBooks = bookshelf.books.filter(book => book.course_expired || book.product_entitlements.expired);
      updatedBookshelf.books = [...activeBooks, ...nonActiveBooks];
    }

    return updatedBookshelf;
  }

  /**
   * Update userpreference firstTimeUser
   * 
   * @param {*} updateUserActivity
   */
  static updateUserPreference(updateUserActivity) {
    userPreferencesService.fetch().then(({ data }) => {
      const userActivity = data?.userActivity || {};
      const eventData = {
        eventType: 'PUT',
        payload: {
          userActivity: {
            ...userActivity,
            ...updateUserActivity
          }
        }
      };
      Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData);
    });
  }

  /**
   * To detect reader page came from Intergrated MLM launch or not
   */
  static isIntergratedMLMLaunch = () => {
    const mlmLaunchQueryParamValue = PathUtils.getQueryParameterbyName(constants.MLM_LAUNCH_EREADER);

    return !!(mlmLaunchQueryParamValue && mlmLaunchQueryParamValue.toLowerCase() === 'y');
  }

  /**
   * Handle post message from Integrated MLM Launch
   * @param {*} event 
   * @param {*} listenerCallback 
   */
  static handlePostMessageFromIntegratedMLMLaunch = (event, listenerCallback) => {
    if(event && event.data) {
      switch (event.data.type) {
        case constants.MLM_IA_LAUNCH:
          const { startPageId, endPageId, productId, courseId } = event.data;
          listenerCallback(startPageId || null);
          break;
        default:
          break;
      }
    }
  };

  /**
   * Initialize the communication between IntegratedMLM and one reader
   * @param {*} listenerCallback 
   */

  static initializeIntegratedMLMLaunch = (listenerCallback) => {
    window.addEventListener('message', (event) => CommonUtils.handlePostMessageFromIntegratedMLMLaunch(event, listenerCallback));
  }

  /**
   * Close the communication between IntegratedMLM and one reader
   */

  static closeConnectionIntegratedMLMLaunch = (listenerCallback) => {
    window.removeEventListener('message', (event) => CommonUtils.handlePostMessageFromIntegratedMLMLaunch(event, listenerCallback));
  }

  /**
   * Method to find whether user has book title
   * @param {*} bookshelf 
   * @returns 
   */
  static hasBooks = (bookshelf = {}) => {
    return bookshelf.books && bookshelf.books.length > 0;
  }

  /**
   * Method to find whether user has active book list
   * 
   * @param {<LIST>} books 
   * @returns
   */
  static getActiveBooks = (books = []) => (
    books.filter(book => (CommonUtils.isActiveBook(book)))
  )

  /**
   * Method to find whether user has in active book listt
   *
   * @param {<LIST>} books
   * @returns
   */
  static getExpiredBooks = (books = []) => (
    books.filter(book => (CommonUtils.isExpiredBook(book)))
  )

  /**
      * Function to check isPPlusSubscriptionBook
      * @param {object} book
      */
  static isPPlusSubscriptionBook = (book, checkExpire = true) => {
    const {
      product_entitlements: {
        // eslint-disable-next-line camelcase
        business_model_code
      }
    } = book;
    const bookExpired = checkExpire && !CommonUtils.isActiveBook(book);

    // eslint-disable-next-line camelcase
    return ((business_model_code === constants.DIRECT_TO_CONSUMER) && (!bookExpired));
  }

  /**
     * Function to check active/inactive book
     * @param {object} book
     */
  static isActiveBook = (book) => {
    const isPPlusBook = CommonUtils.isPPlusSubscriptionBook(book, false);
    const isPausedBook = isPPlusBook && book?.gpsSubscriptionOfBook?.status === constants.PAUSED;

    return (book.product_entitlements && !book.product_entitlements.expired && !book.course_expired && !isPausedBook);
  }

  /**
   * Returns true if book is expired
   */
  static isExpiredBook = book => (
    (book.product_entitlements && book.product_entitlements.expired) || book.course_expired
  );

  /**
   * Method to find whether user has active book title
   *
   * @param {<LIST>} books
   * @returns
   */
  static hasActiveBooks = (books = []) => {
    const activeBooks = CommonUtils.getActiveBooks(books);
    return activeBooks.length > 0;
  }

  /**
   * Method to find whether user has Inactive book title
   *
   * @param {<LIST>} books
   * @returns
   */
  static hasInActiveBooks = (books = []) => {
    const inActiveBooks = CommonUtils.getExpiredBooks(books);
    return inActiveBooks.length > 0;
  }

  /**
   * To identify whether user is ghost account or not
   *
   */
  static isGhostAccount() {
    const tpiValue = PathUtils.getQueryParameterbyName('isTpi');

    return (tpiValue && tpiValue.toLowerCase() === 'y') || false;
  }

  /**
   * To identify whether LMS query param is present or not
   *
   */
  static isLMSQueryParamAvailable() {
    const isLMS = PathUtils.getQueryParameterbyName(constants.IS_LMS);

    return (isLMS && isLMS.toLowerCase() === 'y') || false;
  }

  /**
   * Function which returns banner's expirationDate from GPS switchTitle/autoRenewal
   * based on bannerid
   *
   * @param {*} userGpsSubscriptions
   * @param {String} bannerId
   * @returns
   */
  static getBannerExpirationDate(userGpsSubscriptions, bannerId) {
    let endDate = null;

    if (userGpsSubscriptions && bannerId) {
      switch (bannerId) {
        case constants.SUBSCRIPTION_BANNER.AUTORENEWALCREDITCARD:
        case constants.SUBSCRIPTION_BANNER.AUTORENEWALBOOKSTORE: {
          if (userGpsSubscriptions.autoRenewal) {
            const last21Obj = userGpsSubscriptions.autoRenewal.find(({ period, type }) => (
              (period && (period.toLowerCase() === constants.SWAP_TITLE_PERIODS.LAST21.toLowerCase()))
              && (type && (type.toLowerCase() === bannerId.toLowerCase()))));
            endDate = last21Obj?.expirationDate;
          }
          break;
        }
        case constants.SUBSCRIPTION_BANNER.SWITCHTITLE: {
          if (userGpsSubscriptions.swapTitle) {
            const first14Obj = userGpsSubscriptions.swapTitle.find(({ period }) => period && period.toLowerCase() === constants.SWAP_TITLE_PERIODS.FIRST14.toLowerCase());
            endDate = first14Obj?.expirationDate;
          }
          break;
        }
        case constants.SUBSCRIPTION_BANNER.PAUSED: {
          if (userGpsSubscriptions) {
            endDate = userGpsSubscriptions.endDate;
          }
          break;
        }
        case constants.SUBSCRIPTION_BANNER.EXPIRED: {
          if (userGpsSubscriptions) {
            endDate = userGpsSubscriptions.endDate;
          }
          break;
        }
        default:
          break;
      }
    }

    return endDate;
  }

  /**
   * To get the browser timeZone date format
   *
   * @param {<DATE|UTC>} timeStamp
   * @returns
   */
  static formatDate = (timeStamp = null) => {
    const date = timeStamp ? new Date(timeStamp) : new Date();
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = (`0${date.getDate()}`).slice(-2);
    const month = monthList[date.getMonth()];
    const year = date.getFullYear();
    const formatDateString = {'MMM': month, 'DD': day, 'YYYY': year };

    return constants.DATE_FORMAT.replace(/MMM|DD|YYYY/g, (match)=>(formatDateString[match]));
  }

  /**
   * returns the age based on DOB
   *
   * @param {*} dob
   * @returns
   */
  static getAge = (dob) => {
    if (dob) {
      const today = new Date();
      const birthDay = new Date(dob);
      const currentMonth = today.getMonth();
      const birthMonth = birthDay.getMonth();
      const currentDate = today.getDate();
      const birthDate = birthDay.getDate();
      const isCurrentMonthLessThanBirthMonth = currentMonth < birthMonth;
      const isSameMonthAndCurrentDteLessThanBirthDte = (currentMonth === birthMonth) && (currentDate < birthDate);
      let age = today.getFullYear() - birthDay.getFullYear()

      /**
       * If the current month is lesser than user's birth month then we subtract -1 year
       * If the current month and the user's birth month is same, And current date is lesser than user's birth date
       * then we subtract -1 year
       */
      age = (isCurrentMonthLessThanBirthMonth || isSameMonthAndCurrentDteLessThanBirthDte) ? age - 1 : age;

      return age;
    }

    return null;
  }

  /**
   * Returns book's product entitlement.
   *
   * @param {Object} book
   * @returns
   */
  static getProductEntitlementOfBook = book => book?.product_entitlements || {};

  /*
   * Returns matching gpsSubscription object based on subscription id of the book
   *
   * @param {String} subscriptionId
   * @param {Object} user
   * @returns
   */
  static getMatchingGpsSubscriptionOfBook = (subscriptionId, user) => {
    let bookSubscription;

    if (subscriptionId && user && user.gpsSubscriptions) {
      bookSubscription = user.gpsSubscriptions.find(subscription => subscription.subscriptionId === subscriptionId);
    }

    return bookSubscription || {};
  }

  /**
   * Returns the necessary IA (Inclusive Access) launch query parameters
   *
   * @returns
   */
  static getDecodedIAqueryParam = () => {
    let isIA = false;
    let IAProdcutID = null;
    let decodedQueryParam = '';
    const encodedQueryParam = PathUtils.getQueryParameterbyName(constants.IA_QUERY_PARAMS.REF);
    if (encodedQueryParam) {
      // eslint-disable-next-line no-undef
      decodedQueryParam = atob(encodedQueryParam);
      if (decodedQueryParam && decodedQueryParam.length > 0) {
        const isIAQueryParam = PathUtils.getQueryParameterbyName(constants.IA_QUERY_PARAMS.ISIA, decodedQueryParam);
        isIA = !!(isIAQueryParam && isIAQueryParam.toLowerCase() === 'y');
        IAProdcutID = PathUtils.getQueryParameterbyName(constants.IA_QUERY_PARAMS.PRODUCTID, decodedQueryParam) || null;
      }
    }

    return {
      isIA,
      IAProdcutID
    };
  };

  /**
   * Get IA (Inclusive access) book if the productId of the book
   * is available and isIA is equal to y in query param
   *
   * @param {*} books
   * @param {*} IAbookProductID
   * @returns
   */
  static getIAbookIfAvailable = (books) => {
    let IAbook;
    const { isIA, IAProdcutID } = CommonUtils.getDecodedIAqueryParam();
    const isIAandHasProductId = !!(isIA && IAProdcutID);

    if (isIAandHasProductId) {
      IAbook = books.find(book => book.product_id === IAProdcutID);
    }

    return IAbook;
  }

  static getIds = (id) => {
    let productId = null;
    let bookId = null;
    let courseId = null;

    if (typeof id === 'string' && id.includes(':')) {
      const ids = id.split(':');

      bookId = ids[0];
      productId = ids[1];
      courseId = ids[2];
    }

    return {
      productId,
      bookId,
      courseId
    };
  }

  /**
   * Get IA (Inclusive access) book if the productId of the book
   * is available and isIA is equal to y in query param
   *
   * @param {*} books
   * @param {*} IAbookProductID
   * @returns
   */
  static getIAbookIdxIfAvailable = (courses) => {
    let IAbookIdx = -1;
    const { isIA, IAProdcutID } = CommonUtils.getDecodedIAqueryParam();
    const isIAandHasProductId = !!(isIA && IAProdcutID);

    if (isIAandHasProductId) {
      IAbookIdx = courses.findIndex((course) => {
        CommonUtils.moveBookToFirstPosition(course, { productId: IAProdcutID });
        const { productId } = (course && course.collection && course.collection[0]) || {};

        if (productId) {
          return productId === IAProdcutID;
        }

        return false;
      });
    }

    return IAbookIdx;
  }

  /**
   * If course pill has more than one book associated.
   * Moves the deeplink book to first position.
   *
   * @param {Object} course
   * @param {Object} data
   */
  static moveBookToFirstPosition = (course, data = {}) => {
    let collectionIdx = 0;
    const courseId = data?.courseId || null;
    const bookId = data?.bookId || null;
    const productId = data?.productId || null;
    const hasMoreThanOneCourse = course?.collection?.length > 1;

    if (hasMoreThanOneCourse) {
      collectionIdx = course.collection.findIndex(
        book => (productId === book?.productId && bookId === book?.bookId && courseId === book?.courseId)
      );
    }

    if (hasMoreThanOneCourse && collectionIdx !== 0) {
      const deepLinkBook = course.collection[collectionIdx];

      if (deepLinkBook) {
        course.collection.splice(collectionIdx, 1);
        CommonUtils.setCourseImageTitle(course, deepLinkBook);
        // eslint-disable-next-line no-param-reassign
        course.collection = [deepLinkBook, ...(course.collection || [])];
      }
    }
  }

  /**
   * Map the title and coverImage for course
   *
   * @param {*} course
   * @param {*} obj
   */
  static setCourseImageTitle(course, { title, bookCoverImage }) {
    // eslint-disable-next-line no-param-reassign
    course.imageSrc = bookCoverImage;
    // eslint-disable-next-line no-param-reassign
    course.title = title;
  }

  static getMLMbookIdxIfAvailable = (courses, match, isMLMroute = false) => {
    let MLMBookIdx = -1;
    const mlmBookData = CommonUtils.getMLMRouteBookData(match) || {};
    const mlmCourseId = mlmBookData?.courseId || null;
    const mlmBookId = mlmBookData?.bookId || null;

    if (isMLMroute) {
      MLMBookIdx = courses.findIndex((course) => {
        CommonUtils.moveBookToFirstPosition(course, mlmBookData);
        const { bookId = null, courseId = null } = (course && course.collection && course.collection[0]) || {};

        if (mlmBookId && mlmCourseId) {
          return (bookId === mlmBookId) && (courseId === mlmCourseId);
        }
        if (mlmBookId) {
          return bookId === mlmBookId;
        }
        if (mlmCourseId) {
          return courseId === mlmCourseId;
        }

        return false;
      });
    }

    return MLMBookIdx;
  }

  /**
   * Checks for business model code either DTC or DTC Inclusive Access Or HEI book
   *
   * @param businessModelCode
   * @returns
   */
  static isPplusOrIAbookOrHEI = businessModelCode => businessModelCode
  && (businessModelCode === constants.DIRECT_TO_CONSUMER || businessModelCode === constants.DIRECT_TO_CONSUMER_IA || businessModelCode === constants.DIRECT_TO_CONSUMER_HEI);

  /**
  * Returns true if all the books are PPlus
  *
  * @param {List} books
  */
  static isAllPPlusBook(books) {
    return books.length > 0 ? books.every(book => CommonUtils.isPPlusSubscriptionBook(book, false)) : false;
  }

  /**
   * Returns true if the book is a bookstore book
   *
   * @param {Object} book
   * @returns
   */
  static isBookStorePPlusEntitlements = book => (book?.gpsSubscriptionOfBook?.subscriptionSource
    === constants.CHANNEL_PARTNER) && CommonUtils.isPPlusSubscriptionBook(book);

  /**
   * Returns true if subscriptionId is matched with any books
   * 
   * @param {string} subscriptionId
   * @param {<LIST>} books
   * @returns
   */
  static isSubscriptionIdFoundInBooks = (subscriptionId, books) => {
    const isSubscriptionFound = books.find((book) => {
      const { subscription_id: productSubscriptionId } = CommonUtils.getProductEntitlementOfBook(book);

      return subscriptionId === productSubscriptionId;
    });

    return !!isSubscriptionFound;
  };

  /**
   * Returns true if all active bookstore subscriptions have pplus entitlements
   *
   * @param {Object} user
   * @param {List} books
   * @returns
   */
  static isAllActiveBookStoreHavePPlusEntitlements(user, books) {
    let isAllActiveBookStoreHavePPlusEntitlements = false;

    if (user && user.gpsSubscriptions && books) {
      const channelPartnerSubscriptions = user.gpsSubscriptions.filter(
        subscription => subscription?.subscriptionSource === constants.CHANNEL_PARTNER
          && (subscription?.status === constants.ACTIVE)
          && (subscription?.entitlementLevel === constants.ENTITLEMENTLEVEL_SINGLE
            || subscription?.entitlementLevel === constants.BUNDLE
            || subscription?.entitlementLevel === constants.ENTITLEMENTLEVEL_MULTI)
      );
      if (channelPartnerSubscriptions.length > 0) {
        isAllActiveBookStoreHavePPlusEntitlements = channelPartnerSubscriptions.every(({ subscriptionId }) => CommonUtils.isSubscriptionIdFoundInBooks(subscriptionId, books));
      }

    }

    return isAllActiveBookStoreHavePPlusEntitlements;
  }

  /**
   * Get propery value from an object
   * @param {*} obj
   * @param {*} name
   * @param {*} defaultValue
   * @returns
   */
  static getObjectPropertyValue = (obj, name, defaultValue) => (obj
    && Object.prototype.hasOwnProperty.call(obj, name) ? obj[name] : defaultValue);


  /**
   * Returns true if user has subscription with provided source
   * and has a subscription with its start date greater than the provided date
   *
   * @param {object} user
   * @param {string} source
   * @param {string} startDate
   * @param {Array} statusList
   * @returns
   */
  static hasSubscriptionWithSourceStartDateRange(
    user, source, startDate, statusList = [constants.ACTIVE, constants.PAUSED]
  ) {
    return user.gpsSubscriptions.some(
      subscription => subscription.subscriptionSource === source
        && (subscription?.status && statusList.includes(subscription.status))
        && (subscription?.entitlementLevel === constants.ENTITLEMENTLEVEL_SINGLE
          || subscription?.entitlementLevel === constants.BUNDLE
          || subscription?.entitlementLevel === constants.ENTITLEMENTLEVEL_MULTI)
        && CommonUtils.getNoOfDays(subscription.startDate, startDate) > 0
    );
  }

  /**
   * To local string compare
   * 
   * @param {*} stringA 
   * @param {*} stringB 
   * @returns 
   */
  static localStringCompare(stringA, stringB) {
    let isSame = false;
    if (typeof stringA === 'string' && typeof stringB === 'string') {
      isSame = stringA.toLowerCase().trim() === stringB.toLowerCase().trim()
    }

    return isSame;
  }

  /**
   * Returns true if the given businessModelCode matches with Integrated SMS bmc's
   *
   * @param {String} businessModelCode
   * @returns
   */
  static isIntegratedBMC(businessModelCode) {
    const integratedBMC = [constants.INTEGRATED_SMS, constants.INTEGRATED_RUMBA, constants.CG_RENEWALS];

    return integratedBMC.includes(businessModelCode);
  }

  /**
   * Returns true based on audio_book_supported flag from bookshelf
   *
   * @param {String} audioFlag
   * @returns
   */
  static isAudioBook(audioFlag) {
    const {
      AUDIO_FLAG_ON,
      AUDIO_FLAG_ON_BETA
    } = constants;

    if (PathUtils.getQueryParameterbyName('isTtsEnabled') === 'true') {
      return true;
    }

    return (audioFlag === AUDIO_FLAG_ON || audioFlag === AUDIO_FLAG_ON_BETA);
  }

  /**
   * Returns true if user has a subscription with the provided entitlementLevel
   *
   * @param {Object} userDetail
   * @param {Array} entitlementLevelList
   */
  static hasSubscriptionBasedOnEntitlementLevel(
    userDetail,
    entitlementLevelList = [
      constants.ENTITLEMENTLEVEL_SINGLE,
      constants.BUNDLE,
      constants.ENTITLEMENTLEVEL_MULTI
    ],
    statusList = [constants.ACTIVE]
  ) {
    return userDetail.gpsSubscriptions.some(
      subscription => (subscription?.entitlementLevel && entitlementLevelList.includes(subscription.entitlementLevel))
        && (subscription?.status && statusList.includes(subscription.status))
    );
  }

  /**
   * Get session storage
   *
   * @param {*} key
   * @param {*} parseData
   * @returns
   */
  static getSessionStorge(key, parseData = false) {
    let data = null;
    const store = window.sessionStorage.getItem(key);
    if (store) {
      data = parseData ? JSON.parse(store) : store;
    }

    return data;
  }

  /**
   * Set session storage
   *
   * @param {*} key
   * @param {*} data
   * @param {*} stringify
   */
  static setSessionStorge(key, data, stringify = false) {
    window.sessionStorage.setItem(key, stringify ? JSON.stringify(data) : data);
  }

  /**
   * Clear session storage
   *
   * @param {*} key
   */
  static clearSessionStorge(key) {
    if (this.getSessionStorge(key)) {
      window.sessionStorage.removeItem(key);
    }
  }

  /**
   * Function to determine new user or exisiting user
   * User first time login will set current session id into sessionStorge incase for isFirstLogin not present
   * User Secondtime login will compare the current session id vs pervious session id from sessionStorge incase of isFirstLogin as false
   *
   * @param {*} userActivity
   * @param {*} user
   * @returns
   */
  static isExistingUser(userActivity) {
    const isFirstLogin = CommonUtils.getObjectPropertyValue(userActivity, 'isFirstLogin', true);
    const currentSessionId = window.piSession.getContextId();
    let isNewUser = isFirstLogin;
    const previousSessionId = this.getSessionStorge(constants.PEARSON_SESSION);
    if (isFirstLogin) { // First visit new user
      this.setSessionStorge(constants.PEARSON_SESSION, currentSessionId);
    } else if (!isFirstLogin && previousSessionId && (previousSessionId === currentSessionId)) { // existing user refresh in same session
      isNewUser = true;
    } else if (!isFirstLogin && previousSessionId && (previousSessionId !== currentSessionId)) { // Second Visit existing user
      this.clearSessionStorge(constants.PEARSON_SESSION);
    }

    return isNewUser;
  }

  /**
   * Return true if last accessed channel exists in location response
   * @returns
   */
  static hasLastAccessedChannel() {
    const location = getSnapshot(Framework.getStoreRegistry().getStore('lastlocation'));

    return !!(location && location.userChannelLocation && location.userChannelLocation.length > 0);
  }

  static getFullStoryScript = () => {
    const script = `window._fs_host="fullstory.com",
      window._fs_script="edge.fullstory.com/s/fs.js",
      window._fs_org="o-1NN4YM-na1",
      window._fs_namespace="FS",
      function(n,e,t,o,s,c,i,f){
        t in n?n.console&&n.console.log&&n.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].')
        :((i=n[t]=function(n,e,t){i.q?i.q.push([n,e,t]):i._api(n,e,t)}).q=[],
        (c=e.createElement(o)).async=1,c.crossOrigin="anonymous",
        c.src="https://"+_fs_script,
        (f=e.getElementsByTagName(o)[0]).parentNode.insertBefore(c,f),
        i.identify=function(n,e,t){i(s,{uid:n},t),e&&i(s,e,t)},
        i.setUserVars=function(n,e){i(s,n,e)},
        i.event=function(n,e,t){i("event",{n:n,p:e},t)},
        i.anonymize=function(){i.identify(!1)},
        i.shutdown=function(){i("rec",!1)},
        i.restart=function(){i("rec",!0)},i.log=function(n,e){i("log",[n,e])},
        i.consent=function(n){i("consent",!arguments.length||n)},
        i.identifyAccount=function(n,e){c="account",(e=e||{}).acctId=n,
        i(c,e)},i.clearUserCookie=function(){},
        i.setVars=function(n,e){i("setVars",[n,e])},
        i._w={},f="XMLHttpRequest",i._w[f]=n[f],
        f="fetch",i._w[f]=n[f],
        n[f]&&(n[f]=function(){return i._w[f].apply(this,arguments)}),
        i._v="1.3.0")}(window,document,window._fs_namespace,"script","user")`;

    return script;
  }

  /**
   * Handles Info book cta events
   */
  static handleInfoBookCTAEvents = (
    book,
    isClicked = true,
    locationInApp = constants.LOCATION_IN_APP.COURSE_TAB,
    isFromInfo = true
  ) => {
    let eventLabel = null;
    let eventAction = null;
    const { LIBRARY_EVENT, LOCATION_IN_APP, EVENT_AUTHHOMEEVENT } = constants;
    const isLastAccessed = !!(book.last_activity);
    if (isFromInfo) { // Hero Inof CTA Click and hover event
      eventLabel = (isLastAccessed && locationInApp === LOCATION_IN_APP.COURSE_TAB)
        ? LIBRARY_EVENT.INFO.CONTINUE_READING
        : LIBRARY_EVENT.INFO.OPEN;
      eventAction = LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION;
    }
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: locationInApp
    };

    if (eventAction && eventLabel && isClicked) {
      CommonUtils.dispatchGaEvent(
        LIBRARY_EVENT.EVENT_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        eventAction,
        eventLabel,
        customFields
      );
    }
  }

  /**
   * To identify whether query param is present or not with actual value
   *
   * @param {*} query
   * @param {*} value
   * @returns
   */
  static isQueryParamAvailable(query, value) {
    const isQueryExist = PathUtils.getQueryParameterbyName(query);

    return (isQueryExist && isQueryExist.toLowerCase() === value) || false;
  }

  /**
   * Is the book of product_entitlements end date crossed the current date after N number of days
   *
   * @param {*} book
   * @param {*} numOfDays
   * @returns
   */
  static isBookEndDateWithinXDays(book, numOfDays) {
    // TODO : Need to check date difference on bookshelf mobx store itself by introduceing new propery
    let isValidBook = true;
    if (book?.product_entitlements?.end_date) {
      // Getting number of days between current days against book end date
      const actualNumOfDays = this.getNoOfDays(new Date(), book.product_entitlements.end_date);
      isValidBook = actualNumOfDays <= numOfDays;
    }

    return isValidBook;
  }

  /**
   * Returns the List of book exclude expired book crossed Max days
   *
   * @param {*} books
   * @param {*} numOfDays
   * @returns
   */
  static removeExpiredBookAfterXDays(books, numOfDays) {
    // Filtering the non expired books as first things or expired book with in x days
    const bookList = books.filter(book => (!this.isExpiredBook(book) || this.isBookEndDateWithinXDays(book, numOfDays)));

    return bookList;
  }

  /**
   * Get last accessed channel from location
   *
   * @returns
   */
  static getRecentlyUsedChannel = () => {
    const location = getSnapshot(Framework.getStoreRegistry().getStore('lastlocation'));
    const channel = location && location.userChannelLocation && location.userChannelLocation[0];

    return channel;
  }

  /**
   * Returns recommended assets based on custom mapping
   *
   * @param {*} response
   * @returns
   */
  static getRecommendedAssetsData = (response) => {
    const recommendedAssetsData = {};
    const {
      PRACTICE_SET_ICON,
      HRS,
      MIN,
      ASSET_CARD_SECTION_COLOR,
      ASSET_CONTENT_TYPE_DISPLAY_TEXT
    } = constants;
    const { MAPPEDCHANNELASSET } = constants.MAPPED_CHANNEL_TEMPLATES;
    const { EXAMPLE, MULTIPLE_CHOICE, SIMPLE_QUESTIONS } = constants.ASSET_CONTENT_TYPE;
    const language = Framework.getStoreRegistry().getStore('language');

    if (response) {
      recommendedAssetsData.subTitle = (response.chapterTitle && response.topicTitle)
        ? `${response.chapterTitle} • ${response.topicTitle}` : (response.chapterTitle || response.topicTitle);

      if (typeof response.assetType === 'string'
        && (response.assetType.toLowerCase() === MULTIPLE_CHOICE
          || response.assetType.toLowerCase() === SIMPLE_QUESTIONS)) {
        recommendedAssetsData.description = ASSET_CONTENT_TYPE_DISPLAY_TEXT[response.assetType.toLowerCase()];
        recommendedAssetsData.thumbnailUrl = PRACTICE_SET_ICON;
        recommendedAssetsData.section = language.getMessage('hero.practiceProblem');
        recommendedAssetsData.sectionColor = ASSET_CARD_SECTION_COLOR.GREEN;
        recommendedAssetsData.icon = null;
        recommendedAssetsData.title = response.topicTitle || '';
      } else {
        recommendedAssetsData.description = (response?.assetDuration?.split(':').length > 2)
          ? response?.assetDuration?.replace(/^0/, '').concat(HRS) : response?.assetDuration?.replace(/^0/, '').concat(MIN);
        recommendedAssetsData.thumbnailUrl = response.thumbUrl || '';
        recommendedAssetsData.section = response.assetContentType || '';
        recommendedAssetsData.sectionColor = (response.assetContentType === EXAMPLE) ? ASSET_CARD_SECTION_COLOR.PINK : ASSET_CARD_SECTION_COLOR.BLUE;
        recommendedAssetsData.icon = MAPPEDCHANNELASSET.ICONS.PLAYICON;
        recommendedAssetsData.title = response.assetTitle || '';
      }
    }

    return recommendedAssetsData;
  }

  /**
   * Returns true when AI chat bot  is enabled
   *
   * @param {*} showAITutor
   * @param {*} isAIReviewUser
   * @param {*} isAITutorSupported
   * @returns
   */
  static isAITutorEnabled(showAITutor, isAIReviewUser, isAITutorSupported) {
    let isShowAITutorEnabled = false;
    if (showAITutor) {
      switch (isAITutorSupported) {
        case constants.LIVE:
          isShowAITutorEnabled = true;
          break;
        case constants.REVIEW:
          isShowAITutorEnabled = isAIReviewUser;
          break;
        default:
          isShowAITutorEnabled = false;
          break;
      }
    }

    return isShowAITutorEnabled;
  }

  static isAHAITutorEnabled(enableAITutorOnAuthHome, showAITutor, isAIReviewUser, isAITutorSupported) {
    return enableAITutorOnAuthHome
      && CommonUtils.isAITutorEnabled(showAITutor, isAIReviewUser, isAITutorSupported);
  }

  /**
   * Returns marketing card data for hero
   *
   * @returns
   */
  static getChannelMarketingCardData = () => {
    const {
      NORMAL,
      EXTENDED
    } = constants.CHANNEL_MARKETING_IMAGE_TYPE;
    const language = Framework.getStoreRegistry().getStore('language');

    return [
      {
        image: constants.CHANNEL_MARKETING_CARD1,
        title: language.getMessage('hero.marketingcard1.copy.title'),
        description: language.getMessage('hero.marketingcard1.copy.description'),
        imageSize: NORMAL
      },
      {
        image: constants.CHANNEL_MARKETING_CARD2,
        title: language.getMessage('hero.marketingcard2.copy.title'),
        description: language.getMessage('hero.marketingcard2.copy.description'),
        imageSize: EXTENDED
      },
      {
        image: constants.CHANNEL_MARKETING_CARD3,
        title: language.getMessage('hero.marketingcard3.copy.title'),
        description: language.getMessage('hero.marketingcard3.copy.description'),
        imageSize: EXTENDED
      }
    ];
  }

  /**
   * Returns appropriate channelPersonalized, locationInAppForSol1D
   * for view channel card event for hero channel, herobook
   *
   * @param {*} mappedChannel
   * @returns
   */
  static getChannelsViewEventDataForSol1D = (mappedChannel) => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const {
      bannerType
    } = getSnapshot(heroBannerStore);
    const {
      HERO_ETEXT_MAPPED_CHANNEL,
      HERO_RECENTCHANNEL
    } = constants.LOCATION_IN_APP;
    const {
      MAPPED_CHANNEL_ASSET_CARD,
      POPULAR_CHANNEL_ASSETS_CARD,
      LAST_ACCESSED_CHANNEL_ASSETS
    } = constants.MAPPED_CHANNEL_TEMPLATES;
    const {
      RECOMMENDED__ASSETS,
      POPULAR_ASSETS,
      RECENT_CHANNEL
    } = constants.CHANNEL_PERSONALIZED_VALUES;
    const mappedChannelTemplate = mappedChannel && mappedChannel.template;
    let channelPersonalized = null;
    let locationInApp = null;

    if (mappedChannelTemplate === MAPPED_CHANNEL_ASSET_CARD.TEXT) {
      channelPersonalized = RECOMMENDED__ASSETS;
      locationInApp = HERO_ETEXT_MAPPED_CHANNEL;
    } else if (mappedChannelTemplate === POPULAR_CHANNEL_ASSETS_CARD.TEXT) {
      channelPersonalized = POPULAR_ASSETS;
      locationInApp = HERO_ETEXT_MAPPED_CHANNEL;
    } else if (mappedChannelTemplate === LAST_ACCESSED_CHANNEL_ASSETS.TEXT) {
      channelPersonalized = RECENT_CHANNEL;
      locationInApp = HERO_RECENTCHANNEL;
    } else if (bannerType === constants.HERO_BANNER_TYPES.CHANNEL) {
      channelPersonalized = RECENT_CHANNEL;
      locationInApp = HERO_RECENTCHANNEL;
    }
    return {
      channelPersonalized,
      locationInAppForSol1D: locationInApp
    };
  }

  static waitForElm(selector) {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }
      // eslint-disable-next-line no-undef
      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  /**
   * Function hanlde the text into clipboard
   * Note: Clipboard only works on https secure mode
   *
   * @param {*} text
   * @returns
   */
  static copyText = (text) => {
    if (!window?.navigator?.clipboard || !text) {
      return;
    }

    window.navigator.clipboard.writeText(text);
  }

  /**
   * Returns true if the book collection array contains archived
   *
   * @param {Array} collections
   * @returns
   */
  static isArchivedBook = (collections = []) => {
    const { BOOKSHELF_USER_COLLECTIONS } = constants;
    let isArchivedBook = false;

    if (collections.length > 0) {
      isArchivedBook = collections.some(
        collection => typeof collection === 'string' && collection.toLowerCase() === BOOKSHELF_USER_COLLECTIONS.ARCHIVED.toLowerCase()
      );
    }

    return isArchivedBook;
  }

  /**
   * Returns archived, unarchived books
   *
   * @param {<LIST>} books
   * @returns
   */
  static getArchivedOrUnArchivedBooks = (books = []) => {
    const archivedBooks = [];
    const unArchivedBooks = [];

    if (books.length > 0) {
      books.forEach((book) => {
        if (book.isArchived) archivedBooks.push(book);
        else unArchivedBooks.push(book);
      });
    }

    return {
      archivedBooks,
      unArchivedBooks
    };
  }

  /**
   * Returns true if user has books which are Archived
   *
   * @param {*} books
   * @returns
   */
  static hasArchivedBooks = (books = []) => books.filter(book => (book.isArchived))?.length > 0

  /**
   * Tracks if a element is viewed / intersected in the viewport
   *
   */
  static appDomElementIntersectionTracker(properties = {}) {
    const elementsToTrackViewPortIntersection = constants.ELEMENTS_TO_TRACK_VIEWPORT_INTERSECTION;
    const intersectorCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          CommonUtils.logArchiveViewEvent(entry.target);
          CommonUtils.logNativeAppBannerViewEvent(entry.target, properties?.locationInApp);
        }
      });
    };

    if (!this.elementIntersectObserver) {
      const options = {
        rootMargin: '0px',
        threshold: 1.0
      };
      this.elementIntersectObserver = new window.IntersectionObserver(intersectorCallback, options);
    } else {
      this.elementIntersectObserver.disconnect();
    }

    if (elementsToTrackViewPortIntersection && elementsToTrackViewPortIntersection.length > 0) {
      elementsToTrackViewPortIntersection.forEach((element) => {
        const elementToTrack = document.querySelector(element);

        if (elementToTrack) {
          this.elementIntersectObserver.observe(elementToTrack);
        }
      });
    }
  }

  /**
   * Handles archive feature's view event
   *
   * @param {*} element
   */
  static logArchiveViewEvent(element) {
    const {
      VIEW_SELECT_TO_ARCHIVE_CTA,
      VIEW_SELECT_TO_UNARCHIVE_CTA,
      SELECT_TO_ARCHIVE,
      SELECT_TO_UNARCHIVE
    } = constants.ARCHIVE_EVENTS;
    const {
      SELECT_TO_ARCHIVE_OR_UNARCHIVE_BUTTONS,
      LOCATION_IN_APP,
      AUTHHOME_APPLICATION,
      EVENT_USER_INTERACTION
    } = constants;
    const elementId = element?.getAttribute('id');
    const isSelectToArchive = elementId === SELECT_TO_ARCHIVE_OR_UNARCHIVE_BUTTONS.SELECT_TO_ARCHIVE;
    const isSelectToUnarchive = elementId === SELECT_TO_ARCHIVE_OR_UNARCHIVE_BUTTONS.SELECT_TO_UNARCHIVE;
    const eventAction = isSelectToArchive ? VIEW_SELECT_TO_ARCHIVE_CTA : VIEW_SELECT_TO_UNARCHIVE_CTA;
    const eventLabel = isSelectToArchive ? SELECT_TO_ARCHIVE : SELECT_TO_UNARCHIVE;
    const customFields = {
      location_in_app: LOCATION_IN_APP.LIBRARYL2,
      is_ghost_account: CommonUtils.isGhostAccount(),
      event_value: null
    };

    if (isSelectToArchive || isSelectToUnarchive) {
      CommonUtils.dispatchGaEvent(
        AUTHHOME_APPLICATION,
        EVENT_USER_INTERACTION,
        eventAction,
        eventLabel,
        customFields
      );
    }
  }

  /**
   * Logs banner's view impression event
   *
   * @param {*} element
   */
  static logBannerViewImpression(element) {
    const containerId = element.target.getAttribute('id');
    const { BANNERS, AUTH_HOME_CATEGORY, EVENT_AUTHHOMEEVENT } = constants;

    if (containerId === BANNERS.CODE_REDEEMED.id) {
      CommonUtils.dispatchGaEvent(
        AUTH_HOME_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        BANNERS.CODE_REDEEMED.view_event_action,
        BANNERS.CODE_REDEEMED.event_label,
        {
          is_ghost_account: CommonUtils.isGhostAccount()
        }
      );
    }
  }

  /**
   * To get channel reldirection learn url
   *
   * @param {*} ChannelAssetData
   * @param {*} mappedChannel
   * @param {*} channelData
   * @returns
   */
  static getChannelLearnUrl(ChannelAssetData, mappedChannel, channelData = {}, maxAssets = 4) {
    const {
      RECOMMENDED_ASSETS,
      JUMPBACK_IN_AND_RECOMMENDED_NEXT,
      POPULAR_TOPICS,
      START_WITH_TOPICS
    } = constants.CHANNEL_TYPES;
    const {
      recommendedAssets,
      popularTopics,
      startWithTopics,
      jumpBackIn,
      recommendedNext
    } = ChannelAssetData;
    const firstChannelCard = (mappedChannel && mappedChannel.cards && mappedChannel.cards.length > 0 && mappedChannel.cards[0]);
    const { type, cardIndex } = (firstChannelCard && firstChannelCard.callForAction) || {};
    let redirectionUrl = null;

    switch (type) {
      case RECOMMENDED_ASSETS: {
        if (recommendedAssets && recommendedAssets[cardIndex]) {
          const { url } = recommendedAssets[cardIndex];
          redirectionUrl = env.STUDY_CHANNEL_URL + url;
        }
        break;
      }
      case POPULAR_TOPICS: {
        if (popularTopics && popularTopics[cardIndex]) {
          const { url } = popularTopics[cardIndex];
          redirectionUrl = env.STUDY_CHANNEL_URL + url;
        }
        break;
      }
      case START_WITH_TOPICS: {
        if (startWithTopics && startWithTopics[cardIndex]) {
          const { url } = startWithTopics[cardIndex];
          redirectionUrl = env.STUDY_CHANNEL_URL + url;
        }
        break;
      }
      case JUMPBACK_IN_AND_RECOMMENDED_NEXT: {
        if (jumpBackIn || recommendedNext) {
          const hasRecommendedNext = recommendedNext && recommendedNext.length > 0;
          const jumpBackInAndUpNext = [...jumpBackIn, ...(hasRecommendedNext ? recommendedNext : [])].slice(
            0, maxAssets
          );
          if (jumpBackInAndUpNext[cardIndex]) {
            const { url } = jumpBackInAndUpNext[cardIndex];
            redirectionUrl = env.STUDY_CHANNEL_URL + url;
          }
        }
        break;
      }
      default:
        break;
    }

    return redirectionUrl || channelData.learnUrl || channelData.url;
  }

  /*
   * Handles native app banner feature's view event
   *
   * @param {*} element
   * @param {*} locationInApp
   */
  static logNativeAppBannerViewEvent(element, locationInApp = constants.LOCATION_IN_APP.AUTHHOME) {
    const { NATIVE_APP_BANNER_CLASS } = constants;
    const {
      EVENT_NAME,
      VIEW_EVENT_ACTION,
      VIEW_EVENT_LABEL
    } = constants.NATIVE_APP_BANNER_EVENTS;
    const isDrawer = element?.classList?.contains(NATIVE_APP_BANNER_CLASS.DRAWER);
    const isBanner = element?.classList?.contains(NATIVE_APP_BANNER_CLASS.BANNER);
    const eventLabel = isDrawer ? VIEW_EVENT_LABEL.BOTTOM_DRAWER : VIEW_EVENT_LABEL.BANNER;

    if (isDrawer || isBanner) {
      CommonUtils.dispatchGaEvent(
        constants.AUTHHOME_APPLICATION,
        EVENT_NAME,
        VIEW_EVENT_ACTION,
        eventLabel,
        {
          location_in_app: locationInApp,
          is_ghost_account: CommonUtils.isGhostAccount()
        }
      );
    }
  }

  /**
   * To get active channel card
   * @param {*} channelCards
   * @returns
   */

  static getActiveChannelCards = (channelCards, includeMostRelated = false) => {
    let activeChannelCards = [];
    if (channelCards && channelCards.length > 0) {
      activeChannelCards = channelCards.filter(
        channel => (includeMostRelated
          ? channel.active || channel.isMostRelated
          : channel.active)
      );
    }

    return activeChannelCards;
  };

  /**
   * Returns true if user is not ghost account, the book has courseId and bmc is belonged to MLM
   *
   * @param {String} courseId
   * @param {String} bmc
   * @returns {Boolean}
   */
  static isMyLabSubscription = (
    courseId = null,
    BMC
  ) => !!(!CommonUtils.isGhostAccount() && CommonUtils.isIntegratedBMC(BMC) && courseId)

  /**
   * Fetches and returns piSession refresh token from local storage
   *
   * @returns {String}
   */
  static getPiRefreshToken = () => {
    let currentRefreshToken = '';
    const { LOCAL_STORAGE_KEYS } = constants;
    const localStorageDataList = Object.entries(window?.localStorage || []);

    if (localStorageDataList.length > 0) {
      const currentData = localStorageDataList.find(item => item[0]?.endsWith(LOCAL_STORAGE_KEYS.REFRESH_TOKEN));

      if (currentData[1]) currentRefreshToken = currentData[1];
    }

    return currentRefreshToken;
  }

  /**
   * Determine wheather user have bundle or subscribed  or Non-subscribed
   *
   * @returns
   */

  static channelsSubStatus = () => {
    const user = getSnapshot(Framework.getStoreRegistry().getStore('user'));
    const channelsSubscriber = CommonUtils.isChannelsSubscriber();
    if(CommonUtils.isBundleSubscriber()){
      return constants.SUBSCRIBER_STATUS.BUNDLE;
    }else if(channelsSubscriber){
      return constants.SUBSCRIBER_STATUS.SUBSCRIBER;
    }else {
      return constants.SUBSCRIBER_STATUS.NON_SUBSCRIBER;
    }
  }

  /**
   * Determine wheather bundle subscribed user or not
   *
   * @returns
   */
  static isBundleSubscriber = () => {
    const user = getSnapshot(Framework.getStoreRegistry().getStore('user'));
    return CommonUtils.hasSubscription(user, [constants.ACTIVE], [constants.BUNDLE]);
  }

  /**
   * Determine wheather Channels Subscriber subscribed user or not
   *
   * @returns
   */
  static isChannelsSubscriber = () => {
    const user = getSnapshot(Framework.getStoreRegistry().getStore('user'));
    return this.isBundleSubscriber()
      || CommonUtils.hasSubscription(user, [constants.ACTIVE], [constants.ENTITLEMENTLEVEL_STUDY])
      || user?.hasChannelsAccess;
  }

  /**
   * Current screen event label
   *
   * @returns
   */
  static getCurrentScreenEventLabel = () => {
    const herobanner = getSnapshot(Framework.getStoreRegistry().getStore('herobanner'));
    const courseCollection = Framework.getStoreRegistry().getStore('coursecollection');
    const { channelMappingType } = courseCollection.getActiveCourseCollection() || {};
    const isRelatedMapping = CommonUtils.localStringCompare(
      channelMappingType || '', constants.CHANNEL_MAPPING_TYPES.RELATED
    );
    let template = herobanner.bannerType;

    if (isRelatedMapping) {
      template = constants.ETEXT_RELATED_CHANNEL;
    }

    return constants.TEMPLATE_LABEL[template] || null;
  }

  static isAbsoluteURL = (url) => {
    const regex = new RegExp('^(?:[a-z+]+:)?//', 'i');

    return regex.test(url);
  }

  static getExamPrepUrl = (url = '') => `${url}/exam-prep`

  static getAssetParamsData(params = {}) {
    return { ...(params || {}) };
  }

  static constructAssetData(response = {}) {
    const {
      // eslint-disable-next-line camelcase
      content_type = null,
      type,
      channelsUrl,
      locationData,
      params,
      completed = false
    } = response || {};
    const {
      chapterTitle = null,
      topicTitle = null
    } = locationData;
    const {
      title,
      duration = null,
      thumbUrl = null,
      question
    } = CommonUtils.getAssetParamsData(params) || {};
    let renderTitle = title;

    if (!CommonUtils.isEmpty(question || {})) {
      const {
        rtext
      } = CommonUtils.getAssetParamsData(question && question.params) || {};
      renderTitle = rtext;
    }

    return {
      assetContentType: content_type,
      assetDuration: duration,
      assetTitle: renderTitle,
      assetType: type,
      chapterTitle,
      thumbUrl,
      topicTitle,
      url: channelsUrl,
      completed
    };
  }

  static filterAssets = (recommendedAssets) => {
    let assets = [];

    if (recommendedAssets && recommendedAssets.length > 0) {
      assets = recommendedAssets.filter(asset => (
        asset !== null
        && asset?.assetType !== constants.ASSET_CONTENT_TYPE.TEXT_BLOCK
        && asset?.assetType !== constants.ASSET_CONTENT_TYPE.IMAGE
      ));
    }

    return assets;
  }

  /**
   * To checkAvailabilityOfBMC to give Array
   * return Boolean
   */
  static checkAvailabilityOfBMC = ( bmc, books ) => {
    return books.some(book => book?.product_entitlements?.business_model_code === bmc &&  book?.product_entitlements?.expired === false);
   } 

  /**
   * To check user if he is a EHI user
   * return Boolean
   */
  static isHEIUser() {
    if(this.isHEIUserStatus){
      return this.isHEIUserStatus;
    }
    const bookshelf = Framework.getStoreRegistry().getStore('bookshelf');
    const books = getSnapshot(bookshelf).books;
    if(books && books.length > 0) {
      this.isHEIUserStatus = this.checkAvailabilityOfBMC(constants.DIRECT_TO_CONSUMER_HEI,books)
    }
    return this.isHEIUserStatus;
  }

  /**
   * call generateUId to get uId and jwtToken
   * @param {*} locationInApp
   * @param {*} calledFrom
   */
  static fetchUIDQRcode = async (locationInApp) => {
    // eslint-disable-next-line no-undef
    this.controller = new AbortController();
    const user = getSnapshot(Framework.getStoreRegistry().getStore('user'));
    const token = user.token;
    const error = { isError: true };
    const { QR_CODE_HTTP_REQUEST_METHODS, QR_CODE_EVENT } = constants;
    fetchEventSource(`${env.MARIN_API_BASE_URL}/qr/generateUId`, {
      method: QR_CODE_HTTP_REQUEST_METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        refreshToken: CommonUtils.getPiRefreshToken()
      }),
      signal: this.controller.signal,
      onmessage(msg) {
        if (msg.event === 'FatalError') {
          Framework.getEventManager().publish(constants.QR_CODE_SERVICE_FETCHED, error);
          CommonUtils.logQRCodeEvent(locationInApp, QR_CODE_EVENT.QR_CODE_API_FAILURE_EVENT_LABEL);
          console.log('onmessage error');
        }
        const { uId, status } = JSON.parse(msg.data);
        if (uId) {
          Framework.getEventManager().publish(constants.QR_CODE_SERVICE_FETCHED, JSON.parse(msg.data));
          CommonUtils.logQRCodeEvent(locationInApp, QR_CODE_EVENT.QR_CODE_EVENT_LABEL);
        }
        if (status) {
          if (status.status && typeof status.status === 'string' && (status.status.toLowerCase() === constants.QR_CODE_STATUS.DONE || status.status.toLowerCase() === constants.QR_CODE_STATUS.EXPIRED)) {
            Framework.getEventManager().publish(constants.QR_CODE_SERVICE_FETCHED, { closeConnection: true });
          }
        }
      },
      onerror(err) {
        if (err) {
          Framework.getEventManager().publish(constants.QR_CODE_SERVICE_FETCHED, error);
          CommonUtils.logQRCodeEvent(locationInApp, QR_CODE_EVENT.QR_CODE_API_FAILURE_EVENT_LABEL);
          throw err;
        }
      }
    });
  }

  /**
  * return menu array based on requirement
  * @returns
  */
  static getHeaderTabsMenuListItems(books, flags, isBookshelfFetched) {
    const isHEIUser = this.isHEIUser();
    const isHEILDShowContent = isHEIUser && !flags.showHEIUserContent;
    const lang = Framework.getStoreRegistry().getStore('language');
    const headerL2TabsMenuList = [];

    if (isHEILDShowContent) {
      headerL2TabsMenuList.push({
        id: constants.HEI_LEARN_LANGUAGE,
        name: lang.getMessage('header.menu.learnLanguage')
      });
    }

    if (!isHEIUser && isBookshelfFetched) {
      headerL2TabsMenuList.push({
        id: constants.MORE_AT_PEARSON,
        name: lang.getMessage('header.menu.moreAtPearson')
      });
    }

     if (books.length > 1 || (books.length > 0 && !CommonUtils.hasActiveBooks(books))) {
       headerL2TabsMenuList.push({
         id: 'MY_ETEXTBOOK',
         name: lang.getMessage('hero.myETextbooks')
       });
     }

    return headerL2TabsMenuList;
  }

   /**
   * return modal object to populate the modal
   *
   *
   * @returns
   */
  static getModalProps() {
    const language = Framework.getStoreRegistry().getStore('language');
    const modalProps = {
      title: language.getMessage('header.continueInTheApp'),
      header: language.getMessage('header.continueInTheApp'),
      subHeader: language.getMessage('header.qrCode.subHeaderTxt'),
      ignoreAppQrCode: language.getMessage('header.qrCode.dontHaveApp'),
      footer: language.getMessage('header.qrCode.footerTxt'),
      close: language.getMessage('onboard.close')
    };
    return modalProps;
  }

  /**
   * method to log abTesting event
   *
   * @param {*} eventLabel
   * @param {*} bookData
   */
  static logABTestingEvent(eventLabel, locationInApp) {
    const bookshelf = Framework.getStoreRegistry().getStore('bookshelf');
    const books = getSnapshot(bookshelf).books;
    const { AUTH_HOME_CATEGORY, EVENT_AUTHHOMEEVENT, ONBOARDING_HEI_AB_TESTING_EVENT } = constants;
    const customFields = {
      location_in_app: locationInApp,
      user_entitlement_mix: CommonUtils.getUserEntitlementMix(books),
      channels_sub_status: CommonUtils.channelsSubStatus()
    };
    CommonUtils.dispatchGaEvent(AUTH_HOME_CATEGORY, EVENT_AUTHHOMEEVENT, ONBOARDING_HEI_AB_TESTING_EVENT.AB_TESTING_ELEMENT_CLICK_EVENT_ACTION, eventLabel, customFields);
  }

  static getMLMRouteBookData = (path) => {
    const decodedPath = CommonUtils.getMLMPath(path);

    if (!decodedPath) return null;

    const pathMatchRegex = /^(?:\/courses\/([^/]+))?(?:\/products\/([^/]+))?$/;

    const pathMatch = decodedPath.match(pathMatchRegex);
    const courseId = (pathMatch && pathMatch[1]) || null;
    const productId = (pathMatch && pathMatch[2]) || null;

    return {
      courseId,
      bookId: productId
    };
  }

  /**
   * Add UTM Parameter and amend Url hash at end of the Url incase
   *
  */
  static getUrlWithUTMParams = (url, utmParams) => {
    let locationUrl = url;
    if (url && !url.includes(utmParams)) {
      const hashReg = new RegExp('#.+=([^&|/|?]+)', 'i');
      const hashMatch = url.match(hashReg);
      let urlHash = '';
      if (hashMatch) {
        urlHash = hashMatch[0];
        locationUrl = locationUrl.replace(urlHash, '');
      }
      locationUrl = PathUtils.addQueryParameter(utmParams, locationUrl) + urlHash;
    }

    return locationUrl;
  }

  /* method to log QR code event
   *
   * @param {*} eventLabel
   * @param {*} bookData
   */
  static logQRCodeEvent(locationInApp, eventLabel) {
    const { AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, QR_CODE_EVENT } = constants;
    const customFields = {
      location_in_app: locationInApp,
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, QR_CODE_EVENT.CONTINUE_IN_APP_CLICK_EVENT_ACTION, eventLabel, customFields);
  }

  static getMLMPath = (path) => {
    const {
      mlmcontextencoded
    } = path?.params || {};

    try {
      // eslint-disable-next-line no-undef
      return atob(mlmcontextencoded);
    } catch (err) {
      return null;
    }
  }

  static getBase64Decoded = (encodedValue) => {
    try {
      // eslint-disable-next-line no-undef
      return atob(encodedValue);
    } catch (err) {
      return null;
    }
  }

  /**
   * byLastAccessedTime === true (Returns last accessed books)
   * byLastAccessedTime === false (Returns newly added books)
   *
   * @param {Array} books
   * @param {Boolean} byLastAccessedTime
   * @returns
   */
  static getConditionalActiveBooks = (books, byLastAccessedTime) => {
    const filterBooks = books.filter(book => (
      (byLastAccessedTime ? book.last_accessed_time : !book.last_accessed_time)
      && CommonUtils.isActiveBook(book)
      && !book.isArchived));

    if (!byLastAccessedTime && filterBooks.length > 1) {
      filterBooks.sort((a, b) => {
        const bookATimestamp = new Date(a?.product_entitlements?.start_date || null)?.getTime();
        const bookBTimestamp = new Date(b?.product_entitlements?.start_date || null)?.getTime();

        return (bookBTimestamp - bookATimestamp);
      });
    }

    return { firstBook: filterBooks[0] || null, books: filterBooks };
  }

  /**
   * Sorts and returns collections based on createdDate property
   *
   * @param {Array} userCollections
   * @returns
   */
  static getRecentlyAddedCourses = (userCollections) => {
    if (userCollections.length <= 1) return userCollections;

    const collections = [...userCollections];
    collections.sort((a, b) => b.createdDate - a.createdDate);

    return collections;
  }

  /**
   * Returns last accessed book's course collection index
   *
   * @param {Array} courses
   */
  static getLastAccessedBookIdx = (courses = [], userCollections = []) => {
    const findPriorityFlow = [];
    let priorityFlow = null;
    let foundBook = { book_id: null, course_id: null, product_id: null };
    const bookshelf = Framework.getStoreRegistry().getStore('bookshelf');
    const { books } = getSnapshot(bookshelf);
    const lastAccessedBooks = CommonUtils.getConditionalActiveBooks(books, true) || {};
    const newAdded = CommonUtils.getConditionalActiveBooks(books, false) || {};
    const recentlyAddedCourse = CommonUtils.getRecentlyAddedCourses(userCollections)[0];
    const {
      last_accessed_time: lastAccessedBooktime = null
    } = lastAccessedBooks.firstBook || {};

    const {
      product_entitlements: newlyAddedBookEntitlements = null
    } = newAdded.firstBook || {};

    const {
      createdDate: recentlyAddedCourseTime = null
    } = recentlyAddedCourse || {};

    if (lastAccessedBooktime) {
      findPriorityFlow.push({
        name: 'lastAccessedBook', timeStamp: lastAccessedBooktime, value: lastAccessedBooks.firstBook
      });
    }
    if (newlyAddedBookEntitlements && newlyAddedBookEntitlements.start_date) {
      const newlyAddedBookTime = new Date(newlyAddedBookEntitlements.start_date).getTime();
      findPriorityFlow.push({ name: 'newlyAddedBook', timeStamp: newlyAddedBookTime, value: newAdded.firstBook });
    }
    if (recentlyAddedCourseTime) {
      findPriorityFlow.push({
        name: 'recentlyAddedCourse', timeStamp: recentlyAddedCourseTime, value: recentlyAddedCourse?.name || null
      });
    }

    if (findPriorityFlow.length > 0) {
      // Sorting out the timestamp to findout which flow like recent course access or recently access book or newly added book
      findPriorityFlow.sort((a, b) => b.timeStamp - a.timeStamp);
      priorityFlow = findPriorityFlow[0];
      if (priorityFlow.name === 'lastAccessedBook' || priorityFlow.name === 'newlyAddedBook') {
        foundBook = priorityFlow.value;
      }
    }

    return courses.findIndex((course) => {
      CommonUtils.moveBookToFirstPosition(course, {
        bookId: foundBook.book_id,
        courseId: foundBook.course_id,
        productId: foundBook.product_id
      });
      const {
        bookId = null,
        courseId = null,
        productId = null
      } = (course && course.collection && course.collection[0]) || {};

      if (priorityFlow?.name === 'recentlyAddedCourse') {
        return priorityFlow?.value === course?.id;
      }

      return (
        foundBook.book_id === bookId
        && foundBook.course_id === courseId
        && foundBook.product_id === productId
      );
    });
  }

  /**
  *
  * Return the CTS based one channel mapping
  * @param {*} channelCards
  * @param {*} isBundleUser
  * @returns
  */
  static getChannelContainerCopytext(channelCards = [], isBundleUser) {
    const language = Framework.getStoreRegistry().getStore('language');
    let returnText = 'channel.genericBundle';
    if (channelCards && channelCards.some(ele => ele.recommended)) {
      returnText = 'channel.relatedBundle';
    }

    return language.getMessage(isBundleUser ? returnText : 'channel.noBundletitle');
  }

  /**
   *
   * Returns true if it is algoliaSearch else return false
   * @param {Object} userOrProductSubscription
   * @returns
   */
  static isAlgoliaSearch(user, books = []) {
    const hasActiveBooks = CommonUtils.hasActiveBooks(books);
    const isPplusUser = CommonUtils.isPPlusUser(user, constants.AUTH_HOME);
    const bookLengthCheck = books?.length > 0;
    return !bookLengthCheck || !hasActiveBooks || isPplusUser;
  }

  /**
   *
   * Returns current value based on the channel mapping type
   * @param {*} heroBook
   * @returns
   */
  static containerValue(heroBook = {}) {
    let template = constants.TEMPLATE_LABEL.BOOK;
    const isExactMapping = CommonUtils.localStringCompare(
      heroBook.channel_mapping_type, constants.CHANNEL_MAPPING_TYPES.EXACT
    );
    if (heroBook.channel_id
      && isExactMapping
    ) {
      template = constants.TEMPLATE_LABEL.BOOK_WITH_CHANNEL;
    }

    return template;
  }

  /**
   * Function to push telementry/GA event
   * @param {string} event_type
   * @param {object} additionalFields or extraFields
   */
  static dispatchAIGAEvent(eventType, additionalFields = null) {
    const gaEventData = GAConstants.GAEvents[eventType];
    const timeStamp = {
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      event_unix_timestamp: new Date().getTime()
    };
    const eventContent = {
      ...gaEventData,
      ...additionalFields,
      ...timeStamp
    };

    if (gaEventData) EventProvider.getEventManager().dispatch(eventContent);
  }

  /**
   * Returns channel added source
   *
   * @param {*} channelCard
   * @returns
   */
  static getChannelAddedSource = (channelCard) => {
    let source = constants.LOCATION_IN_APP.NEW_OTHER_COURSE_CONTAINER;
    const courseCollection = Framework.getStoreRegistry().getStore('coursecollection');
    const {
      channelMappingType
    } = courseCollection.getActiveCourseCollection() || {};
    const isRelatedMapping = CommonUtils.localStringCompare(
      channelMappingType, constants.CHANNEL_MAPPING_TYPES.RELATED
    );

    const {
      isMostRelated,
      recommended
    } = channelCard || {};

    if (isMostRelated) source = constants.LOCATION_IN_APP.NEW_MOST_RELATED_CONTAINER;
    else if (recommended) source = constants.LOCATION_IN_APP.NEW_OTHER_COURSE_CONTAINER_RELATED;
    else if (isRelatedMapping) source = constants.LOCATION_IN_APP.NEW_OTHER_COURSE_CONTAINER_UNRELATED;

    return source;
  }

  /**
   * Returns current hero template
   *
   * @param {*} bannerType
   * @returns
   */
  static getCurrentHeroTemplate = (bannerType) => {
    const courseCollection = Framework.getStoreRegistry().getStore('coursecollection');
    const {
      channelMappingType
    } = courseCollection.getActiveCourseCollection() || {};
    const isRelatedMapping = CommonUtils.localStringCompare(
      channelMappingType, constants.CHANNEL_MAPPING_TYPES.RELATED
    );

    if (isRelatedMapping && bannerType === constants.HERO_BANNER_TYPES.BOOK) {
      return constants.TEMPLATE_LABEL.ETEXT_RELATED_CHANNEL;
    }

    return bannerType;
  }

  static secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return {
      h: hours,
      m: minutes,
      s: seconds
    };
  }

  /**
   * Common config for AI in both AH and Ereader
   *
   * @param {*} flags
   * @param {*} bookData
   * @param {*} extraConfig
   * @returns
   */
  static commonAIConfig = (flags, bookData, extraConfig = {}) => {
    const config = {};
    const { enableNewMathConfig } = flags;
    const { isAITutorEnabled } = extraConfig;

    config.enableNewMathConfig = enableNewMathConfig && isAITutorEnabled && bookData.isAITutorSupported === constants.REVIEW;

    return config;
  }

  /**
   * Returns true if current book is inside the books array
   *
   * @param {Array} books
   * @param {object} currentBook
   * @returns
   */
  static isTitleInList = (books = [], currentBook = {}) => books.some(
    book => book?.bookId === currentBook?.bookId || book?.productId === currentBook?.productId
  )
}
