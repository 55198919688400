/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Turkish language
 *
 * @file tr-TR.js
 * @author Manimaran.S
 */

const trTR = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': 'Uygulamayı başka bir cihaza indirmek ve otomatik olarak oturum açmak veya yüklediğin uygulamada güvenli bir şekilde oturum açmak için benzersiz QR kodunu kullanmak için %Click here% tıkla.',
  'moremenu.downloadAppNotDownload': 'Uygulamayı indirmek ve benzersiz QR kodunla güvenli bir şekilde oturum açmak için %Click here% tıkla',
  'channel.bundleTitle': 'Diğer kurslarınız için Çalışma ve Sınava Hazırlık Paketine erişin',
  'channel.noBundletitle': 'Diğer kurslarınızda yardım almak için Çalışma ve Sınava Hazırlığı kullanın',
  'onboard.welcome': 'Pearson+’ya hoşgeldin',
  'onboard.welcomeCourse': '<span>{COURSE_NAME}</span> ve çok daha fazlasına erişmek için doğru yerdesin.',
  'onboard.welcomeCourseList': 'Şunu satın aldınız:',
  'onboard.next': 'Sonraki',
  'onboard.studyPlan':'Özel çalışma planı oluşturuldu!',
  'onboard.addCourseAnother': 'Bir başka kurs eklemek ister misin?',
  'onboard.purchase': 'Pearson e-Ders Kitabı satın almam gerekiyor',
  'onboard.addCourseWithoutEtext': 'e-Ders Kitabı olmadan kurs ekle',
  'onboard.addCourseWithoutEtextBundle': 'Çalışma ve Sınava Hazırlık’ı kullanarak kurs ekleyin',
  'onboard.doYouWantOtherCourse': 'Bir başka kurs eklemek ister misin?',
  'onboard.mayBeLater': 'Belki sonra',
  'onboard.close': 'Close',
  'course.addTitle': 'Kurs ekle',
  'bookshelf.TITLE': 'Kitap rafım',
  'common.GO_HOME': 'Ana Sayfaya Git',
  'common.LOGOUT': 'Oturumu kapat',
  'common.PURCHASE': 'Satın Al',
  'courses.action.SHOW_ASSIGNMENTS': 'Görevleri Göster',
  'courses.toc.PUBLISH_MESSAGE': 'Öğrenciler, bir dahaki sefer içeriği açtıklarında değişikliklerinizi görürler.',
  'courses.toc.PUBLISH_SUCCESS': 'Başarılı!',
  'courses.WELCOME': 'Merhaba {userName}, {courseName} kursunuza hoş geldiniz!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Hesabınız bu başlığa erişimi içermiyor. Bu mesajı yanlışlıkla aldığınızı düşünüyorsanız, lütfen Pearson Teknik Destek ile iletişime geçin.',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonelik Bulunamadı',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Aboneliğiniz eText`e erişimi içermiyor, ancak şu anda bir kredi kartı kullanarak abone olabilirsiniz.',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonelik Bulunamadı',
  'foxit.ABOUT': 'Hakkında',
  // eslint-disable-next-line max-len
  'foxit.COPY_RIGHT': 'PDF Reader Foxit tarafından desteklenmektedir. Telif Hakkı (C) 2003-2019 Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Mobil uygulamayı indir',
  'menu.ABOUT': 'Hakkında',
  'menu.AVAILABLE': 'Ayrıca mevcut',
  'menu.CONTACT_SUPPORT': 'Desteğe Başvur',
  'menu.COPY_RIGHT': 'Telif Hakkı \u00A9 {year} Pearson Education, Inc. Tüm hakları saklıdır.',
  'menu.FOXIT': 'Foxit tarafından desteklenmektedir',
  'menu.GET_LOOSE_LEAF': 'Föy alın',
  'menu.PRINT_PAGE': 'Sayfayı yazdır',
  'menu.PRINT_VERSION': 'Basılı sürümü ister misiniz?',
  'menu.PRIVACY_POLICY': 'Gizlilik Politikası',
  'menu.SIGN_OUT': 'Oturumu Kapat',
  'menu.TERMS_OF_USE': 'Kullanım Şartları',
  'menu.tooltip.SCHEDULE': 'Program',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Merhaba {userName}',
  'device.SWAPPED_ALREADY': 'Bu ay zaten değiştirme yaptın',
  'device.SWITCH_DEVICE': 'Mevcut cihaza geçilsin mi?',
  'device.SWITCH_ONCE': 'Ayda yalnızca bir kez geçiş yapabileceğin için bunu geri alamazsın.',
  'device.SEAT_TAKEN': 'Bu koltuk dolu',
  'device.DISCONNECT_DEVICE': 'Sadece 3 cihazdan giriş yapabilirsin. Bu cihazla devam etmek için başka bir cihazın bağlantısını kes.',
  'device.ALERT_TEXT': 'Unutmayın, cihazları yalnızca ayda bir kez değiştirebilirsiniz.',
  'device.YOUR_MOJO': 'Mojo\'ya erişmek üzeresin',
  'device.DISCONNECT': 'Bağlantıyı kes',
  'device.CONTINUE': 'Devam et',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Ana sayfaya geri dön',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Buraya kodunuzu girmek için {time} süreniz var',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Yanlış—kodunuzu tekrar kontrol edin veya yeniden gönderin.',
  'device.MANY_ATTEMPTS': 'Çok fazla deneme yaptınız, kodu yeniden göndermeyi deneyin',
  'device.EXPIRED': 'Süreniz doldu, kodu yeniden göndermeyi deneyin',
  'device.ACCESS_HEADER': 'Giriş yaptınız!',
  'device.ACCESS_DESC': 'Oturumunuz doğrulandı. Hazır buradayken, şifrenizi güncellemek ister misiniz?',
  'device.ACCESS_CHANGE_PASSWORD': 'Şifreyi değiştir',
  'device.ACCESS_RESET_PASSWORD': 'Şifreyi sıfırla',
  'device.THREATONE_TITLE': 'Herşey yolunda mı?',
  'device.THREATONE_DESC': 'Hesabınızda bazı şüpheli etkinlikler tespit ediyoruz.',
  'device.THREATONE_DESC_TWO': 'Şifrenizi değiştirmeniz iyi olur.',
  'device.MAY_BE_LATER': 'Belki sonra',
  'device.OKAY': 'Принято',
  'device.ACCOUNT_LOCK_DESC': 'Güvende tutmak için oturumunuzu kapatıyoruz. Hesabınızla ilişkili e-postaya bir şifre sıfırlama bağlantısı gönderdik.',
  'device.ACCOUNT_LOCK_TITLE': 'Hesabınızda bazı şüpheli etkinlikler tespit ediyoruz.',
  'device.ACCOUNT_LOCK_TIMER': 'Şu kadar zamanda oturumunuz kapatılacak {time}',
  'menu.myAccount': 'Tercihler Merkezi',
  'menu.helpCenter': 'Yardım merkezi',
  'menu.preferencesCenter': 'Tercihler Merkezi',
  'menu.HELP_SUPPORT': 'Yardım & destek',
  'menu.FAQS': 'SSS',
  'menu.CHAT_WITH_SUPPORT': 'Destekle sohbet et',
  'menu.QUICK_WALKTHROUGH': 'Hızlı gezinti',
  'menu.LEGAL': 'Yasal',
  'menu.PRIVACY_NOTICE': 'Gizlilik bildirimi',
  'menu.TITLE_INFO': 'Başlık bilgisi',
  'menu.PEARSON_ACCESSIBILITY': 'Pearson erişilebilirliği',
  'menu.DEVICE_WARNING_MESSAGE': '{deviceLimit} cihaz kaydedin. Ayda 1 değiştirin.',
  'menu.ACCESSIBILITY': 'Erişilebilirlik',
  'menu.ACCESSIBILITY_SUPPORT': 'Erişilebilirlik desteği',
  'menu.DEVICES': 'Son cihazlar',
  'menu.THIS_DEVICE': 'Bu cihaz',
  'menu.MOJO_APP_INFO': 'Pearson+\'yı çevrimdışı kullanmak için, telefonunuzdan veya tabletinizden uygulama mağazasına gidin.',
  'menu.DEVICE_LABEL': 'Cihaz',
  'navigation.PAGE': 'Sayfa',
  'launchcode.CREATEBY': 'Oluşturan:',
  'launchcode.sentvia': 'Şununla gönderildi:',
  'launchcode.CODEINLAST': 'Son 7 gün içindeki kodlar:',
  'launchcode.TIMESENT': 'Gönderilme saati:',
  'launchcode.ACCOUNTEMAIL': 'Hesap e-postası:',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP Kodu Kaydı',
  'menu.OTP_CODES': 'OTP kodları',
  'menu.LAUNCH_CODE': 'Kod kaydını aç',
  'menu.SEND_CODE': 'Yeni kodu kullanıcıya e-posta ile gönder',
  'menu.SEND_CODE_PHONE': 'Yeni kodu kullanıcıya kısa mesaj ile gönder',
  'menu.COMMUNITY_GUIDELINES': 'Topluluk kuralları',
  'textmessage.TITLE': 'Telefon Numarasını girin',
  'textmessage.PHONE_NUMBER': 'Telefon Numarası',
  'textmessage.SEND_CODE': 'Kodu Gönder',
  'textmessage.INVALID_NUMBER': 'Geçersiz Telefon Numarası.',
  'textmessage.SUCCESS_MESSAGE': 'Kod gönderildi',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Listende ara',
  'remove.title': 'Başlık kaldırılsın mı?',
  'remove.subTitle': 'Daha sonra istediğiniz zaman geri alabilirsiniz\n— yeniden eklemek için aramayı kullanmanız yeterlidir.',
  'remove.buttonText': 'Kaldır',
  'footer.downloadMobileApp': 'Mobil uygulamayı indir',
  'footer.termsOfUse': 'Kullanım şartları',
  'footer.privacy': 'Gizlilik',
  'footer.cookies': 'Çerezler',
  'footer.doNotSellMyPersonalInformation': 'Kişisel bilgilerimi satmayın',
  'footer.accessibility': 'Erişilebilirlik',
  'footer.patentNotice': 'Patent bildirimi',
  'footer.copyrights': 'Pearson, Tüm hakları saklıdır.',
  'channel.videos': 'Videolar',
  'channel.questions': 'Sorular',
  'channel.descriptionA': 'Okumanızı Kanallarla destekleyin.',
  'channel.descriptionB': 'Kanallar ile zor konularda yardım alın.',
  'channel.openchannel': 'Açık Kanal',
  'hero.continueReading': 'Okumaya devam et',
  'hero.openBook': 'Kitabı aç',
  'hero.channel.open': 'Açık Kanal',
  'hero.addATitle': 'Bir başlık ekle',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Etkin olmayan başlıkları görüntüle',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'e-Ders Kitabınızı bulun'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'e-Ders Kitabını Aç',
  'hero.openEtextBookV1': 'e-Ders Kitabını Aç',
  'hero.heroHeading': '<span>{channel_title}</span>Çalıştığınız için',
  'hero.heroHeadingForBundleUser': 'Çalışma ve Sınav Hazırlık Paketi',
  'hero.study.help': 'Çalışma yardımı alın',
  'hero.study.jumpBackIn': 'Geri dön',
  'hero.study.numOfUsers': 'Şu anda Kanalları kullanan <b>{num_of_users}</b> öğrenci var.',
  'hero.heroBook.sectionHeading': 'Kursunuzda sürprizlerle karşılaşmayın',
  'hero.heroBook.channelsSectionHeading': 'Yeni bir çalışma seansı başlatın.',
  'hero.heroBook.sectionHeadingNewUser': 'Çalışma yardımı burada',
  'hero.heroBook.sectionSubHeading': 'Kursunuza özel alıştırma soruları ve video açıklamaları ile zor konuları çözün.',
  'hero.heroChannel.cardsHeading': 'Kaldığın yerden devam et',
  'hero.marketingcard.subheading': 'Zor kavramları açıklayan videolar ve kursunuza göre uyarlanmış ekstra alıştırma problemleri ile daha verimli çalışın.',
  'hero.marketingcard1.copy.title': 'Videolarla öğrenin',
  'hero.marketingcard1.copy.description': 'Zor konuları açıklayan kısa videoları izleyin.',
  'hero.marketingcard2.copy.title': 'Problemlerle pratik yapın',
  'hero.marketingcard2.copy.description': 'Binlerce sınav benzeri soru ve video çözümleri ile sınavınıza hazırlanın.',
  'hero.marketingcard3.copy.title': 'Uzman yardımı alın',
  'hero.marketingcard3.copy.description': 'Sorularınızı gönderin ve konunun uzmanlarından yanıt alın.',
  'hero.popularTopics.heading': 'Bu kanaldaki diğer popüler konular',
  'hero.popularChannels.heading': 'Popüler Kanallar',
  'hero.popularChannels.headingv1': 'Başka bir kurs mu alıyorsun?',
  'hero.popularChannels.description': 'Bu en iyi Kanallarla çalışma yardımı alın.',
  'hero.popularChannels.descriptionv1': 'Bir kanal seçerek bir başka kurs için yardım alın.',
  'hero.popularChannels.descriptionv2': 'Popüler çalışma yardımı konularına göz atın.',
  'hero.startReading': 'Okumaya başlayın',
  'hero.practice': 'Alıştırma yap',
  'hero.noTitleNoChannel.sectionHeading': 'Rekor sürede sınava hazır hale gelin',
  'hero.herobook.mappedChannelHeading': 'Bu Kanalda önerilen çalışma yardımı',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Zor konuları açıklayan kısa videolar',
  'hero.promotionalcard.list2': 'Binlerce sınav benzeri soru',
  'hero.promotionalcard.list3': 'Konu uzmanlarından yanıtlar alın',
  'hero.studyandexamprep': 'Çalışma ve Sınava Hazırlık',
  'hero.heroBook.sectionSubHeadingV1': 'Kursunuza özel alıştırma soruları ve video açıklamaları ile zor konuları çözün.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Daha fazla bilgi edin',
  'hero.subscriptionStatusTitle': 'Pearson+ üyelik durumu',
  'hero.eTextbook': 'e-Ders Kitabı',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Sayfaya git',
  'subscription.eTextbook': 'e-Ders Kitabı',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Çalışma ve Sınav Hazırlık Paketi',
  'subscription.studyNoBundle': 'Çalışma ve Sınava Hazırlık',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'e-Ders Kitabınızı bulun',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Kurs Kontrol Panelinden kaldırılsın mı?',
  'hero.removecourse.description': '“Kurs ekle” seçeneğine tıklayarak bu kursu her zaman kontrol paneline geri ekleyebilirsin',
  'hero.removecourse.primaryCtaText': 'Kaldır',
  'hero.removecourse.secondaryCtaText': 'İptal Et',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'E-Ders Kitaplarını ara',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Ücretsiz olarak yeni bir dil öğrenin',
  'hero.mondly.learnLang.title2': 'Ücretsiz olarak yeni bir dil öğrenin!',
  'hero.mondly.subTitle': 'Pearson e-Ders Kitabı kullanıcıları üç aylık ücretsiz Mondly by Pearson kazanıyor.',
  'hero.mondly.description': 'Çoğu dil öğrenme uygulaması yalnızca İngilizce üzerinden dil öğrenmenize izin verir. Ancak en iyisi ana diliniz üzerinden öğrenmektir, bu nedenle Mondly 41 dilimizden herhangi biriyle öğrenmenize imkan tanır.',
  'hero.mondly.startLearningCTA': 'Öğrenmeye başla',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Daha fazlası Pearson\'da',
  'hero.extractingExamDetails': 'Sınav ayrıntıları alınıyor',
  'hero.finishingTheProcess': 'İşlem tamamlanıyor',
  'hero.hereWhatWeGotFromYourSyllabus': 'Ders programınızdan aldığımız bilgiler şunlar',
  'hero.school': 'Okul:',
  'hero.class': 'Sınıf:',
  'hero.textbook': 'Ders Kitabı:',
  'hero.examSchedule': 'Sınav Takvimi:',
  'hero.confirm': 'Onayla',
  'onboard.aboutCourse': 'Bize kursunuz hakkında bilgi verin',
  'onboard.studyMaterials': 'Konunuzu seçin, sizin için özelleştirilmiş çalışma materyalleri içeren bir pano oluşturalım.',
  'hero.personalizedStudyPlan': 'Size kişiselleştirilmiş bir çalışma planı sunmak için yapay zekayı kullanacağız.',
  'hero.welcomeToP': 'P+’ya Hoş Geldiniz!',
  'hero.learnYourWayAround': 'Genel bir fikir edinmek için şimdi bir dakikanızı ayırın, ya da buna başka bir zaman hesap ayarlarından erişin.',
  'hero.weveCreatedADashboard': 'Kursunuz için burada bir pano oluşturduk.',
  'hero.youCanAddYourPearsonCourse': 'Ayrıca Pearson dışında kendi kurslarınızı ekleyebilir ve çalışma materyallerini ücretsiz olarak deneyebilirsiniz.',
  'libraryL2.back': 'Geri',
  'hero.getExamreadyWithVideoLessons': 'Kursunuza göre özelleştirilmiş video dersler ve alıştırma setleri ile sınava hazırlanın.',
  'hero.youreTexbook': 'E-Ders Kitabınız ve tüm araçları burada.',
  'hero.VideosAndPractice': 'Çalışma ve Sınava Hazırlık bölümünde her zaman daha fazla video ve alıştırma sorusu mevcuttur.',
  'hero.pleaseSelectTheChapters': 'Lütfen bu sınavda ele alınan bölümleri seçiniz:',
  'hero.tableOfContents': 'İçindekiler',
  'hero.search': 'Ara',
  'hero.notes': 'Notlar ve Vurgulananlar',
  'hero.doYouWantToAddACourse': 'Bir kurs eklemek ister misin?',
  'hero.whatsAvailableForYou': 'Sizin İçin Neler Var?',
  'hero.whatsAvailableForYouInPearson': 'Pearson+’da Sizin İçin Neler Var?',
  'hero.continueStudying': 'Çalışmaya devam et',
  'hero.startStudying': 'Çalışmaya başla',
  'hero.unlockYourStudyExamPrep': 'Diğer Kurslar için Çalışma ve Sınava Hazırlık Paketinizin kilidini şimdi açın!',
  'header.menu.learnLanguage': 'Bir dil öğrenin',
  'hero.subscriptionStatusTitle2': 'Pearson+ kaynaklarınız',
  'hero.getExamreadyForYou': 'Çalışma ve Sınava Hazırlığı kullanarak diğer kurslarınız için sınava hazır olun.',
  'hero.weVideoLessons': 'İhtiyaçlarınıza göre özelleştirilmiş video derslerimiz ve alıştırma setlerimiz var.',
  'hero.tryForFree': 'Ücretsiz deneyin',
  'hero.addCourseUsingStudyExamPrep': 'Çalışma ve Sınava Hazırlık’ı kullanarak kurs ekleyin',
  'hero.for': 'İçin',
  'hero.studyExamPrepPackForAllCourse': 'Tüm kurslarınız için Çalışma ve Sınav Hazırlık Paketi',
  'hero.myETextbooks': 'e-Ders Kitaplarım',
  'header.continueInTheApp': 'Uygulama ile devam et',
  'hero.goToMyLabMastering': 'MyLab/Mastering\'e git',
  'hero.learnTxt': 'Öğren',
  'hero.aITutorTxt': 'Yapay Zeka Eğitmeni',
  'hero.exploreTxt': 'Keşfet',
  'hero.studyExamPrepAllYourCourses': 'Tüm kurslarınız için Çalışma ve Sınava Hazırlık',
  'hero.examSchedules': 'Sınav takvimi',
  'hero.examSchedules2': 'Sınav takvimi',
  'hero.uploadYourSyllabus': 'Ders programını yükle',
  'hero.addAnExam': 'Bir sınav ekle',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Çalışma ve Sınava Hazırlık Paketinizden en iyi şekilde yararlanmak için ders programınızı yükleyin',
  'hero.UploadYourSyllabus.studyExamPrep': 'Çalışma ve Sınav Hazırlığınızdan en iyi şekilde yararlanmak için ders programınızı yükleyin',
  'hero.getRecommendationsOnWhat': 'Neyi ne zaman çalışmanız gerektiği konusunda öneriler alın',
  'hero.noSyllabusSharingExamSchedule': 'Ders programınız yok mu? Sınav takviminizi paylaşmanız da yeterli',
  'hero.selectDropSyllabusFile': 'PDF veya DOCX formatında bir ders programı dosyası seçin veya sürükleyip bırakın',
  'hero.uploadTxt': 'Yükle',
  'hero.examName': 'Sınav adı',
  'hero.completeTxt': 'Tamamla',
  'hero.exactTopicsChapter': 'Her bölüm için tam konuları daha sonra belirleyebileceksiniz.',
  'hero.uploadingDocument': 'Belge yükleniyor',
  'hero.activatingAIComponents': 'Yapay zeka bileşenleri etkinleştiriliyor',
  'hero.extractingClassSchoolData': 'Sınıf ve okul verileri alınıyor',
  'hero.extractingTextbookChapters': 'Ders kitabı bölümleri alınıyor',
  'hero.insideStudyExamPrepPack': 'Dahili Çalışma ve Sınava Hazırlık Paketi',
  'hero.userSubsGuidence': 'Sınav programınıza göre uyarlanmış video derslere, çalışma planlarına ve alıştırma setlerine sınırsız erişiminiz var. Ayrıca, soru sorabilir ve konu uzmanlarından görüş alabilirsiniz.',
  'hero.examPrepTxt': 'Sınava hazırlık',
  'hero.extractingDataFromSyllabus': 'Ders programından veri alınıyor...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': 'Dahili Çalışma ve Sınava Hazırlık',
  'hero.testVideoLessons': 'Sınav programınıza göre uyarlanmış video derslerini, çalışma planlarını ve alıştırma setlerini deneyin.',
  'header.qrCode.subHeaderTxt': 'Mobil cihazınızda Pearson+ uygulamasında hızlı ve güvenli bir şekilde oturum açmak için bu QR kodunu kullanın.',
  'header.qrCode.dontHaveApp': 'Uygulamaya sahip değil misiniz?',
  'header.qrCode.footerTxt': 'Bu kodu taradığınızda App Store veya Google Play\'e yönlendirileceksiniz. Uygulama indirildikten sonra kodu tekrar tarayarak oturum açın.',
  'header.qrCode.errorMsg': 'QR kodu yüklenemedi. Kapatın ve tekrar deneyin.',
  'hero.yourETextbook': 'E-Ders Kitabınız',
  'onboard.addCourseWithEtextBundle': 'Çalışma ve Sınava Hazırlık Paketi’ni kullanarak kurs ekleyin',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': 'Dahili Çalışma ve Sınava Hazırlığa Erişin',
  'channel.genericBundle': 'Diğer kurslarınızda yardım almak için Çalışma ve Sınava Hazırlık Paketini kullanın',
  'channel.relatedBundle': 'Ayrıca, diğer kurslarınızda yardım almak için Çalışma ve Sınav Hazırlık Paketinizi kullanın',
  'onboard.courseAdded': 'Kurs eklendi!',
  'hero.flashcards': 'Flashcard´lar',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use this QR code to open the Pearson+ app on your mobile device.',
  'print.qrCode.footer': 'Scanning this code will redirect you to the App store or Google Play. Once the app is installed, sign in to download and access your title.'
};

export default trTR;
