/* eslint-disable no-nested-ternary */
/*
* PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
* Copyright © 2019 Pearson Education, Inc.
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Pearson Education, Inc. The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
*/

/**
 * React container component for course
 *
 * @file CourseContainer.jsx
 * @author Hari Gangadharan & Arish Kumar K
 */

import React, {
  PureComponent, Fragment, Suspense, createRef
} from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import {
  CircularProgress,
  IconButton
} from '@material-ui/core';

import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';


import CommonPlugin from '../../common/plugins/CommonPlugin';
import BrazeTemplatePlugin from '../braze/BrazeTemplatePlugin';
import BrazePlugin from '../braze';
import Asset from './model/Asset';
import Course from './model/Course';
import CourseListData from './model/CourseListData';
import env from '../../common/env';
import PathUtils from '../../common/utils/PathUtils';
import ProductToken from '../products/model/ProductToken';
import AuthHandler from '../../common/AuthHandler';
import MoreMenuHandler from '../../common/MoreMenuHandler';
import BackLinkClient from '../../common/BackLinkClient';
import HotspotUtils from '../../common/utils/HotspotUtils';
import PdfHotspot from './model/PdfHotspot';
import GlossaryHotspot from './model/GlossaryHotspot';
import LanguageContext from '../../contexts/LanguageContext';
import CommonUtils from '../../common/utils/CommonUtils';
import ErrorCard from '../../common/components/ErrorCard';
import '../../assets/styles/glossaryPopup.css';
import printIcon from '../../common/icons/printIcon';
import $ from 'jquery';
import DeviceManagement from '../../common/components/DeviceManagement';

import LaunchCode from '../../common/LaunchCode';
import TextMessage from '../../common/TextMessage';
import * as constants from '../../common/constants';
import LaunchLog from '../../common/model/LaunchLog';
import FloatingButton from '../../common/components/FloatingButton';
import BrazeContainer from '../braze/BrazeContainer';
import BrazeUtils from '../../common/utils/BrazeUtils';
import NativeAppBannerPlugin from '../../common/plugins/NativeAppBannerPlugin';
import ProductUtils from '../../common/utils/ProductUtils';
import QRCodeButton from '../../common/components/qrcodebutton/QRCodeButton';
import QRCodeModal from '../../common/components/qrcodemodal/QRCodeModal';
import SessionWarningPlugin from '../sessionWarning/plugin/sessionWarningPlugin';

const isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();

/**
 * Lazy Loading Components
 */
const HotspotContainer = React.lazy(() => import('../../common/components/HotspotContainer'));
const GlossaryComponent = React.lazy(() => import('../../common/components/GlossaryComponent'));
const NotesCopyComponent = !isIntergratedMLMLaunch ? React.lazy(
  () => import('@vega/notes-copy').then(module => ({ default: module.NotesCopyComponent }))
) : Fragment;
const VegaReader = CommonUtils.lazyWithPreload(() => import('@vega/vega-reader')
  .then(module => ({ default: module.VegaReader })));

@inject('course', 'courseStatus', 'productToken', 'user', 'asset',
  'assetStatus', 'courseItems', 'language',
  'pdfHotspot', 'glossaryHotspot', 'device', 'launchLog')
@observer
class CourseContainer extends PureComponent {
  static propTypes = {
    course: shapes.modelOf(Course).isRequired,
    courseStatus: shapes.state.isRequired,
    courseItems: shapes.modelOf(CourseListData).isRequired,
    assetStatus: shapes.state.isRequired,
    productToken: shapes.modelOf(ProductToken).isRequired,
    user: shapes.user.isRequired,
    ldClient: PropTypes.object,
    device: PropTypes.object.isRequired,
    match: shapes.match.isRequired,
    history: shapes.history.isRequired,
    asset: shapes.modelOf(Asset).isRequired,
    language: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pdfHotspot: shapes.modelOf(PdfHotspot).isRequired,
    glossaryHotspot: shapes.modelOf(GlossaryHotspot).isRequired,
    launchLog: shapes.modelOf(LaunchLog).isRequired
  };

  static childContextTypes = {
    preferenceContext: PropTypes.object,
    studyContext: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.qrCodeButtonRef = createRef(null);
    this.brazePlugin = new BrazePlugin(this);
    this.commonPlugin = new CommonPlugin(this, constants.EREADER);
    this.brazeTemplatePlugin = new BrazeTemplatePlugin(this);
    this.nativeAppBannerPlugin = new NativeAppBannerPlugin(this, constants.EREADER);
    const { match } = this.props;
    const { pageId } = match.params;
    this.userPreferredType = PathUtils.getQueryParameterbyName('userPreferredType');
    let drawerState = constants.DRAWER_STATE_CLOSED;
    let selectedPanel = '';
    if (this.userPreferredType && this.userPreferredType !== 'null' && this.userPreferredType !== constants.READ) {
      drawerState = constants.DRAWER_STATE_OPEN;
      selectedPanel = constants.DRAWER_PANEL_STUDY;
      switch (this.userPreferredType) {
        case constants.AUDIO:
          selectedPanel = constants.DRAWER_PANEL_AUDIO;
          break;
        case constants.SOCIAL:
          selectedPanel = constants.DRAWER_PANEL_SOCIAL;
          break;
        case constants.BOOKMARK:
          selectedPanel = constants.DRAWER_PANEL_BOOKMARK;
          break;
        case constants.ACTIVITY_TYPE.CONTENTS:
          selectedPanel = constants.DRAWER_PANEL_CONTENT;
          break;
        case constants.ACTIVITY_TYPE.SEARCH:
          selectedPanel = constants.DRAWER_PANEL_SERACH;
          break;
        case constants.ACTIVITY_TYPE.NOTES:
          selectedPanel = constants.DRAWER_PANEL_NOTEBOOK;
          break;
        default:
          break;
      }
    }
    this.moreMenuData = {};
    this.state = {
      drawerView: {
        drawerState,
        selectedPanel,
        onDrawerStateChange: this.onDrawerStateChange
      },
      openAITutor: {
        isOpen: false
      },
      isDrawerOpen: drawerState === constants.DRAWER_STATE_OPEN,
      isNotesCopy: false,
      showGlossary: false,
      showHotspot: false,
      isInitiated: false,
      addedDeviceId: '',
      removedDeviceId: '',
      open: false,
      openAlert: false,
      openToast: false,
      launchStudyStatus: false,
      isLimitExceeded: false,
      isDeviceRegistered: false,
      isDeviceIncluded: false,
      launchCode: false,
      showMobileOTPDialog: false,
      otpSent: false,
      textMessageSent: false,
      anchorEl: null,
      flags: {
        basePathChange: true,
        deviceManagementViolation: isIntergratedMLMLaunch ? false : true,
        enableSocial: true,
        scrubberFeature: true,
        showAllCaps: true,
        showColorMode: true,
        showCustomDeck: true,
        showFontOptions: true,
        showHighlights: true,
        showHotSpots: true,
        showKeyTerms: true,
        showLineSpacing: true,
        showPagelayout: true,
        studyGuide: true,
        enableAccountAbuseLevel1: false,
        enablePrintPageNavigation: false,
        showABSearch: false,
        enableCopy: false,
        enableAudioReadAlong: false,
        showABNavigation: false,
        showABAudio: false,
        enableUserLastLocation: false,
        showABNavIcons: false,
        showFlashcardViewInContent: false,
        enabledBronteFormattingOptions: false,
        enablePrintPageNavigationBronte: false,
        showMLMLaunchButton: false,
        showPDFChapterFilter: false,
        showABChannelsIconPosition: false,
        showABAITutor: false,
        showAITutor: false,
        topicTimeOut: constants.TOPIC_TIMEOUT,
        enableFreeResponse: true,
        enableParagraph: false,
        enableStreamingAPI: true,
        delayMessageTimerInSecs: constants.DELAY_TIMER_IN_SECS,
        enableFullStoryRecording: false,
        enableAIShowMore: false,
        enableChannelInSearch: false,
        enableAISleepMode: true,
        showAISleepModeAB: true,
        showAIChannelVideos: false,
        isAIReviewUser: false,
        enableTopicsForChannel: false,
        enableBrazeEReader: false,
        enableDigitalOrPrintPageNavigation: false,
        enableSummaryContinuation: false,
        enableFooterAudio: false,
        miniPlayerHeaderABTestFeature: 0,
        enableAIForGlossary: false,
        enableAIShortAnswer: {},
        showMiniPlayerIconAB: 'A',
        enableProblemSolveFeature: false,
        showSearchChannelVideoChanges: false,
        enableTOCPrintPage: false,
        enableExplainQuizOptions: 1,
        AIChatPlaceHolderText: '',
        showChannelsABHeaderTitle: true,
        showAIExpertDecks: false,
        enableTopicInQuizFlow: '',
        AIStudyToolAutoOpenLimit: 0,
        aitutorWelcomeScreenCohorts: constants.CRUSH_YOUR_FINALS,
        aiTutorAutoOpenCommands: null,
        miniPlayerPaywallTestFeature: 0,
        showSearchChannelVideoPlayer: true,
        enableNativeAppPromoBanners: false,
        enableSessionTimeoutWarn: false,
        showUserHistory: false,
        enableMathMLKeyboard: false,
        enableBundleChannelMiniPlayer: false,
        ChannelsVideoScore: 10,
        starterPromptLimit: 0,
        hideChannelsPopout: false,
        showTranslate: false,
        showRecommendedPrompts: false,
        showSourceReferencePage: false,
        enableQRcode: false,
        AIEnableMarkdown: false,
        isNewFlashcardUIEnable: true,
        AIEnableImageContext: false,
        isSearchStudyAutoSelected: false,
        AIBerlinTurnOffSummary: [],
        AIBerlinTurnOffPractice: [],
        enableNewMathConfig: false,
        miniplayerBadgeIconABCTest: 1,
        enableAISearch: false,
        AIBotIconNextToContentImage: {}
      },
      tableOfContents: {},
      userPreferences: {
        isTextCopyEnabled: false,
        isFirstTimeAITutorUser: null,
        aiTutorAutoOpenCount: 0,
        promptShownCount: 0,
        translationLanguage: null
      },
      LDUserFlagsFetched: false,
      isPageIdSetOnNavChange: false,
      brazeContentCards: [],
      brazeContentCardFetched: false,
      brazeInAppMsgFetched: false,
      brazeInitiated: false,
      VRApploaded: false,
      AITutorloaded: false,
      userPreferencesFetched: false,
      // eslint-disable-next-line react/no-unused-state
      nativeAppBannerConfig: {
        showDrawer: false,
        showBanner: false,
        showBannerClose: false
      },
      modalProps: {
        open: false,
        qrCode: '',
        isQRCodeMsg: ''
      }
    };
    this.regionData = null;
    this.glossaryData = null;
    this.pageId = pageId;
    this.chapterId = this.commonPlugin.getChapterId();
    this.printPageNumber = this.commonPlugin.getPrintPageNumber();
    this.sourceFrom = this.commonPlugin.getSourceFrom();
    this.IALaunchPageId = '';
    this.isFromCourse = true;
    this.isDeeplink = true;
    this.eventTriggered = false;
    this.platformId = PathUtils.getQueryParameterbyName('platformId');
    this.canadianProxy = PathUtils.getQueryParameterbyName('cp');
    this.isET1CourseId = PathUtils.getQueryParameterbyName('isET1CourseId');
    this.tpiValue = PathUtils.getQueryParameterbyName('isTpi');
    this.authHandler = new AuthHandler(this);
    this.moreMenuHandler = new MoreMenuHandler(this);
    this.backLinkClient = new BackLinkClient(this);
    this.appConfig = null;
    this.serverSideEnabled = false;
    this.isPLAInitiated = false;
    this.userLimitExceed = false;
    this.tocUpdated = false;
    this.isIntegratedFirstTimelaunch = true;
    this.combineStatusSuccess = {
      // combination of userProfile and LD call success
      userProfileAndLD: false,
      // combination of userProfile and Product call success
      userProfileAndProduct: false,
      // combination of LD fetch and product call success
      LDAndProduct: false,
      // combination of combination of LD fetch, product call and pageId fetch on navaigation
      LDAndProductAndPageId: false,
      // combination of LD fetch, product call, VRapp loaded and braze init and content card
      LDAndProductAndBrazeContentCard: false,
      // combination of LD fetch, VRapp loaded, braze init and braze in app msg
      LDAndProductAndBrazeInAppMsg: false,
      // combination of LD fetch, product, braze init
      LDAndProductAndBrazeInit: false,
      // userProfile fetched
      userProfileFetched: false
    };
    BrazeUtils.initializeBrazeCallBackListener((action, actionValue = null) => {
      switch (action) {
        case constants.BRAZE_CTA_ACTION.COPYURL:
          const product = this.commonPlugin.getProduct();
          const channelId = product.channel_id;
          if (channelId) {
            const channelUrl = `${env.STUDY_CHANNEL_URL}/${channelId}`;
            CommonUtils.copyText(channelUrl);
          }
          break;
        case constants.OPEN_EREADER_COMPONENT:
          if (actionValue) {
            const { component, utmParams } = JSON.parse(actionValue);
            this.commonPlugin.openEreaderComponentFromBraze(component, utmParams);
          }
          break;
        default:
          break;
      }
    });
    this.sessionWarningPlugin = new SessionWarningPlugin(this);
  }

  componentDidMount() {
    const {
      asset,
      match: {
        params: {
          id,
          productId,
          bundleId
        }
      },
      course,
      productToken,
      user,
      courseStatus,
      launchLog,
      ldClient
    } = this.props;
    CommonUtils.initializeGTM();
    if (isIntergratedMLMLaunch) {
      CommonUtils.initializeIntegratedMLMLaunch(this.commonPlugin.handleIntegratedMLMLaunchMessage);
    }
    course.fetch(id, productId, bundleId);
    Framework.getEventManager().on(constants.USER_PREFERENCES_SUCESS, (data)=> {
      let { userPreferences, userPreferencesFetched } = this.state;
      if(data) {
        const isFirstTimeAITutorUser = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'isFirstTimeAITutorUser', true) : true;
        const aiTutorAutoOpenCount = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'aiTutorAutoOpenCount', 0) : 0;
        const promptShownCount = data.userActivity ? CommonUtils.getObjectPropertyValue(data.userActivity, 'promptShownCount', 0) : 0;
        userPreferences = { ...userPreferences, isFirstTimeAITutorUser, aiTutorAutoOpenCount, promptShownCount };
      }
      // TODO: Need to refactor code to get and put on user preferencess
      if(data && data.eReaderSettings) {
        const { isTextCopyEnabled, translationLanguage = null } = data.eReaderSettings;
        userPreferences = { ...userPreferences, isTextCopyEnabled, translationLanguage };
      } else {
        const eventData = {
          eventType: 'PUT',
          payload: {
            eReaderSettings: {
              isTextCopyEnabled: true
            }
          }
        }
        userPreferences = { ...userPreferences, isTextCopyEnabled: true };
        Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData)
      }
      let updateState = { userPreferences };
      if (!userPreferencesFetched) {
        userPreferencesFetched = true;
        updateState = { ...updateState, userPreferencesFetched };
      }
      this.setState({ ...updateState });
    });
    Framework.getEventManager().on(constants.USER_PREFERENCES_FAIL, ()=> {
      this.setState({ userPreferencesFetched: true });
    });
    if (!isIntergratedMLMLaunch) {
      Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED);
    }
    Framework.getEventManager().on(constants.COURSE_DATA_FETCHED, () => {
      const productId = course.getProduct().id || '';
      const bookFormat = course.getProduct().format || '';
      if (course.getProduct().subscription && course.getProduct().subscription.allowAccess && productId) {
        asset.fetch(productId, id, bundleId, bookFormat, isIntergratedMLMLaunch);
        productToken.fetch(productId);
      }
      CommonUtils.pushGlobalParams();
      // Push telemetry Global params and user telemetry events only once on page load when marin response is ready
      !this.state.isInitiated && !courseStatus.isError && this.pushTelemetry(user);
    });

    Framework.getEventManager().on(constants.DEVICE_SWAP_SUCCESS, (data) => {
      if (data.isSwapAlreadyDone) {
        this.setState({ open: true });
      } else {
        this.setState({ open: false });
        this.userLimitExceed = false;
        document.getElementsByClassName('pageNavigation')[0].style.display = 'block';
        this.handleClose();
      }
    });

    /**
     * Pre Loading Vega reader Component
     */
    Framework.getEventManager().on(constants.AUTH_VERIFIED, () => {
      VegaReader.preload();
    });
    Framework.getEventManager().on(constants.USER_PROFILE_FETCHED, () => {
      this.printPageWatermark(user);
      if (user.isImpersonatedId) {
        Framework.getEventManager().publish(constants.LAUNCH_LOG_REQUESTED, () => {
          launchLog.fetch();
        });
        Framework.getEventManager().on(constants.LAUNCH_CODE, () => {
          this.setState({ launchCode: true });
        });

        Framework.getEventManager().on(constants.SEND_CODE_PHONE, (data) => {
          this.setState({
            showMobileOTPDialog: true,
            anchorEl: data.dataEvent
          });
        });

        Framework.getEventManager().on(constants.OTPREQUESTED, (data) => {
          if (data.type === 'text') {
            this.setState({ textMessageSent: false });
          } else {
            this.setState({ otpSent: false });
          }
        });
        Framework.getEventManager().on('DEVICE_OTP_GENERATE_SUCCESS', (data) => {
          if (data.primaryEmail) {
            this.setState({
              otpSent: true
            });
          } else if (data.phoneNumber) {
            this.setState({
              textMessageSent: true
            });
          }
        });
      }
    });

    Framework.getEventManager().on(constants.BRAZE_IN_APP_MESSAGE_CARD_FETCHED, ()=> {
      this.setState({ brazeInAppMsgFetched: true });
    });
  }

  componentWillUnmount() {
    if (isIntergratedMLMLaunch) {
      CommonUtils.closeConnectionIntegratedMLMLaunch(this.commonPlugin.handleIntegratedMLMLaunchMessage);
    }
  }

  getChildContext() {
    const {
      course
    } = this.props;
    const {
      format,
      model
    } = course.getProduct();
    const {
      showColorMode,
      showLineSpacing,
      showHotSpots,
      showAllCaps,
      showFontOptions,
      showHighlights,
      showPagelayout,
      showCustomDeck,
      showKeyTerms,
      showFlashcardViewInContent,
      enabledBronteFormattingOptions,
      showAITutor,
      showAIExpertDecks,
      isNewFlashcardUIEnable
    } = this.state.flags;
    const isPdf = format === constants.CONTENT_TYPE_PDF;
    const pdfModel = model === constants.ETEXT_PDF;
    const isPXE = format === constants.CONTENT_TYPE_PXE;
    const isBronte = format === constants.CONTENT_TYPE_EPUB;
    const isCITE = format === constants.CONTENT_TYPE_CITE;
    const isBVBronte = ProductUtils.isBVBronteBook(format);
    const isshowFlashcardViewInContentEnabled = (isPXE || isBronte || isCITE || isBVBronte) ? showFlashcardViewInContent : false;

    return {
      preferenceContext: {
        showColorMode: format === constants.CONTENT_TYPE_PXE ? showColorMode : !isPdf,
        showPagelayout: this.serverSideEnabled && showPagelayout,
        showPageProgression: false,
        showAnnotationSwitch: showHighlights,
        showFontOptions: isBronte ? enabledBronteFormattingOptions && showFontOptions : !isPdf && showFontOptions,
        showTextTransform: isBronte ? enabledBronteFormattingOptions && showAllCaps : !isPdf && showAllCaps,
        showLineSpacing: isBronte ? enabledBronteFormattingOptions && showLineSpacing : !isPdf && showLineSpacing,
        showHotSpots: isPdf && showHotSpots,
        showFontSlider: true
      },
      studyContext: {
        glossaryLanguage: pdfModel ? CommonUtils.getGlossaryLanguage(course.getProduct().getPdfLocale()) : 'en_us',
        showCustomDecks: showCustomDeck,
        showExpertDecks: true,
        showKeyTerms,
        studyLDFlagsContext: {
          showFlashcardViewInContent: isshowFlashcardViewInContentEnabled,
          showAITutor: showAITutor,
          showAIExpertDecks,
          isNewFlashcardUIEnable
        }
      }
    };
  }

  printPageWatermark = (user) => {
    const email = user.email.toUpperCase();
    const truncateData = email.length > 17 ? '...' : '';
    const dynamicEmail = `FOR THE PRIVATE USE OF ${email.substring(0, 17)}${truncateData}`;
    const str = encodeURIComponent(ReactDOMServer.renderToStaticMarkup((printIcon(dynamicEmail))));
    const base64 = `data:image/svg+xml,${str}`;
    const printImage = base64;
    const getHeadTag = window.document.getElementsByTagName('head');
    $(getHeadTag).append(`<style>@media print{{@page { size:A4; margin-bottom: 0;}#printImage {display:block; background-image: url(${printImage});background-position: center; background-repeat: no-repeat;background-size: 70%; font-size: 65pt;position: fixed;width: 100%;height: 100%;opacity: 0.6;left: 0%;top:0%;-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}} </style>`);
  }

  /**
  * Handle aysnc api call success
  *
  */
  onCombineStatusSuccess = () => {
    const { user, course } = this.props;
    const { flags, LDUserFlagsFetched, isPageIdSetOnNavChange, VRApploaded, userPreferencesFetched } = this.state;
    const isUserFetched = user && user.gpsSubscriptions;

    // combination of userProfile and LD call success
    if (!this.combineStatusSuccess.userProfileAndLD && isUserFetched && this.state.LDUserFlagsFetched) {
      this.commonPlugin.IntialiseAndIdentifyBraze(() => {
        this.setState({ brazeContentCardFetched: true });
      }, flags.enableBrazeEReader);
      this.combineStatusSuccess.userProfileAndLD = true;
    }

    if (!this.combineStatusSuccess.userProfileAndProduct && user && user.gpsSubscriptions && course.id && userPreferencesFetched) {
      this.combineStatusSuccess.userProfileAndProduct = true;
      this.commonPlugin.InitializePendo();
      this.commonPlugin.setGpsSubscriptionOfProduct();
      if (user && !isIntergratedMLMLaunch) {
        this.updateLaunchDarklyUser();
      }
    }

    if (!this.combineStatusSuccess.LDAndProduct && LDUserFlagsFetched && course.id) {
      const product = course.getProduct();
      this.combineStatusSuccess.LDAndProduct = true;
      const isShowAITutorEnabled = CommonUtils.isAITutorEnabled(flags.showAITutor, flags.isAIReviewUser, product.isAITutorSupported);
      if (flags.enableFullStoryRecording && isShowAITutorEnabled) {
        const script = document.createElement('script');
        script.innerHTML = CommonUtils.getFullStoryScript();
        document.head.appendChild(script);
      }
      this.commonPlugin.initiateDeviceManagementOnEreader();
    }

    if (!this.combineStatusSuccess.LDAndProductAndPageId && (LDUserFlagsFetched || isIntergratedMLMLaunch) && course.id && isPageIdSetOnNavChange) {
      this.combineStatusSuccess.LDAndProductAndPageId = true;
      // GA push for user Access eTextbook from which source
      this.commonPlugin.dispatchEreaderUserPathEvent();
      this.nativeAppBannerPlugin.initializeNativeAppBanners();
    }

    if (!this.combineStatusSuccess.LDAndProductAndBrazeContentCard && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && course.id && this.state.brazeContentCardFetched) {
      this.combineStatusSuccess.LDAndProductAndBrazeContentCard = true;
      this.brazeTemplatePlugin.setEReaderContext();
      /**
     * Added 2 seconds delay to show content card after vegareader page load
     */
      setTimeout(() => {
      // Show braze content card
        try {
          this.brazePlugin.showContentCards();
        } catch (error) {
          console.log('braze conent card error', error);
        }
      }, 2000);
    }

    const isAIEnabled = this.commonPlugin.isAIEnabled();
    if (!this.combineStatusSuccess.LDAndProductAndBrazeInit && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && flags.enableBrazeEReader && course.id
    && (!isAIEnabled || (isAIEnabled && this.state.AITutorloaded))) {
      this.combineStatusSuccess.LDAndProductAndBrazeInit = true;
      this.brazeTemplatePlugin.setEReaderContext();
      const { BRAZE_TRIGGER_EVENT, BRAZE_CUSTOM_EVENTS: { PPLUS_WEB_EREADER_LOADED } } = constants;

      BrazeUtils.handleEvent(BRAZE_TRIGGER_EVENT, null, null, {
        name: PPLUS_WEB_EREADER_LOADED,
        properties: {}
      });
    }

    if (!this.combineStatusSuccess.LDAndProductAndBrazeInAppMsg && this.state.brazeInitiated && VRApploaded && LDUserFlagsFetched && this.state.brazeInAppMsgFetched) {
      this.combineStatusSuccess.LDAndProductAndBrazeInAppMsg = true;
      this.brazeTemplatePlugin.setEReaderContext();
      try {
        // Show braze in app message
        this.brazePlugin.showInAppMesssage();
      } catch (err) {
        console.log('braze error', err);
      }
    }
  }

  updateLaunchDarklyUser = () => {
    const { user, ldClient } = this.props;
    if (user.id && user.ldUserHash) {
      const customAttributes = {
        hasPPlusSubscription: this.commonPlugin.isPPlusProduct()
      };

      try {
        ldClient.identify({
          kind: 'user',
          key: user.id,
          ...customAttributes
        }, user.ldUserHash, (err, res) => {
          if (res) {
            const aiTutorAutoOpenCommands = this.commonPlugin.getAiTutorAutoOpenCommands(ldClient.variation('aiTutorAutoOpenCommands', {}));
            this.setState({
              flags: {
                basePathChange: res.basePathChange,
                deviceManagementViolation: res.deviceManagementViolation,
                enableSocial: res.enableSocial,
                scrubberFeature: res['scrubber-feature'],
                showAllCaps: res.showAllCaps,
                showColorMode: res.showColorMode,
                showCustomDeck: res.showCustomDeck,
                showFontOptions: res.showFontOptions,
                showHighlights: res.showHighlights,
                showHotSpots: res.showHotSpots,
                showKeyTerms: res.showKeyTerms,
                showLineSpacing: res.showLineSpacing,
                showPagelayout: res.showPagelayout,
                studyGuide: res['study-guide'],
                enableAccountAbuseLevel1: res['enable-account-abuse-level1'],
                enablePrintPageNavigation: res.enablePrintPageNavigation,
                showABSearch: ldClient.variation('showABSearch', 'false'),
                enableCopy: res.enableCopy,
                enableAudioReadAlong: res.enableAudioReadAlong,
                showABNavigation: ldClient.variation('showABNavigation', 'false'),
                showABAudio: ldClient.variation('showABAudio', 'false'),
                enableUserLastLocation: res.enableUserLastLocation,
                showABNavIcons: ldClient.variation('showABNavIcons', 'false'),
                showFlashcardViewInContent: res.showFlashcardViewInContent,
                enabledBronteFormattingOptions: ldClient.variation('enabledBronteFormattingOptions', 'false'),
                enablePrintPageNavigationBronte: ldClient.variation('enablePrintPageNavigationBronte', 'false'),
                showMLMLaunchButton: ldClient.variation('showMLMLaunchButton', 'false'),
                showPDFChapterFilter: ldClient.variation('showPDFChapterFilter', 'false'),
                showABChannelsIconPosition: ldClient.variation('showABChannelsIconPosition', 'false'),
                showABAITutor: ldClient.variation('showABAITutor', false),
                showAITutor: ldClient.variation('showAITutor', false),
                topicTimeOut: ldClient.variation('topicApiTimeOut', constants.TOPIC_TIMEOUT),
                enableParagraph: ldClient.variation('enableParagraph', 'false'),
                enableFreeResponse: ldClient.variation('enableFreeResponse', 'true'),
                enableStreamingAPI: ldClient.variation('enableStreamingAPI', 'true'),
                delayMessageTimerInSecs: ldClient.variation('delayMessageTimerInSecs', constants.DELAY_TIMER_IN_SECS),
                enableFullStoryRecording: ldClient.variation('enableFullStoryRecording', false),
                enableAIShowMore: ldClient.variation('enableAIShowMore', false),
                enableChannelInSearch: ldClient.variation('enableChannelInSearch', false),
                enableAISleepMode: ldClient.variation('enableAISleepMode', true),
                showAISleepModeAB: ldClient.variation('showAISleepModeAB', true),
                showAIChannelVideos: ldClient.variation('showAIChannelVideos', false),
                isAIReviewUser: ldClient.variation('isAIReviewUser', false),
                enableTopicsForChannel: ldClient.variation('enableTopicsForChannel', false),
                enableBrazeEReader: ldClient.variation('enable_Braze_eReader', false),
                enableDigitalOrPrintPageNavigation: ldClient.variation('enableDigitalOrPrintPageNavigation', false),
                enableSummaryContinuation: ldClient.variation('enableSummaryContinuation',false),
                enableFooterAudio: ldClient.variation('enableFooterAudio', false),
                miniPlayerHeaderABTestFeature: ldClient.variation('miniPlayerHeaderABTestFeature', 0),
                enableAIForGlossary: ldClient.variation('enableAIForGlossary', false),
                enableAIShortAnswer: ldClient.variation('enableAIShortAnswer', {}),
                showMiniPlayerIconAB: ldClient.variation('showMiniPlayerIconAB', 'A'),
                enableProblemSolveFeature: ldClient.variation('enableProblemSolveFeature', false),
                showSearchChannelVideoChanges: ldClient.variation('showSearchChannelVideoChanges', false),
                enableTOCPrintPage: ldClient.variation('enableTOCPrintPage', false),
                enableExplainQuizOptions: ldClient.variation('enableExplainQuizOptions', 1),
                AIChatPlaceHolderText: ldClient.variation('AIChatPlaceHolderText', ''),
                showChannelsABHeaderTitle: ldClient.variation('showChannelsABHeaderTitle', true),
                showAIExpertDecks: ldClient.variation('showAIExpertDecks', false),
                enableTopicInQuizFlow: ldClient.variation('enableTopicInQuizFlowV1', ''),
                AIStudyToolAutoOpenLimit: ldClient.variation('AIStudyToolAutoOpenLimit', 0),
                aitutorWelcomeScreenCohorts: ldClient.variation('aitutorWelcomeScreenCohorts', constants.CRUSH_YOUR_FINALS),
                aiTutorAutoOpenCommands,
                miniPlayerPaywallTestFeature: ldClient.variation('miniPlayerPaywallTestFeature', 0),
                showSearchChannelVideoPlayer: ldClient.variation('showSearchChannelVideoPlayer', true),
                enableNativeAppPromoBanners: ldClient.variation('enableNativeAppPromoBanners', false),
                enableSessionTimeoutWarn: ldClient.variation('enableSessionTimeoutWarn', false),
                showUserHistory: ldClient.variation('showUserHistory', false),
                enableMathMLKeyboard: ldClient.variation('enableMathMLKeyboard', false),
                enableBundleChannelMiniPlayer: ldClient.variation('enableBundleChannelMiniPlayer', false),
                ChannelsVideoScore: ldClient.variation('ChannelsVideoScore', 10),
                starterPromptLimit: ldClient.variation('starterPromptLimit', 0),
                hideChannelsPopout: ldClient.variation('hideChannelsPopout', false),
                showTranslate: ldClient.variation('showTranslate', false),
                showRecommendedPrompts: ldClient.variation('showRecommendedPrompts', false),
                showSourceReferencePage: ldClient.variation('showSourceReferencePage', false),
                enableQRcode: ldClient.variation('enableQRcode', false),
                AIEnableMarkdown: ldClient.variation('AIEnableMarkdown', false),
                isNewFlashcardUIEnable: ldClient.variation('isNewFlashcardUIEnable', true),
                AIEnableImageContext: ldClient.variation('AIEnableImageContext', false),
                isSearchStudyAutoSelected: ldClient.variation('isSearchStudyAutoSelected', false),
                AIBerlinTurnOffSummary: ldClient.variation('AI_Berlin_TurnOff_Summary', []),
                AIBerlinTurnOffPractice: ldClient.variation('AI_Berlin_TurnOff_Practice', []),
                enableNewMathConfig: ldClient.variation('enableNewMathConfig', false),
                miniplayerBadgeIconABCTest: ldClient.variation('miniplayerBadgeIconABCTest', 1),
                enableAISearch: ldClient.variation('enableAISearch', false),
                AIBotIconNextToContentImage: ldClient.variation('AIBotIconNextToContentImage', {})
              },
              LDUserFlagsFetched: true
            });
          } else {
            this.setState({
              LDUserFlagsFetched: true
            });
            console.log('ld error', err);
          }
        });
      } catch (err) {
        this.setState({
          LDUserFlagsFetched: true
        });
        console.log('ld error', err);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { match, course, user } = this.props;
    const { id, productId, bundleId } = prevProps.match.params;
    this.commonPlugin.updateLangAttribute(); // Update lang atrribute after proper mobx language props update
    if ((id !== match.params.id) || (productId !== match.params.productId)
      || (bundleId !== match.params.bundleId)) {
      course.fetch(match.params.id, match.params.productId, match.params.bundleId);
    }
    if (user.isImpersonatedId) {
      Framework.getEventManager().publish(constants.LAUNCH_LOG_REQUESTED, () => {
        launchLog.fetch();
      });
    }
  }

  pushTelemetry = (user) => {
    const { course } = this.props;
    const loggedUser = user.id;

    CommonUtils.logUserTelemetryEvent(course, loggedUser);
    this.setState({ isInitiated: true });
  }

  /**
   * Method to Check device already present
   */
  isDeviceIdInList = (deviceId) => {
    const { device } = this.props;
    const matchDevice = device.devices.slice().filter(item => item.deviceId === deviceId);

    return matchDevice.length > 0;
  }

  /**
   * Get config details
   *
   * TODO: Need to remove Product format specific condition once Marin API adds
   * audio flag for all product models
   *
   * @param {string} locale - locale
   * @param {string} bundleId - bundle id
   * @param {string} redirectUrl - redirect Url
   */
  getConfig = (locale, bundleId, redirectUrl, format) => ({
    ...this.props.course.getConfig(locale, bundleId, redirectUrl, this.canadianProxy,
      this.state.flags, this.state.userPreferences, isIntergratedMLMLaunch, this.state.LDUserFlagsFetched),
    ...(this.userLimitExceed && { showDrawer: false })
  });

  /**
  * Fucntion to pass isFirstTimeAITutorUser to the marin API PUT call
  */
  updateAITutorUserSettings = (data) => {
    CommonUtils.updateUserPreference({ ...data });
  }

  /**
   * Get default locale for PDF
   *
   */
  getDefaultLocale = () => {
    const { course } = this.props;
    const { configuration, getPdfLocale } = course.getProduct();
    const pdfBookLocale = getPdfLocale();
    let locale = Framework.getStoreRegistry().getStore('language').currentLanguage;

    if (configuration.availableBookLanguages
      && configuration.availableBookLanguages.length > 0) {
      if (pdfBookLocale !== constants.DEFAULT_LOCALE && pdfBookLocale !== constants.DEFAULT_LOCALE_US) {
        locale = pdfBookLocale;
      }
    }

    return locale;
  }

  /**
    * Fetch the pageno for SAeT PDF
    *
    * @param {string} pageId
    */
    handleNavigationChange = (pageId, status, navData) => {
    CommonUtils.checkSession();

    const previousPageId = this.pageId;
    const {
      course,
      history,
      match
    } = this.props;
    if (pageId) {
      const { format } = course.getProduct();
      const customAsset = [constants.ASSET, constants.STANDARDS, constants.ASSESSMENT, constants.CUSTOM];
      if (pageId && !pageId.type && !customAsset.includes(pageId.type)) {
        CommonUtils.sendCommonPLAEvents(course, pageId, this.isFromCourse, previousPageId, constants.UNLOAD, navData);
      }
      this.isPLAInitiated = true;
      this.pageId = pageId;
      if (!this.state.isPageIdSetOnNavChange) {
        this.setState({ isPageIdSetOnNavChange: true });
      }
      if (format === constants.CONTENT_TYPE_PDF || this.serverSideEnabled) {
        this.setState({ showGlossary: false });

        if (pageId && pageId.type && customAsset.includes(pageId.type)) {
          if (pageId.type === constants.CUSTOM) {
            this.setState({ showHotspot: false });
            this.regionData = null;
          } else {
            this.regionData = pageId;
            this.setState({ showHotspot: true });
          }
          this.pageId = previousPageId;
        } else {
          if (pageId !== previousPageId) {
            HotspotUtils.disableNavigation();
          }
          this.setState({ showHotspot: false });
          this.regionData = null;
          PathUtils.redirectToPage(match, history, pageId);
        }
      } else {
        PathUtils.redirectToPage(match, history, pageId);
      }
      if (pageId && !pageId.type && !customAsset.includes(pageId.type)) {
        if (this.isIntegratedFirstTimelaunch && isIntergratedMLMLaunch) {
          // TODO: Need to add specific target origin instead of *
          window.parent.postMessage({
            type: constants.PPLUS_LAUNCH_SUCCEEDED
          }, '*');
          this.isIntegratedFirstTimelaunch = false;
        }
        CommonUtils.sendCommonPLAEvents(course, pageId, this.isFromCourse, previousPageId, constants.LOAD, navData);
        if (!this.state.launchStudyStatus && this.state.drawerView.drawerState === constants.DRAWER_STATE_OPEN
          && this.state.drawerView.selectedPanel === constants.DRAWER_PANEL_STUDY) {
          this.setState({ launchStudyStatus: true });
          CommonUtils.launchStudyPush();
        }
      }
    }
  };

  handleBackNavClick = () => {
    const {
      match: {
        params: {
          pageId
        }
      },
      course,
      user
    } = this.props;

    const businessModelCode = course.getProduct().getBusinessModelCode();
    const tpiFlag = (CommonUtils.isGhostAccount() || user.isTpi);
    const previousPageId = pageId;
    CommonUtils.sendCommonPLAEvents(course, '', this.isFromCourse, previousPageId, constants.UNLOAD);
    setTimeout(() => {
      window.location = CommonUtils.redirectToUrl(businessModelCode, tpiFlag, this.sourceFrom);
    }, 1000);
  };

  handleNotesCopy = () => {
    const { courseItems, match } = this.props;
    const productId = match.params.productId;

    courseItems.fetch(productId);
    this.setState({ isNotesCopy: true });
  };

  onDrawerStateChange = (drawerState) => {
    this.setState({ isDrawerOpen: drawerState === constants.DRAWER_STATE_OPEN });
    CommonUtils.checkSession();
  }

  handlePopUpToggle = () => {
    this.setState(prevState => ({ isNotesCopy: !prevState.isNotesCopy }));
  };

  vegaReaderLoading = () => {
    const {
      courseStatus, assetStatus
    } = this.props;

    return courseStatus.isPending || assetStatus.isPending;
  }

  showNotesCopy = () => (
    this.state.isNotesCopy && this.props.courseItems.items && this.props.courseItems.items.length
  )

  /**
   * Fetch the hotspot data
   *
   * @param {string} currentPageNo
   * @param {string} nextPageNo
   */
  fetchHotspotData = (currentPageNo, nextPageNo) => new Promise((resolve) => {
    const {
      match,
      pdfHotspot,
      asset,
      course
    } = this.props;
    const { model } = course.getProduct();
    const { bundleId, productId } = match.params;
    const pagePlaylist = (bundleId) ? asset.children : asset.slates;
    const spdfPlaylist = asset.getPagePlaylist(
      bundleId,
      course.getProduct(),
      this.serverSideEnabled
    );

    HotspotUtils.enableNavigation();
    if (model === constants.ETEXT_PDF) {
      this.pageData = this.serverSideEnabled
        ? spdfPlaylist.find((item => item.id === currentPageNo))
        : pagePlaylist.find((item => item.playOrder === currentPageNo));
      this.pageNumber = this.pageData && this.serverSideEnabled
        ? this.pageData.pagenumber
        : this.pageData && this.pageData.pageno;

      /**
       * User preference change to double page handle
       */
      if (nextPageNo) {
        this.nextPageData = spdfPlaylist.find((item => item.id === nextPageNo));
        const nextPage = this.nextPageData && this.nextPageData.pagenumber;

        this.pageNumber = `${this.pageNumber},${nextPage}`;
      }
      pdfHotspot.fetch(productId || course.getProduct().bookID, this.pageNumber);
      const hotspotSubscription = Framework.getEventManager().on(constants.PDF_HOTSPOT_FETCHED, () => {
        resolve(
          pdfHotspot.constructHotspot(pdfHotspot.hotspot,
            this.platformId,
            course.role || course.getProduct().role)
        );
        hotspotSubscription();
      });
    }
  });

  /**
   * Fetch the region data while clicking hotspot
   *
   * @param {string} regionId
   */
  fetchClickedRegionData = (regionId) => {
    const hotspotData = this.props.pdfHotspot.getHotspotData(regionId, this.props.glossaryHotspot);

    this.regionData = hotspotData.regionData;
    this.glossaryData = hotspotData.glossaryData;
    this.setState({
      showHotspot: (hotspotData.hotspotType === 'hotspot'),
      showGlossary: (hotspotData.hotspotType === 'glossary')
    });
  }

  /**
   * Fetch the glossary data
   *
   */
  fetchGlossaryData = () => {
    const {
      course,
      glossaryHotspot
    } = this.props;

    glossaryHotspot.fetch((this.pageNumber).replace(',', '|'),
      course.getProduct().indexId,
      course.getProduct().getPdfLocale());
  }

  /**
   * Closes PDF glossary popup on click away
   *
   */
  handleGlossaryClose = () => {
    this.setState({ showGlossary: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAlertClose = () => {
    this.setState({ openAlert: false });
  };

  handleToastClose = () => {
    this.setState({ openToast: false });
  };

  handleMenuClose = () => {
    this.setState({launchCode: false})
  };

  handleMobileOtpDialogClose = (e) => {
    const target = e.target;
    if (target.classList.contains('paperOtpPopover')) {
      this.setState({ showMobileOTPDialog: true, anchorEl: e.target })
    } else {
      this.setState({ showMobileOTPDialog: false, anchorEl: null })
    }
  }
  handleConnect = (devId) => {
    if (!this.state.addedDeviceId) { return false; }
    this.setState({ removedDeviceId: devId, openAlert: true });
  };

  handleAlertAgree = () => {
    const { addedDeviceId, removedDeviceId } = this.state;
    // swap the device
    const payload = { addedDeviceId, removedDeviceId };
    if (!isIntergratedMLMLaunch) {
    Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
      type: 'swap',
      payload
    });
    }
    CommonUtils.deviceDisconnectEvent(addedDeviceId);
    this.handleAlertClose();
  };

  handleDeviceClose = () => {
    this.setState({
      isLimitExceeded: false
    })
  };

  /**
   * appLoaded callback from Vega reader component
   *
   */
  appLoaded = (navData) => {
    const {
      match: {
        params: {
          bundleId,
          pageId
        }
      },
      course
    } = this.props;
    this.setState({ VRApploaded: true });

    // Initializing GTM
    CommonUtils.initializeGTM();
    // Initial PLA Load
    if (!this.isPLAInitiated) {
      CommonUtils.sendCommonPLAEvents(course, pageId, this.isFromCourse, '', constants.LOAD, navData);
      if (!this.state.launchStudyStatus && this.state.drawerView.drawerState === constants.DRAWER_STATE_OPEN
        && this.state.drawerView.selectedPanel === constants.DRAWER_PANEL_STUDY) {
        this.setState({ launchStudyStatus: true });
        CommonUtils.launchStudyPush();
      }
    }
  }

  /**
   * Callback function to opena and close the modal
   */
  handleQrCodeClick = (locationInApp, event = null) => {
    if (event && event.currentTarget) {
      this.qrCodeButtonRef.current = event.currentTarget;
    }

    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        ...CommonUtils.getModalProps(),
        open: true
      }
    }));
    CommonUtils.fetchUIDQRcode(locationInApp);
  }

  isReadyToRender() {
    const {
      asset,
      assetStatus,
      course,
      history,
      match,
      productToken,
      user
    } = this.props;
    const {
      bundleId, pageId = '', productId, id
    } = match.params;
    const {
      isAudioBook, audioBook, model
    } = course.getProduct();
    const isPdf = model === constants.ETEXT_PDF;
    const role = course.role || course.getProduct().role;
    this.onCombineStatusSuccess();
    if (course.getProduct().model === constants.ETEXT_PDF && this.isDeeplink) {
      const page = asset && HotspotUtils.getPlayOrder(
        asset,
        bundleId,
        pageId,
        this.serverSideEnabled,
        course.getProduct()
      );

      this.pageId = page.pageId || '';
      if (page && page.deeplink) {
        this.isDeeplink = false;
      }
    }
    if (asset.id && !assetStatus.isPending && !assetStatus.isError && !this.tocUpdated) {
      this.setState ({
        tableOfContents : isPdf ? asset.getAssetTocPdf(bundleId, role, this.serverSideEnabled, isAudioBook(audioBook)) : asset
      });
      this.tocUpdated = true;
    }
    const isReadyToRender = productToken.verified && course.id && asset.id && (!bundleId || pageId || this.IALaunchPageId);
    Framework.getEventManager().on(constants.PRODUCT_DATA_FETCHED, () => {
      if (!user.isImpersonatedId && !isIntergratedMLMLaunch) {
        // Avoid impersonated users from device count
        Framework.getEventManager().on(constants.DEVICE_LIST_REQUEST_SUCCESS, (data) => {
          const businessModelCode = course.getProduct().getBusinessModelCode();
          if (CommonUtils.isPplusOrIAbookOrHEI(businessModelCode) && !this.state.isDeviceIncluded) {
            const deviceId = window.piSession && window.piSession.getDeviceId();
            this.setState({ addedDeviceId: deviceId });
            // check this device id is already in list
            if (deviceId && !this.isDeviceIdInList(deviceId)) {
              // check if limit reached
              if (data.limitReached && constants.SHOW_DEVICE_MANAGEMENT_POPUP) {
                // call open popup
                this.setState({ open: true });
                this.userLimitExceed = true;
                setTimeout(() => {
                  const elements = document.getElementsByClassName('pageNavigation');
                  elements.length && (elements[0].style.display = 'none');
                }, 3000);
              } else {
                //  Register the device
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'register',
                  payload: deviceId
                });
              }
            }
            if (this.state.flags.deviceManagementViolation) {
              Framework.getEventManager().on(constants.DEVICE_REGISTER_SUCCESS, (data) => {
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'violation',
                  data: { deviceId }
                });
              });
              if (deviceId && this.isDeviceIdInList(deviceId)) {
                Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                  type: 'violation',
                  data: { deviceId }
                });
              }
              Framework.getEventManager().on(constants.DEVICE_VIOLATION_SUCCESS, (data) => {
                if (data.isWebViolation) {
                  Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                    type: 'generateOTP',
                    data: {
                      clientId: env.CLIENT_ID,
                      deviceId
                    }
                  });
                }
                this.setState({
                  isLimitExceeded: data.isWebViolation
                });
                CommonUtils.deviceManagementReSentOTPEvents(constants.SEND_OTP);
              });
            }
            Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
              type: 'list',
              payload: { includeDeviceDetails: true }
            });
            this.setState({
              isDeviceIncluded: true
            })
          }
        });
      }
    });

    if (isReadyToRender) {
      if (history.action === constants.POP) {
        const product = course.getProduct();
        const title = (product.platform === constants.BUNDLED_PLATFORM) ? product.title : course.title;
        const userId = user.id;
        const productIdValue = productId || course.getProduct().id;
        const courseId = id || course.id;
        const courseName = (product.platform === constants.BUNDLED_PLATFORM) ? course.title : course.code;

        if (product.model !== constants.CUSTOM_COLLECTIONS || (product.model === constants.CUSTOM_COLLECTIONS && course.isStudent)) {
          product.doSubscriptionCheck(
            title,
            userId,
            productIdValue,
            courseId,
            courseName
          );
        }
        window.localStorage.setItem(`bookId_${product.id}`, (product.bookID || product.id));
      }
    }

    return isReadyToRender;
  }

  /**
   * Callback function to close the modal
   */
   handleQRCodeClose = () => {
    if (CommonUtils.controller) {
      CommonUtils.controller.abort();
    }

     if (this.qrCodeButtonRef && this.qrCodeButtonRef.current) {
       setTimeout(() => {
         this.qrCodeButtonRef.current.focus();
       }, 200);
     }

    this.setState(state => ({
      modalProps: {
        ...state.modalProps,
        open: !state.modalProps.open
      }
    }));
  }
  
  render() {
    const {
      asset, assetStatus, course, courseItems, courseStatus,
      language, match, user, device
    } = this.props;
    const { bundleId, pageId } = match.params;
    let pdfCallbacks;
    const {
      format, id, indexId, version, model, backLinkUrl
    } = course.getProduct();
    const {
      tableOfContents
    } = this.state;
     const { 
       smsUserId, initials, username, phoneNumber, mobileAccess
     } = Framework.getStoreRegistry().getStore('user');
    const { itemsCode } = Framework.getStoreRegistry().getStore('launchLog');
    const isPdf = model === constants.ETEXT_PDF;
    const redirectUrl = PathUtils.getQueryParameterbyName('redirectURL');
    const vegaReaderErrorCode = (courseStatus.error && constants.ERROR_CODE_MARIN)
      || (assetStatus.error && constants.ERROR_CODE_PRISM);
    const productId = match.params.productId || id;
    const courseId = match.params.id || course.id;
    const contentType = format === constants.CONTENT_TYPE_EPUB ? constants.CONTENT_TYPE_EPUB3 : format;
    const tocContent = tableOfContents && tableOfContents.children && tableOfContents.children.length > 0 && CommonUtils.tocList(tableOfContents.children, pageId);
    const brazeAINotificationCard = this.state.brazeContentCards.length > 0 && this.state.brazeContentCards[0];

    document.title = tocContent ? tocContent.title || tocContent.label : CommonUtils.getAppTitle();

    this.serverSideEnabled = course.getProduct().getServerSideStatus();

    if (format === constants.CONTENT_TYPE_PDF || this.serverSideEnabled) {
      pdfCallbacks = {
        hotspotObj: {
          fetchHotspotData: this.fetchHotspotData,
          bookFeatures: course.getProduct().getBookFeatures(),
          fetchClickedRegionData: this.fetchClickedRegionData,
          renderGlossaryData: this.fetchGlossaryData
        }
      };
    }

    this.moreMenuData = {
      handleChange: this.moreMenuHandler.handleChange,
      menuItem: this.moreMenuHandler.getMenuItems(this.canadianProxy, match.params.id, this.appConfig, this.isFromCourse, bundleId, this.state.otpSent, this.state.textMessageSent),
      userInitial: initials
    };

    const isET1CourseId = this.isET1CourseId ? `&isET1CourseId=${this.isET1CourseId}` : '';

    if (pageId === undefined && bundleId && asset.id) {
      let firstPageId = asset && asset.children.length && asset.children[0].id;

      if (isPdf) {
        firstPageId = asset && asset.children.length && asset.children[0].playOrder;
        this.pageId = firstPageId;
      }

      if (isIntergratedMLMLaunch && bundleId && format !== constants.CONTENT_TYPE_PXE) {
        const productDetails = CommonUtils.deCodeBase64(bundleId);
        if (productDetails && productDetails.startPage) {
          this.IALaunchPageId = productDetails.startPage;
          if (isPdf) {
            this.pageId = productDetails.startPage; // for pdf IA launch
          }
        }
      } else {
        const canadianProxy = this.canadianProxy ? `&cp=${this.canadianProxy}` : '';
        const type = this.userPreferredType ? `&userPreferredType=${this.userPreferredType}` : '';
        const isLMS = CommonUtils.isLMSQueryParamAvailable() ? `&${constants.IS_LMS}=Y` : '';

        Framework.redirectTo(`/courses/${courseId}/products/${productId}/bundles/${bundleId}/pages/${firstPageId}?platformId=${this.platformId}${canadianProxy}${isET1CourseId}${type}${isLMS}`);
      }
    }
    // Get the pdf locale
    const pdfLocale = isPdf ? this.getDefaultLocale() : '';
    this.appConfig = this.getConfig(pdfLocale, bundleId, redirectUrl, format);

    if (courseStatus.isError && !this.eventTriggered) {
      const data = {
        api: constants.MARIN,
        errorDetails: courseStatus
      };

      this.eventTriggered = true;
      course.getProduct().sendErrorEvents(data, productId, courseId);
    }
    if (assetStatus.isError && !this.eventTriggered) {
      const assetData = {
        api: constants.ASSET,
        errorDetails: assetStatus
      };

      this.eventTriggered = true;
      course.getProduct().sendErrorEvents(assetData, productId, courseId);
    }

    return (
      <Fragment>
        {!isIntergratedMLMLaunch ? this.commonPlugin.renderPrintMessage() : null}
        <LoadingHandler
          loading={this.vegaReaderLoading()}
          isError={courseStatus.isError || assetStatus.isError}
          error={courseStatus.error || assetStatus.error}
          content={this.isReadyToRender() && (
            <Fragment>
              <LanguageContext.Consumer>
                {(context) => {
                  if (isPdf) {
                    context.onLocaleChange(pdfLocale);
                  }

                  return (
                    <Suspense fallback={<CircularProgress className="circleStyle" />}>
                      {this.nativeAppBannerPlugin.renderMobileBanners()}
                      {this.sessionWarningPlugin.renderSessionWarning()}
                      <VegaReader
                        chapterId={this.chapterId}
                        courseId={match.params.id}
                        bookId={course.getProduct().id}
                        secondaryBookId={course.getProduct().bookID}
                        businessModelCode={course.getProduct().getBusinessModelCode()}
                        tocData={tableOfContents}
                        pagePlaylist={asset.getPagePlaylist(
                          bundleId,
                          course.getProduct(),
                          this.serverSideEnabled
                        )}
                        pdfCallbacks={pdfCallbacks}
                        theme={CommonUtils.getAppTheme()}
                        contentType={this.serverSideEnabled ? constants.CONTENT_TYPE_SPDF : contentType}
                        pageId={isPdf ? this.pageId : (pageId || this.IALaunchPageId)}
                        onBackNavClick={this.handleBackNavClick}
                        onNavigationChange={this.handleNavigationChange}
                        authHandler={this.authHandler.get('CourseContainer')}
                        env={env.ENVIRONMENT}
                        basePath={course.getBasePath(this.state.flags.basePathChange, this.serverSideEnabled)}
                        indexId={indexId}
                        versionUrn={version}
                        config={this.getConfig(pdfLocale, bundleId, redirectUrl, format)}
                        bookInfo={course.getBookInfo(this.printPageNumber)}
                        onNotesCopy={this.handleNotesCopy}
                        moreMenuData={!isIntergratedMLMLaunch && this.moreMenuData}
                        backLinkClient={this.backLinkClient}
                        appLoaded={this.appLoaded}
                        drawerView={!isIntergratedMLMLaunch && this.state.drawerView}
                        openAITutor={this.state.openAITutor}
                        onBotClose={() => this.setState({
                          openAITutor: {
                            isOpen: false
                          }
                        })}
                        onLDTrack={this.commonPlugin.handleLDTrackEvent}
                        updateAITutorUserSettings={this.updateAITutorUserSettings}
                        onInitBraze={this.commonPlugin.onBrazeEventFromReader}
                        menuIconsEnd={this.state.flags.enableQRcode && !mobileAccess
                          && (
                          <QRCodeButton
                            title={CommonUtils.getModalProps().title}
                            onQrCodeClick={this.handleQrCodeClick}
                          />
                          )}
                        getPendoData={this.commonPlugin.onPendoMetaFromReader}
                      />
                    </Suspense>
                  );
                }}
              </LanguageContext.Consumer>
            </Fragment>
          )}
          loadingContent={
            <CircularProgress className="circleStyle" />
          }
          errorContent={
            <ErrorCard code={vegaReaderErrorCode} courseId={match.params.id} productId={match.params.productId} />
          }
        />
        {this.showNotesCopy() && (
                <Suspense fallback={<CircularProgress className="circleStyle" />}>
                  <NotesCopyComponent
                    spectrumXCaller={env.SPECTRUM_X_TENANTKEY}
                    userId={user.id}
                    spectrumClient={Framework.getClientManager().get('spectrum')}
                    popUpOpen={this.state.isNotesCopy}
                    courseItems={courseItems.getData(match.params.id)}
                    toggleShowPopUp={this.handlePopUpToggle}
                    locale={language.currentLanguage}
                  />
                </Suspense>
        )}
        {this.state.showHotspot && (
          <Suspense fallback={<CircularProgress className="circleStyle" />}>
            <HotspotContainer
              basePath={backLinkUrl}
              goToPageNumber={this.handleNavigationChange}
              isStudent={course.isStudent}
              productId={match.params.id}
              regionData={this.regionData}
              userId={smsUserId}
              pagePlaylist={asset.getPagePlaylist(
                bundleId,
                course.getProduct(),
                this.serverSideEnabled
              )}
              isET1CourseId={isET1CourseId}
              bookData={course.getProduct()}
              userName={username}
            />
          </Suspense>
        )}
        {this.state.showGlossary && (
          <Suspense fallback={<CircularProgress className="circleStyle" />}>
            <GlossaryComponent
              glossaryData={this.glossaryData}
              onClickAway={this.handleGlossaryClose}
              regionData={this.regionData}
              showGlossary={this.state.showGlossary}
            />
          </Suspense>
        )}
        {this.state.launchCode && (
          <LaunchCode
            open={this.state.launchCode}
            email={user.email}
            onClose={this.handleMenuClose}
            history={itemsCode}
          />
        )}
        {this.state.showMobileOTPDialog && (
          <TextMessage
            open={this.state.showMobileOTPDialog}
            anchorEl={this.state.anchorEl}
            onClose={this.handleMobileOtpDialogClose}
            phoneNo={phoneNumber}
          />
        )}
        {this.state.openToast && (
          <div className="alert-wrapper">
            <Alert
              onClose={this.handleToastClose}
            >
              <h3>Device activated</h3>
              <p>You won't be able to swap devices again until next month.</p>
            </Alert>
          </div>
        )}
        {this.state.isLimitExceeded ? <DeviceManagement dialogOpen={true} onClose={this.handleDeviceClose} /> : ''}
        <div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={`dialog-zindex popup-backgroundImage ${device.isSwapAlreadyDone ? 'swap-background' : ''}`}
          >
            <IconButton aria-label="close" className="closeButton" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
            {this.state.openAlert && (
              <IconButton aria-label="close" className="arrowBackButton" onClick={this.handleAlertClose}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <DialogTitle id="alert-dialog-title">
              {device.isSwapAlreadyDone
                ? (
                  <div>
                    <h3><FormattedMessage {...language.getText('device.SWAPPED_ALREADY')} /></h3>
                    <p><FormattedMessage {...language.getText('device.CONTACT_SUPPORT')} /></p>
                  </div>
                )
                : this.state.openAlert
                  ? (
                    <div>
                      <h3><FormattedMessage {...language.getText('device.SWITCH_DEVICE')} /></h3>
                      <p><FormattedMessage {...language.getText('device.SWITCH_ONCE')} /></p>
                    </div>
                  )
                  : (
                    <div>
                      <h3><FormattedMessage {...language.getText('device.SEAT_TAKEN')} /></h3>
                      <p><FormattedMessage {...language.getText('device.DISCONNECT_DEVICE')} /></p>
                    </div>
                  )
              }
            </DialogTitle>
            {!device.isSwapAlreadyDone && !this.state.openAlert && (
              <div>
                <DialogContent>
                  <LoadingHandler
                    loading={device.isLoading}
                    content={!device.isSwapAlreadyDone && device.devices.slice().map(item => (
                      <div className="devicelist-container">
                        <div className="list-left">
                          <p>{item.displayName}</p>
                        </div>
                        <div className="list-right">
                          <Button
                            variant="outlined"
                            onClick={() => this.handleConnect(item.deviceId)}
                            color="primary"
                          >
                            <FormattedMessage {...language.getText('device.DISCONNECT')} />
                          </Button>
                        </div>
                      </div>
                    ))}
                    loadingContent={
                      <CircularProgress />
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <p className="tagline"><FormattedMessage {...language.getText('device.ALERT_TEXT')} /></p>
                </DialogActions>
              </div>
            )}
            {this.state.openAlert && (
              <DialogActions className="alert_continue_button">
                <Button onClick={this.handleAlertAgree} color="primary" autoFocus>
                  <FormattedMessage {...language.getText('device.CONTINUE')} />
                </Button>
              </DialogActions>
            )
            }
            {device.isSwapAlreadyDone && (
              <DialogActions />
            )}
          </Dialog>
        </div>
        {brazeAINotificationCard && (
          <BrazeContainer
            data={brazeAINotificationCard?.data}
            callbackForBraze={brazeAINotificationCard?.callbackForBraze}
          />
        )}
        {(isIntergratedMLMLaunch && this.state.flags.showMLMLaunchButton) && (
          <FloatingButton />
        )}
        {this.state.modalProps.open && (
          <QRCodeModal
            data={this.state.modalProps}
            callbacks={{
              handleQrCodeClick: this.handleQRCodeClose
            }}
          />
        )}
      </Fragment>
    );
  }
}

CourseContainer.defaultProps = {
  ldClient: {}
};

export default isIntergratedMLMLaunch ? CourseContainer : withLDConsumer({ clientOnly: true })(CourseContainer);
