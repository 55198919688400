/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Chinese(Chinese - Simplified) language
 *
 * @file zh-cn.js
 * @author Manimaran.S
 */

const zhcn = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% 将应用程序下载到其他设备上并自动登录，或使用您唯一的二维码安全登录您已安装的应用程序。',
  'moremenu.downloadAppNotDownload': '%Click here% 下载应用程序并使用您唯一的二维码安全登录',
  'channel.bundleTitle': '访问其他课程的学习和考试准备包',
  'channel.noBundletitle': '使用学习和考试准备为您的其他课程提供帮助',
  'onboard.welcome': '欢迎来到Pearson+',
  'onboard.welcomeCourse': '您来对地方了，在这里可以访问<span>{COURSE_NAME}</span>等。',
  'onboard.welcomeCourseList': '您购买的产品包括：',
  'onboard.next': '下一步',
  'onboard.studyPlan': '已创建自定义学习计划！',
  'onboard.addCourseAnother': '您是否想添加其他课程？',
  'onboard.purchase': '我需要购买培生电子教材',
  'onboard.addCourseWithoutEtext': '添加没有电子教材的课程',
  'onboard.addCourseWithoutEtextBundle': '使用学习和考试准备添加课程',
  'onboard.doYouWantOtherCourse': 'Do you want to another course?',
  'onboard.mayBeLater': '稍后',
  'onboard.close': 'Close',
  'course.addTitle': '添加课程',
  'bookshelf.TITLE': '我的书架',
  'common.LOGOUT': '注销',
  'common.GO_HOME': '前往主页',
  'common.PURCHASE': '购买',
  'courses.WELCOME': '{userName} 您好，欢迎来到您的 {courseName} 课程！',
  'courses.action.SHOW_ASSIGNMENTS': '显示作业',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '学生将在下次打开内容时看到您的更改。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '订阅未找到',
  'errors.SUBSCRIPTION_GENERIC_DESC': '您的账户没有访问该章目的权限。如果您认为您错误接收了这条消息，请联系培生技术支持团队',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '订阅未找到',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '您的订阅不包括访问 eText，然而您可以选择使用信用卡立即订阅。',
  'foxit.ABOUT': '关于',
  'foxit.COPY_RIGHT': 'PDF 阅读器由 Foxit 提供支持。Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.myAccount': '我的帐户',
  'menu.helpCenter': '帮助中心',
  'menu.preferencesCenter': '偏好设置中心',
  'menu.GET_THE_APP_DOWNLOAD': '下载移动应用程序',
  'menu.CONTACT_SUPPORT': '联系客服团队',
  'menu.ABOUT': '关于',
  'menu.FOXIT': 'Foxit 提供支持',
  'menu.TERMS_OF_USE': '使用条款',
  'menu.PRIVACY_POLICY': '隐私政策。',
  'menu.SIGN_OUT': '注销',
  'menu.PRINT_PAGE': '打印页面',
  'menu.GET_LOOSE_LEAF': '获得活页',
  'menu.AVAILABLE': '獲得活頁',
  'menu.PRINT_VERSION': '想获得纸质版本吗？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. 保留所有权利。',
  'menu.tooltip.SCHEDULE': '计划安排',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': '{userName} 您好',
  'device.SWAPPED_ALREADY': '您本月已经切换过了',
  'device.SWITCH_DEVICE': '切换到当前设备？',
  'device.SWITCH_ONCE': '由于您每月只能进行一次切换，因此您无法撤销此操作。',
  'device.SEAT_TAKEN': '该名额已被占用',
  'device.DISCONNECT_DEVICE': '您只能在3台设备上登录。要从此处继续，请断开另一台设备。',
  'device.ALERT_TEXT': '请记住，您每月只能进行一次设备切换。',
  'device.YOUR_MOJO': '您正在前往Mojo',
  'device.DISCONNECT': '断开',
  'device.CONTINUE': '继续',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': '回到主页',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': '您需要在此处{time}输入验证码',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': '验证码错误 - 请检查验证码输入是否正确，再试一次。',
  'device.MANY_ATTEMPTS': '错误次数过多，请重新发送验证码',
  'device.EXPIRED': '验证码已过期，请重新发送验证码',
  'device.ACCESS_HEADER': '大功告成！',
  'device.ACCESS_DESC': '您的会话已通过验证。想要在登录状态下更改密码？',
  'device.ACCESS_CHANGE_PASSWORD': '更改密码',
  'device.ACCESS_RESET_PASSWORD': '重置密码',
  'device.THREATONE_TITLE': '是否一切正常？',
  'device.THREATONE_DESC': '我们注意到您的帐户存在可疑活动。',
  'device.THREATONE_DESC_TWO': '建议您最好修改密码。',
  'device.MAY_BE_LATER': '稍后',
  'device.OKAY': '確定',
  'device.ACCOUNT_LOCK_DESC': '為了安全起見，我們將使您先登出。我們已向您的帳戶關聯電子郵箱傳送了密碼重設連結。',
  'device.ACCOUNT_LOCK_TITLE': '我们注意到您的帐户存在可疑活动。',
  'device.ACCOUNT_LOCK_TIMER': '您將被登出 {time}',
  'menu.HELP_SUPPORT': '帮助和支持',
  'menu.FAQS': '常见问题',
  'menu.CHAT_WITH_SUPPORT': '与客服交谈',
  'menu.QUICK_WALKTHROUGH': '快速攻略',
  'menu.LEGAL': '法务部',
  'menu.PRIVACY_NOTICE': '隐私通知',
  'menu.TITLE_INFO': '标题信息',
  'menu.PEARSON_ACCESSIBILITY': '培生可及性',
  'menu.DEVICE_WARNING_MESSAGE': '注册 {deviceLimit} 台设备。每月可切换1台设备。',
  'menu.ACCESSIBILITY': '可及性',
  'menu.ACCESSIBILITY_SUPPORT': '可及性支持',
  'menu.DEVICES': '最近设备',
  'menu.THIS_DEVICE': '此设备',
  'menu.MOJO_APP_INFO': '前往您的手機或平板上的應用程式商店以離線使用Pearson+。',
  'menu.DEVICE_LABEL': '裝置',
  'navigation.PAGE': '页码',
  'launchcode.CREATEBY': '创建于：',
  'launchcode.sentvia': '发送方式:',
  'launchcode.CODEINLAST': '过去 7 天内的代码：',
  'launchcode.TIMESENT': '发送时间：',
  'launchcode.ACCOUNTEMAIL': '帐户电子邮箱：',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP 代码日志',
  'menu.OTP_CODES': 'OTP 代码',
  'menu.LAUNCH_CODE': '启动代码日志',
  'menu.SEND_CODE': '通过电子邮件向用户发送新代码',
  'menu.SEND_CODE_PHONE': '通过短信向用户发送新代码',
  'menu.COMMUNITY_GUIDELINES': '社区指南',
  'textmessage.TITLE': '输入电话号码',
  'textmessage.PHONE_NUMBER': '电话号码',
  'textmessage.SEND_CODE': '发送代码',
  'textmessage.INVALID_NUMBER': '无效的电话号码。',
  'textmessage.SUCCESS_MESSAGE': '代码已发送',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': '搜索您的列表',
  'remove.title': '误删了书目？',
  'remove.subTitle': '您可随时恢复误删书目——重新搜索后再次添加即可。',
  'remove.buttonText': '删除',
  'footer.downloadMobileApp': '下载移动应用程序',
  'footer.termsOfUse': '使用条款',
  'footer.privacy': '隐私',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': '不要出售我的个人信息',
  'footer.accessibility': '可及性',
  'footer.patentNotice': '专利通知',
  'footer.copyrights': '培生保留所有权利。',
  'channel.videos': '视频',
  'channel.questions': '问题',
  'channel.descriptionA': '利用“频道”补充阅读内容。',
  'channel.descriptionB': '利用“频道”解决疑难问题。',
  'channel.openchannel': '打开频道',
  'hero.continueReading': '从上次离开的位置',
  'hero.openBook': '打开书',
  'hero.channel.open': '打开频道',
  'hero.addATitle': '添加书籍',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': '查看无效书名',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: '查找您的电子教材'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': '打开电子教材',
  'hero.openEtextBookV1': '打开电子教材',
  'hero.heroHeading': '因为您正在学习 <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': '学习和考试准备包',
  'hero.study.help': '获得学习帮助',
  'hero.study.jumpBackIn': '返回',
  'hero.study.numOfUsers': '当前有<b>{num_of_users}</b>名学生正在使用频道功能。',
  'hero.heroBook.sectionHeading': '在课程学习中无往不利',
  'hero.heroBook.channelsSectionHeading': '开始新的学习阶段',
  'hero.heroBook.sectionHeadingNewUser': '学习帮助在此处',
  'hero.heroBook.sectionSubHeading': '通过专为您的课程量身打造的习题和视频讲解，条分缕析艰深主题。',
  'hero.heroChannel.cardsHeading': '从中断处继续',
  'hero.marketingcard.subheading': '观看专为您的课程量身打造的原理分析视频讲解，完成额外习题，学习更高效。',
  'hero.marketingcard1.copy.title': '利用视频学习',
  'hero.marketingcard1.copy.description': '观看分析讲解复杂话题的简短视频。',
  'hero.marketingcard2.copy.title': '习题',
  'hero.marketingcard2.copy.description': '利用海量的模拟习题和讲解视频解决方案，为考试做好准备',
  'hero.marketingcard3.copy.title': '向经验丰富的人士获取帮助',
  'hero.marketingcard3.copy.description': '发布问题，并从课程专家处获得解答。',
  'hero.popularTopics.heading': '本频道的其他热门话题',
  'hero.popularChannels.heading': '热门频道',
  'hero.popularChannels.headingv1': '参加另一门课程？',
  'hero.popularChannels.description': '从这些热门频道中获得学习帮助。',
  'hero.popularChannels.descriptionv1': '选择不同频道，获得不同课程的学习帮助。',
  'hero.popularChannels.descriptionv2': '浏览热门学习帮助主题。',
  'hero.startReading': '开始阅读',
  'hero.practice': '练习',
  'hero.noTitleNoChannel.sectionHeading': '在前所未有的短时间内为考试做好准备。',
  'hero.herobook.mappedChannelHeading': '本频道推荐的学习帮助',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': '分析讲解复杂话题的简短视频',
  'hero.promotionalcard.list2': '海量的模拟习题',
  'hero.promotionalcard.list3': '从课程专家处获得解答',
  'hero.studyandexamprep': '学习和考试准备',
  'hero.heroBook.sectionSubHeadingV1': '通过专为您的课程量身打造的习题和视频讲解，条分缕析艰深主题。',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': '了解更多内容',
  'hero.subscriptionStatusTitle': 'Pearson+订阅状态',
  'hero.eTextbook': '电子教材',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': '转到页面',
  'subscription.eTextbook': '电子教材',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': '学习和考试准备包',
  'subscription.studyNoBundle': '学习和考试准备',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': '查找您的电子教材',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': '从控制面板中删除课程？',
  'hero.removecourse.description': '您随时可以通过点击“Add course（添加课程）”重新将这个课程添加到控制面板中',
  'hero.removecourse.primaryCtaText': '删除',
  'hero.removecourse.secondaryCtaText': '取消',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': '搜索电子教材',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': '免费学习一种新语言',
  'hero.mondly.learnLang.title2': '免费学习一种新语言!',
  'hero.mondly.subTitle': '培生电子教材用户可免费获得三个月的Mondly by Pearson。',
  'hero.mondly.description': '大多数语言学习应用程序只允许您通过英语学习其他语种。但最佳方式是通过您的母语学习其他语种，因此，在Mondly中，您可以通过我们 41 种语言中的任何一种学习其他语种。',
  'hero.mondly.startLearningCTA': '开始学习',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': '培生的更多内容',
  'hero.extractingExamDetails': '正在提取考试详细信息',
  'hero.finishingTheProcess': '即将完成流程',
  'hero.hereWhatWeGotFromYourSyllabus': '这是我们从您的教学大纲中获取的内容',
  'hero.school': '学校：',
  'hero.class': '班级：',
  'hero.textbook': '课本：',
  'hero.examSchedule': '考试时间表：',
  'hero.confirm': '确认',
  'onboard.aboutCourse': '告诉我们您的课程',
  'onboard.studyMaterials': '选择您的课程，我们将创建一个包含自定义学习材料的控制面板。',
  'hero.personalizedStudyPlan': '我们将利用人工智能为您提供个性化的学习计划。',
  'hero.welcomeToP': '欢迎来到P+！',
  'hero.learnYourWayAround': '现在花点时间来了解一下这款应用程序，或者下次在帐户设置中了解。',
  'hero.weveCreatedADashboard': '我们在这里为您的课程创建了一个控制面板。',
  'hero.youCanAddYourPearsonCourse': '您还可以添加自己的非培生课程并免费试用学习材料。',
  'libraryL2.back': '返回',
  'hero.getExamreadyWithVideoLessons': '通过根据您的课程自定义的视频课程和习题集，为考试做好准备。',
  'hero.youreTexbook': '您的电子教材及其所有工具都在这里。',
  'hero.VideosAndPractice': '学习和考试准备中始终提供更多视频和练习题。',
  'hero.pleaseSelectTheChapters': '请选择本次考试涵盖的章节：',
  'hero.tableOfContents': '目录',
  'hero.search': '搜索',
  'hero.notes': '笔记及高亮内容',
  'hero.doYouWantToAddACourse': '您想添加课程吗？',
  'hero.whatsAvailableForYou': '您可以访问的内容',
  'hero.whatsAvailableForYouInPearson': '您可以在Pearson+中访问的内容',
  'hero.continueStudying': '继续学习',
  'hero.startStudying': '开始学习',
  'hero.unlockYourStudyExamPrep': '马上解锁其他课程的学习和考试准备包！',
  'header.menu.learnLanguage': '学习一种语言',
  'hero.subscriptionStatusTitle2': '您的Pearson+资源',
  'hero.getExamreadyForYou': '使用学习和考试准备为您的其他课程做好考试准备。',
  'hero.weVideoLessons': '我们有根据您的需求自定义的视频课程和习题集。',
  'hero.tryForFree': '免费试用',
  'hero.addCourseUsingStudyExamPrep': '使用学习和考试准备添加课程',
  'hero.for': '对于',
  'hero.studyExamPrepPackForAllCourse': '适用于您所有课程的学习和考试准备包',
  'hero.myETextbooks': '我的电子教材',
  'header.continueInTheApp': '在应用程序中继续',
  'hero.goToMyLabMastering': '转到MyLab/Mastering',
  'hero.learnTxt': '学习',
  'hero.aITutorTxt': '人工智能导师',
  'hero.exploreTxt': '探索',
  'hero.studyExamPrepAllYourCourses': '适用于您所有课程的学习和考试准备',
  'hero.examSchedules': '考试时间表',
  'hero.examSchedules2': '考试时间表',
  'hero.uploadYourSyllabus': '上传您的教学大纲',
  'hero.addAnExam': '添加考试',
  'hero.UploadYourSyllabus.studyExamPrepPack': '上传您的教学大纲，以充分利用您的学习和考试准备包',
  'hero.UploadYourSyllabus.studyExamPrep': '上传您的教学大纲，以充分利用您的“学习和考试准备”',
  'hero.getRecommendationsOnWhat': '获取有关学习内容和时间的建议',
  'hero.noSyllabusSharingExamSchedule': '没有教学大纲？也可以分享您的考试时间表',
  'hero.selectDropSyllabusFile': '选择或删除PDF或DOCX格式的教学大纲文件',
  'hero.uploadTxt': '上传',
  'hero.examName': '考试名称',
  'hero.completeTxt': '完成',
  'hero.exactTopicsChapter': '稍后您将能够指定每章的具体主题',
  'hero.uploadingDocument': '正在上传文件',
  'hero.activatingAIComponents': '正在激活人工智能组件',
  'hero.extractingClassSchoolData': '正在提取班级和学校数据',
  'hero.extractingTextbookChapters': '正在提取课本章节',
  'hero.insideStudyExamPrepPack': '内部学习和考试准备包',
  'hero.userSubsGuidence': '您可以不受限制地访问根据您的考试时间表量身定制的视频课程、学习计划和习题集。另外，还可以提出问题并获得领域内专家的反馈。',
  'hero.examPrepTxt': '考试准备',
  'hero.extractingDataFromSyllabus': '正在从教学大纲中提取数据……',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': '内部学习和考试准备',
  'hero.testVideoLessons': '测试根据您的考试时间表量身定制的视频课程、学习计划和习题集。',
  'header.qrCode.subHeaderTxt': '使用此二维码快速安全地登录移动设备上的Pearson+应用程序。',
  'header.qrCode.dontHaveApp': '没有该应用程序？',
  'header.qrCode.footerTxt': '扫描此二维码会将您重定向到App Store或Google Play。下载该应用程序后，再次扫描登录。',
  'header.qrCode.errorMsg': '二维码无法加载。关闭并重试。',
  'hero.yourETextbook': '您的电子教材',
  'onboard.addCourseWithEtextBundle': '使用学习和考试准备包添加课程',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': '访问学习和考试准备',
  'channel.genericBundle': '使用您的学习和考试准备包为您的其他课程提供帮助',
  'channel.relatedBundle': '还可以使用您的学习和考试准备包为您的其他课程提供帮助',
 'onboard.courseAdded': '已添加课程！',
  'hero.flashcards': '记忆卡',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use this QR code to open the Pearson+ app on your mobile device.',
  'print.qrCode.footer': 'Scanning this code will redirect you to the App store or Google Play. Once the app is installed, sign in to download and access your title.'
};

export default zhcn;
