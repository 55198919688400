/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for German language
 *
 * @file de.js
 * @author Manimaran.S
 */
const de = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% zum Herunterladen der App auf ein anderes Gerät und zur automatischen Anmeldung oder zur sicheren Anmeldung in der bereits installierten App mit Ihrem individuellen QR-Code.',
  'moremenu.downloadAppNotDownload': '%Click here% um die App herunterzuladen und sich mit Ihrem eindeutigen QR-Code sicher anzumelden.',
  'channel.bundleTitle': 'Zugriff auf das Studien- und Prüfungsvorbereitungspaket für Ihre weiteren Kurse',
  'channel.noBundletitle': 'Nutzen Sie Ihre Studien- und Prüfungsvorbereitung zur Hilfe bei anderen Kursen',
  'onboard.welcome': 'Willkommen bei Pearson+',
  'onboard.welcomeCourse': 'Hier sind Sie richtig, um auf den <b>{COURSE_NAME}</b> und vieles mehr zuzugreifen.',
  'onboard.welcomeCourseList': 'Ihr Kauf beinhaltet:',
  'onboard.next': 'Weiter',
  'onboard.studyPlan': 'Persönlicher Studienplan erstellt!',
  'onboard.addCourseAnother': 'Möchten Sie einen weiteren Kurs hinzufügen?',
  'onboard.purchase': 'Ich muss ein eTextbook von Pearson kaufen',
  'onboard.addCourseWithoutEtextBundle': 'Kurs mit Studien- und Prüfungsvorbereitung hinzufügen',
  'onboard.doYouWantOtherCourse': 'Möchten Sie einen weiteren Kurs hinzufügen?',
  'onboard.addCourseWithoutEtext': 'Fügen Sie einen Kurs ohne eTextbook hinzu',
  'onboard.mayBeLater': 'Vielleicht später',
  'onboard.aboutCourse': 'Erzählen Sie uns von Ihrem Kurs',
  'onboard.close': 'Close',
  'course.addTitle': 'Kurs hinzufügen',
  'bookshelf.TITLE': 'Mein Bücherregal',
  'common.GO_HOME': 'Gehen Sie zur Startseite',
  'common.LOGOUT': 'Abmelden',
  'common.PURCHASE': 'Kaufen',
  'courses.action.SHOW_ASSIGNMENTS': 'Zuordnungen anzeigen',
  'courses.toc.PUBLISH_MESSAGE': 'Die Studenten sehen Ihre Änderungen beim nächsten Öffnen von Inhalten.',
  'courses.toc.PUBLISH_SUCCESS': 'Erfolg!',
  'courses.WELCOME': 'Hallo {userName}, willkommen zu Ihrem Kurs {courseName}!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Ihr Konto enthält keinen Zugriff auf diesen Titel. Wenn Sie der Meinung sind, dass Sie diese Nachricht irrtümlich erhalten haben, wenden Sie sich bitte an den technischen Support von Pearson',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonnement konnte nicht gefunden werden',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Ihr Abonnement beinhaltet keinen Zugriff auf den eText. Sie können ihn jedoch jetzt mit einer Kreditkarte abonnieren.',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonnement konnte nicht gefunden werden',
  'foxit.ABOUT': 'Über',
  'foxit.COPY_RIGHT': 'PDF Reader unterstützt von Foxit. Urheberrecht 2003-2019 von Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.ABOUT': 'Über',
  'menu.AVAILABLE': 'Auch verfügbar',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Alle Rechte vorbehalten.',
  'menu.CONTACT_SUPPORT': 'Support kontaktieren',
  'menu.FOXIT': 'Unterstützt von Foxit',
  'menu.GET_LOOSE_LEAF': 'Lose Blätter erhalten',
  'menu.PRINT_PAGE': 'Seite drucken',
  'menu.PRINT_VERSION': 'Möchten Sie die gedruckte Version erhalten?',
  'menu.PRIVACY_POLICY': 'Datenschutzbestimmungen',
  'menu.SIGN_OUT': 'Abmelden',
  'menu.TERMS_OF_USE': 'Nutzungsbedingungen',
  'menu.tooltip.SCHEDULE': 'Zeitplan',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hallo {userName}',
  'device.SWAPPED_ALREADY': 'Sie haben diesen Monat bereits getauscht',
  'device.SWITCH_DEVICE': 'Zum aktuellen Gerät wechseln?',
  'device.SWITCH_ONCE': 'Da Sie nur einmal im Monat wechseln können, können Sie dies nicht mehr rückgängig machen.',
  'device.SEAT_TAKEN': 'Dieser Platz ist besetzt',
  'device.DISCONNECT_DEVICE': 'Sie können sich nur an 3 Geräten anmelden. Um hier fortzufahren, trennen Sie die Verbindung zu einem weiteren Gerät.',
  'device.ALERT_TEXT': 'Denken Sie daran, dass Sie die Geräte nur einmal im Monat tauschen können.',
  'device.YOUR_MOJO': 'Sie sind auf dem Weg zum Mojo',
  'device.DISCONNECT': 'Verbindung trennen',
  'device.CONTINUE': 'Weiter',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Zurück zur Startseite',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Sie haben {time}, um Ihren Code hier einzugeben',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Falscher Code - überprüfen Sie Ihren Code noch einmal oder senden Sie ihn erneut.',
  'device.MANY_ATTEMPTS': 'Zu viele Versuche, versuchen Sie, den Code erneut zu senden',
  'device.EXPIRED': 'Ihre Zeit ist abgelaufen. Versuchen Sie, den Code erneut zu senden',
  'device.ACCESS_HEADER': 'Sie haben es geschafft!',
  'device.ACCESS_DESC': 'Ihre Sitzung wurde authentifiziert. Möchten Sie gleich noch Ihr Passwort aktualisieren?',
  'device.ACCESS_CHANGE_PASSWORD': 'Passwort ändern',
  'device.ACCESS_RESET_PASSWORD': 'Passwort zurücksetzen',
  'device.THREATONE_TITLE': 'Sie alles gut aus?',
  'device.THREATONE_DESC': 'Wir haben eine verdächtige Aktivität auf Ihrem Konto festgestellt.',
  'device.THREATONE_DESC_TWO': 'Dies wäre ein guter Zeitpunkt, um Ihr Passwort zu ändern.',
  'device.MAY_BE_LATER': 'Vielleicht später',
  'device.OKAY': 'Okay',
  'device.ACCOUNT_LOCK_DESC': 'Aus Sicherheitsgründen melden wir Sie jetzt ab. Wir haben einen Link zum Zurücksetzen des Passworts an die mit Ihrem Konto verknüpfte E-Mail geschickt.',
  'device.ACCOUNT_LOCK_TITLE': 'Wir haben eine verdächtige Aktivität auf Ihrem Konto festgestellt',
  'device.ACCOUNT_LOCK_TIMER': 'Sie werden abgemeldet in {time}',
  'menu.myAccount': 'Mein Konto',
  'menu.helpCenter': 'Hilfe-Center',
  'menu.preferencesCenter': 'Einstellungen-Center',
  'menu.GET_THE_APP_DOWNLOAD': 'Mobile App herunterladen',
  'menu.HELP_SUPPORT': 'Hilfe und Unterstützung',
  'menu.FAQS': 'Häufig gestellte Fragen (FAQs)',
  'menu.CHAT_WITH_SUPPORT': 'Chat mit dem Support',
  'menu.QUICK_WALKTHROUGH': 'Schnelldurchgang',
  'menu.LEGAL': 'Rechtliches',
  'menu.PRIVACY_NOTICE': 'Datenschutzerklärung',
  'menu.TITLE_INFO': 'Titel-Informationen',
  'menu.PEARSON_ACCESSIBILITY': 'Barrierefreiheit von Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Registrieren Sie {deviceLimit} Geräte. Tauschen Sie 1 pro Monat aus.',
  'menu.ACCESSIBILITY': 'Zugänglichkeit',
  'menu.ACCESSIBILITY_SUPPORT': `Unterstützung der Barrierefreiheit`,
  'menu.DEVICES': 'Neueste Geräte',
  'menu.THIS_DEVICE': 'Dieses Gerät',
  "menu.MOJO_APP_INFO": "Rufen Sie den App-Store auf Ihrem Telefon oder Tablet auf, um Pearson+ offline zu nutzen.",
  "menu.DEVICE_LABEL": "Gerät",
  'navigation.PAGE': 'Seite',
  'launchcode.CREATEBY': 'Erstellt von:',
  'launchcode.sentvia': 'Gesendet per:',
  'launchcode.CODEINLAST': 'Codes in den letzten 7 Tagen:',
  'launchcode.TIMESENT': 'Uhrzeit gesendet:',
  'launchcode.ACCOUNTEMAIL': 'E-Mail-Konto:',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP-Code-Protokoll',
  'menu.OTP_CODES': 'OTP-Codes',
  'menu.LAUNCH_CODE': 'Code-Protokoll starten',
  'menu.SEND_CODE': 'Benutzer neuen Code per E-Mail senden',
  'menu.SEND_CODE_PHONE': 'Benutzer neuen Code per Textnachricht senden',
  'menu.COMMUNITY_GUIDELINES': 'Community-Leitlinien',
  'textmessage.TITLE': 'Telefonnummer eingeben',
  'textmessage.PHONE_NUMBER': 'Telefonnummer',
  'textmessage.SEND_CODE': 'Code senden',
  'textmessage.INVALID_NUMBER': 'Ungültige Telefonnummer.',
  'textmessage.SUCCESS_MESSAGE': 'Code gesendet',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Ihre Liste durchsuchen',
  'remove.title': 'Soll der Titel entfernt werden?',
  'remove.subTitle': 'Sie können ihn später jederzeit wieder hinzufügen - verwenden Sie einfach die Suche, um ihn erneut hinzuzufügen.',
  'remove.buttonText': 'Entfernen',
  'footer.downloadMobileApp': 'Laden Sie die Mobile App herunter',
  'footer.termsOfUse': 'Nutzungsbedingungen',
  'footer.privacy': 'Datenschutz',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Verkaufen Sie meine personenbezogenen Informationen nicht',
  'footer.accessibility': 'Zugänglichkeit',
  'footer.patentNotice': 'Hinweis zum Patent',
  'footer.copyrights': 'Pearson Alle Rechte vorbehalten.',
  'channel.videos': 'Videos',
  'channel.questions': 'Fragen',
  'channel.descriptionA': 'Ergänzen Sie Ihre Lektüre mit Channels.',
  'channel.descriptionB': 'Mit Channels erhalten Sie Hilfe zu schwierigen Themen.',
  'channel.openchannel': 'Channel öffnen',
  'hero.continueReading': 'Weiterlesen',
  'hero.openBook': 'Buch öffnen',
  'hero.channel.open': 'Channel öffnen',
  'hero.addATitle': 'Einen Titel hinzufügen',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Inaktive Titel anzeigen',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Finden Sie Ihr eTextbuch'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'eTextbuch öffnen',
  'hero.openEtextBookV1': 'eTextbuch öffnen',
  'hero.heroHeading': 'Da Sie Folgendes studieren: <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Studien- und Prüfungsvorbereitungspaket',
  'hero.study.help': 'Lernhilfe erhalten',
  'hero.study.jumpBackIn': 'Zurückspringen',
  'hero.study.numOfUsers': 'Zurzeit nutzen <b>{num_of_users}</b> Studenten Channels.',
  'hero.heroBook.sectionHeading': 'Lassen Sie sich in Ihrem Kurs nicht beirren',
  'hero.heroBook.channelsSectionHeading': 'Beginnen Sie eine neue Lerneinheit',
  'hero.heroBook.sectionHeadingNewUser': 'Studienhilfe erhalten Sie hier',
  'hero.heroBook.sectionSubHeading': 'Meistern Sie schwierige Themen mit Übungsfragen und Videoerklärungen, die auf Ihren Kurs zugeschnitten sind.',
  'hero.heroChannel.cardsHeading': 'Machen Sie dort weiter, wo Sie aufgehört haben',
  'hero.marketingcard.subheading': 'Lernen Sie effizienter mit Videos, die schwierige Konzepte aufschlüsseln, und zusätzlichen Übungsaufgaben, die alle auf Ihren Kurs zugeschnitten sind.',
  'hero.marketingcard1.copy.title': 'Lernen Sie mit Videos',
  'hero.marketingcard1.copy.description': 'Sehen Sie sich kurz gefasste Erklärungsvideos an, die schwierige Themen aufschlüsseln.',
  'hero.marketingcard2.copy.title': 'Üben Sie Aufgaben',
  'hero.marketingcard2.copy.description': 'Bereiten Sie sich mit Tausenden von prüfungsähnlichen Fragen und Videolösungen auf Ihre Prüfung vor',
  'hero.marketingcard3.copy.title': 'Holen Sie sich Hilfe bei erfahrenen Tutoren',
  'hero.marketingcard3.copy.description': 'Stellen Sie Fragen und erhalten Sie Antworten von Fachleuten.',
  'hero.popularTopics.heading': 'Andere beliebte Themen in diesem Channel',
  'hero.popularChannels.heading': 'Beliebte Channels',
  'hero.popularChannels.headingv1': 'Nehmen Sie an einem anderen Kurs teil?',
  'hero.popularChannels.description': 'Erhalten Sie Lernhilfe mit diesen Top-Channels.',
  'hero.popularChannels.descriptionv1': 'Holen Sie sich Hilfe für einen anderen Kurs, indem Sie einen Kanal wählen.',
  'hero.popularChannels.descriptionv2': 'Durchsuchen Sie beliebte Themen der Studienhilfe.',
  'hero.startReading': 'Anfangen zu lesen',
  'hero.practice': 'Üben',
  'hero.noTitleNoChannel.sectionHeading': 'Seien Sie in Rekordzeit prüfungsbereit',
  'hero.herobook.mappedChannelHeading': 'Empfohlene Studienhilfe in diesem Channel',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Zurück',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Sehen Sie sich kurz gefasste Videos an, die schwierige Themen aufschlüsseln',
  'hero.promotionalcard.list2': 'Tausende von prüfungsähnlichen Fragen',
  'hero.promotionalcard.list3': 'Erhalten Sie Antworten von Fachleuten',
  'hero.studyandexamprep': 'Studien- und Prüfungsvorbereitung',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Mehr erfahren',
  'hero.subscriptionStatusTitle': 'Abonnement-Status bei Pearson+',
  'hero.eTextbook': 'eTextbuch',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Weiter zur Seite',
  'subscription.eTextbook': 'eTextbuch',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Studien- und Prüfungsvorbereitungspaket',
  'subscription.studyNoBundle': 'Studien- und Prüfungsvorbereitung',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Lernen fortsetzen',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Finden Sie Ihr eTextbuch',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Kurs vom Dashboard entfernen?',
  'hero.removecourse.description': 'Sie können diesen Kurs Ihrem Dashboard jederzeit wieder hinzufügen, indem Sie auf „Kurs hinzufügen“ klicken.',
  'hero.removecourse.primaryCtaText': 'Entfernen',
  'hero.removecourse.secondaryCtaText': 'Abbrechen',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'eTextbooks durchsuchen',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Lernen Sie kostenlos eine neue Sprache',
  'hero.mondly.learnLang.title2': 'Lernen Sie kostenlos eine neue Sprache!',
  'hero.mondly.subTitle': 'Nutzer des eTextbuchs von Pearson erhalten drei Monate lang kostenlos Mondly von Pearson.',
  'hero.mondly.description': 'Mit den meisten Apps zum Sprachen lernen können Sie nur auf Englisch lernen. Aber am besten ist es, wenn Sie in Ihrer Muttersprache lernen. Deshalb können Sie mit Mondly in jeder unserer 41 Sprachen lernen.',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Mehr bei Pearson',
  'hero.extractingExamDetails': 'Extrahieren von Prüfungsdetails',
  'hero.finishingTheProcess': 'Beendigung des Prozesses',
  'hero.hereWhatWeGotFromYourSyllabus': 'Hier sehen Sie, was Ihr Lehrplan enthält',
  'hero.school': 'Schule:',
  'hero.class': 'Klasse:',
  'hero.textbook': 'Lehrbuch:',
  'hero.examSchedule': 'Zeitplan der Prüfung:',
  'hero.confirm': 'Bestätigen',
  'hero.personalizedStudyPlan': 'Wir werden KI nutzen, um Ihnen einen persönlichen Studienplan zu erstellen.',
  'hero.welcomeToP': 'Willkommen bei P+!',
  'hero.learnYourWayAround': 'Nehmen Sie sich jetzt eine Minute Zeit, um sich zurechtzufinden, oder holen Sie dies ein anderes Mal in den Kontoeinstellungen nach.',
  'hero.youCanAddYourPearsonCourse': 'Sie können auch Ihre eigenen, nicht von Pearson stammenden Kurse hinzufügen und Lernmaterialien kostenlos testen.',
  'hero.getExamreadyWithVideoLessons': 'Bereiten Sie sich mit Videolektionen und auf Ihren Kurs zugeschnittenen Übungseinheiten auf die Prüfung vor.',
  'hero.youreTexbook': 'Ihr eTexbook und alle dazugehörigen Tools sind hier zu finden.',
  'hero.VideosAndPractice': 'In der Studien- und Prüfungsvorbereitung stehen ständig weitere Videos und Übungsfragen zur Verfügung.',
  'hero.pleaseSelectTheChapters': 'Bitte wählen Sie die Kapitel aus, die in dieser Prüfung behandelt werden:',
  'hero.doYouWantToAddACourse': 'Möchten Sie einen Kurs hinzufügen?',
  'hero.whatsAvailableForYou': 'Was für Sie verfügbar ist',
  'hero.startStudying': 'Mit dem Lernen beginnen',
  'hero.tableOfContents': 'Inhaltsverzeichnis',
  'hero.search': 'Suche',
  'hero.notes': 'Notizen und Markierungen',
  'hero.mondly.startLearningCTA': 'Mit dem Lernen beginnen',
  'header.menu.learnLanguage': 'Lernen Sie eine Sprache',
  'hero.subscriptionStatusTitle2': 'Ihre Ressourcen bei Pearson+',
  'hero.unlockYourStudyExamPrep': 'Schalten Sie jetzt Ihr Studien- und Prüfungsvorbereitungspaket für andere Kurse frei!',
  'hero.whatsAvailableForYouInPearson': 'Was ist für Sie in Pearson+ verfügbar?',
  'onboard.studyMaterials': 'Wählen Sie Ihr Thema und wir erstellen ein Dashboard mit maßgeschneiderten Lernmaterialien.',
  'hero.weveCreatedADashboard': 'Wir haben hier ein Dashboard für Ihren Kurs erstellt.',
  'hero.getExamreadyForYou': 'Bereiten Sie sich mit der Studien- und Prüfungsvorbereitung auf Ihre anderen Kurse vor.',
  'hero.weVideoLessons': 'Wir bieten Ihnen auf Ihre Bedürfnisse zugeschnittene Videolektionen und Übungssets.',
  'hero.tryForFree': 'Kostenlos ausprobieren',
  'hero.addCourseUsingStudyExamPrep': 'Kurs mit Studien- und Prüfungsvorbereitung hinzufügen',
  'hero.heroBook.sectionSubHeadingV1': 'Meistern Sie schwierige Themen mit Übungsfragen und Videoerklärungen, die auf Ihren Kurs zugeschnitten sind.',
  'hero.for': 'Für',
  'hero.studyExamPrepPackForAllCourse': 'Studien- und Prüfungsvorbereitungspaket für alle Ihre Kurse',
  'hero.myETextbooks': 'Meine eTextbücher',
  'header.continueInTheApp': 'Weiter in der App',
  'hero.goToMyLabMastering': 'Zu MyLab/Mastering gehen',
  'hero.learnTxt': 'Lernen',
  'hero.aITutorTxt': 'KI-Tutor',
  'hero.exploreTxt': 'Entdecken',
  'hero.studyExamPrepAllYourCourses': 'Studien- und Prüfungsvorbereitung für alle Ihre Kurse',
  'hero.examSchedules': 'Zeitplan der Prüfung',
  'hero.examSchedules2': 'Zeitplan der Prüfung',
  'hero.uploadYourSyllabus': 'Laden Sie Ihren Lehrplan hoch',
  'hero.addAnExam': 'Fügen Sie eine Prüfung hinzu',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Laden Sie Ihren Lehrplan hoch, um das Beste aus Ihrem Studien- und Prüfungsvorbereitungspaket herauszuholen',
  'hero.UploadYourSyllabus.studyExamPrep': 'Laden Sie Ihren Lehrplan hoch, um das Beste aus Ihrer Studien- und Prüfungsvorbereitung herauszuholen',
  'hero.getRecommendationsOnWhat': 'Empfehlungen erhalten, was und wann man lernen sollte',
  'hero.noSyllabusSharingExamSchedule': 'Kein Lehrplan? Das Teilen Ihres Prüfungszeitplans ist ebenfalls möglich',
  'hero.selectDropSyllabusFile': 'Wählen Sie eine Lehrplandatei im PDF- oder DOCX-Format aus oder legen Sie sie ab.',
  'hero.uploadTxt': 'Hochladen',
  'hero.examName': 'Name der Prüfung',
  'hero.completeTxt': 'Abgeschlossen',
  'hero.exactTopicsChapter': 'Die genauen Themen für jedes Kapitel können Sie später festlegen.',
  'hero.uploadingDocument': 'Hochladen des Dokuments',
  'hero.activatingAIComponents': 'Aktivieren von KI-Komponenten',
  'hero.extractingClassSchoolData': 'Extrahieren von Klassen- und Schuldaten',
  'hero.extractingTextbookChapters': 'Extrahieren von Lehrbuchkapiteln',
  'hero.insideStudyExamPrepPack': 'Internes Studien- und Prüfungsvorbereitungspaket',
  'hero.userSubsGuidence': 'Sie haben unbegrenzten Zugriff auf Videolektionen, Lernpläne und Übungseinheiten, die auf Ihren Prüfungsplan zugeschnitten sind. Außerdem können Sie Fragen stellen und erhalten Feedback von Fachexperten.',
  'hero.examPrepTxt': 'Prüfungsvorbereitung',
  'hero.extractingDataFromSyllabus': 'Extrahieren von Daten aus dem Lehrplan...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': 'Interne Studien- und Prüfungsvorbereitung',
  'hero.testVideoLessons': 'Testen Sie Videolektionen, Lernpläne und Übungseinheiten, die auf Ihren Prüfungsplan zugeschnitten sind.',
  'header.qrCode.subHeaderTxt': 'Verwenden Sie diesen QR-Code, um sich schnell und sicher auf Ihrem Mobilgerät bei der Pearson+-App anzumelden.',
  'header.qrCode.dontHaveApp': 'Sie haben die App nicht?',
  'header.qrCode.footerTxt': 'Durch das Scannen dieses Codes werden Sie zum App Store oder zu Google Play weitergeleitet. Nachdem Sie die App heruntergeladen haben, melden Sie sich durch erneutes Scannen an.',
  'header.qrCode.errorMsg': 'Der QR-Code konnte nicht geladen werden. Schließen und erneut versuchen.',
  'hero.yourETextbook': 'Ihr eTextbuch',
  'onboard.addCourseWithEtextBundle': 'Kurs mit Studien- und Prüfungsvorbereitungspaket hinzufügen',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': 'Zugriff auf die Studien- und Prüfungsvorbereitung',
  'channel.genericBundle': 'Nutzen Sie Ihr Studien- und Prüfungsvorbereitungspaket zur Hilfe bei anderen Kursen',
  'channel.relatedBundle': 'Nutzen Sie Ihr Studien- und Prüfungsvorbereitungspaket auch zur Hilfe bei anderen Kursen',
  'onboard.courseAdded': 'Kurs hinzugefügt!',
  'hero.flashcards': 'Karteikarten',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use this QR code to open the Pearson+ app on your mobile device.',
  'print.qrCode.footer': 'Scanning this code will redirect you to the App store or Google Play. Once the app is installed, sign in to download and access your title.'
};
export default de;
