/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Spanish(Mexico) language
 *
 * @file es-mx.js
 * @author Manimaran.S
 */

const esmx = {
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% para descargar la aplicación en otro dispositivo e iniciar sesión automáticamente o para utilizar su código QR único para iniciar sesión de manera segura en la aplicación que instaló.',
  'moremenu.downloadAppNotDownload': '%Click here% para descargar la aplicación e iniciar sesión de manera segura con su código QR único',
  'channel.bundleTitle': 'Acceso al Paquete de estudio y preparación para exámenes para sus otros cursos',
  'channel.noBundletitle': 'Use el estudio y preparación para exámenes como apoyo para sus otros cursos',
  'onboard.welcome': 'Le damos la bienvenida a Pearson+',
  'onboard.welcomeCourse': 'Está en el lugar adecuado para acceder a <span>{COURSE_NAME}</span> y mucho más.',
  'onboard.welcomeCourseList': 'Su compra incluye lo siguiente:',
  'onboard.next': 'Siguiente',
  'onboard.studyPlan': 'Se creó el plan de estudio personalizado.',
  'onboard.addCourseAnother': '¿Quiere añadir otro curso?',
  'onboard.addCourseWithoutEtext': 'Añadir curso sin libro electrónico',
  'onboard.addCourseWithoutEtextBundle': 'Añadir curso con Estudio y preparación para exámenes',
  'onboard.doYouWantOtherCourse': '¿Quiere añadir otro curso?',
  'onboard.purchase': 'Ich muss ein eTextbook von Pearson kaufen',
  'onboard.mayBeLater': 'Quizás luego',
  'onboard.aboutCourse': 'Cuéntenos sobre su curso',
  'onboard.close': 'Close',
  'course.addTitle': 'Añadir curso',
  'bookshelf.TITLE': 'Mi estantería',
  'common.GO_HOME': 'Ir al inicio',
  'common.LOGOUT': 'Cerrar sesión',
  'common.PURCHASE': 'Comprar',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostrar tareas',
  'courses.toc.PUBLISH_MESSAGE': 'Los estudiantes verán sus cambios la próxima vez que abran el contenido.',
  'courses.toc.PUBLISH_SUCCESS': '¡Éxito!',
  'courses.WELCOME': 'Hola, {userName}. ¡Bienvenido al curso {courseName}!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Su cuenta no incluye acceso a este título. Si cree que recibió este mensaje por error, comuníquese con el soporte técnico de Pearson.',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'No se encontró la suscripción.',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Su suscripción no incluye acceso al texto electrónico; sin embargo, puede suscribirse ahora mismo con una tarjeta de crédito.',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'No se encontró la suscripción.',
  'foxit.ABOUT': 'Información',
  'foxit.COPY_RIGHT': 'PDF Reader activado por Foxit. Copyright (C) 2003-2019 por Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.ABOUT': 'Información',
  'menu.GET_THE_APP_DOWNLOAD': 'Descargar la aplicación móvil',
  'menu.AVAILABLE': 'También disponible',
  'menu.CONTACT_SUPPORT': 'Comunicarse con soporte',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Todos los derechos reservados.',
  'menu.FOXIT': 'Activada por Foxit',
  'menu.GET_LOOSE_LEAF': 'Obtener hoja suelta',
  'menu.PRINT_PAGE': 'Imprimir página',
  'menu.PRINT_VERSION': '¿Desea obtener la versión impresa?',
  'menu.PRIVACY_POLICY': 'Política de privacidad',
  'menu.SIGN_OUT': 'Cerrar sesión',
  'menu.TERMS_OF_USE': 'términos de uso',
  'menu.tooltip.SCHEDULE': 'Programa',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hola, {userName}',
  'device.SWAPPED_ALREADY': 'Ya ha hecho un intercambio este mes',
  'device.SWITCH_DEVICE': '¿Cambiar al dispositivo actual?',
  'device.SWITCH_ONCE': 'Dado que solo puede cambiar una vez al mes, no podrá deshacer esta acción.',
  'device.SEAT_TAKEN': 'Ese asiento está ocupado',
  'device.DISCONNECT_DEVICE': 'Solo puede iniciar sesión en 3 dispositivos. Para continuar aquí, desconecte otro dispositivo.',
  'device.ALERT_TEXT': 'Recuerde que solo puede cambiar los dispositivos una vez al mes.',
  'device.YOUR_MOJO': 'Está en camino hacia Mojo',
  'device.DISCONNECT': 'Desconectar',
  'device.CONTINUE': 'Continuar',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Regresar a inicio',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Tiene {time} para ingresar su código aquí',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Incorrecto: vuelva a verificar el código o solicite que se le reenvíe.',
  'device.MANY_ATTEMPTS': 'Realizó demasiados intentos, pruebe solicitar el reenvío del código',
  'device.EXPIRED': 'El tiempo ha expirado, pruebe solicitar el reenvío del código',
  'device.ACCESS_HEADER': '¡Ha ingresado!',
  'device.ACCESS_DESC': 'Su sesión se ha autenticado. ¿Quiere aprovechar a actualizar su contraseña?',
  'device.ACCESS_CHANGE_PASSWORD': 'Cambiar contraseña',
  'device.ACCESS_RESET_PASSWORD': 'Restablecer contraseña',
  'device.THREATONE_TITLE': '¿Está todo bien?',
  'device.THREATONE_DESC': '"Notamos una actividad sospechosa en su cuenta."',
  'device.THREATONE_DESC_TWO': 'Este es un buen momento para cambiar su contraseña.',
  'device.OKAY': 'Aceptar',
  'device.ACCOUNT_LOCK_DESC': 'Por seguridad, cerraremos la sesión. Le enviamos un enlace de restablecimiento de contraseña al correo electrónico asociado con su cuenta.',
  'device.ACCOUNT_LOCK_TITLE': 'Notamos una actividad sospechosa en su cuenta',
  'device.ACCOUNT_LOCK_TIMER': 'La sesión se cerrará en {time}',
  'device.MAY_BE_LATER': 'Quizás luego',
  'menu.myAccount': 'Mi cuenta',
  'menu.helpCenter': 'Centro de asistencia',
  'menu.preferencesCenter': 'Centro de preferencias',
  'menu.HELP_SUPPORT': 'Ayuda y soporte',
  'menu.FAQS': 'Preguntas frecuentes',
  'menu.CHAT_WITH_SUPPORT': 'Hable con soporte técnico',
  'menu.QUICK_WALKTHROUGH': 'Guía rápida',
  'menu.LEGAL': 'Legal',
  'menu.PRIVACY_NOTICE': 'Aviso de privacidad',
  'menu.TITLE_INFO': 'Información del título',
  'menu.PEARSON_ACCESSIBILITY' : 'Accesibilidad Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Registre {deviceLimit} dispositivos. Intercambie 1 al mes.',
  'menu.ACCESSIBILITY': 'Accesibilidad',
  'menu.ACCESSIBILITY_SUPPORT': 'Soporte de accesibilidad',
  'menu.DEVICES': 'Dispositivos recientes',
  'menu.THIS_DEVICE': 'Este dispositivo',
  "menu.MOJO_APP_INFO": "Visite la tienda de aplicaciones en su teléfono inteligente o tableta para usar Pearson+ sin conexión.",
  "menu.DEVICE_LABEL": "Dispositivo",
  'navigation.PAGE': 'Página',
  'launchcode.CREATEBY': 'Creado por:',
  'launchcode.sentvia': 'Enviado a través de:',
  'launchcode.CODEINLAST': 'Códigos de los últimos siete días:',
  'launchcode.TIMESENT': 'Hora de envío:',
  'launchcode.ACCOUNTEMAIL': 'Correo electrónico de la cuenta:',
  'launchcode.LAUNCHDIALOGTITLE': 'Registro de códigos de OTP',
  'menu.OTP_CODES': 'Códigos de OTP',
  'menu.LAUNCH_CODE': 'Iniciar el registro de códigos',
  'menu.SEND_CODE': 'Enviar un código nuevo al usuario por correo electrónico',
  'menu.SEND_CODE_PHONE': 'Enviar un código nuevo al usuario por mensaje de texto',
  'menu.COMMUNITY_GUIDELINES': 'Normas de la comunidad',
  'textmessage.TITLE': 'Introducir número de teléfono',
  'textmessage.PHONE_NUMBER': 'Número de teléfono',
  'textmessage.SEND_CODE': 'Enviar código',
  'textmessage.INVALID_NUMBER': 'El número de teléfono móvil no es válido.',
  'textmessage.SUCCESS_MESSAGE': 'Código enviado',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Buscar en mi lista',
  'remove.title': '¿Desea eliminar el título?',
  'remove.subTitle': 'Puede volver a recuperarlo más adelante, simplemente utilice la búsqueda para volver a agregarlo.',
  'remove.buttonText': 'Eliminar',
  'footer.downloadMobileApp': 'Descargar la aplicación móvil',
  'footer.termsOfUse': 'Términos de uso',
  'footer.privacy': 'Privacidad',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'No vendan mi información personal',
  'footer.accessibility': 'Accesibilidad',
  'footer.patentNotice': 'Aviso de patente',
  'footer.copyrights': 'Pearson Todos los derechos reservados.',
  'channel.videos': 'Videos',
  'channel.questions': 'Preguntas',
  'channel.descriptionA': 'Complemente su lectura con los Canales.',
  'channel.descriptionB': 'Obtenga ayuda sobre temas difíciles con los Canales.',
  'channel.openchannel': 'Abrir canal',
  'hero.continueReading': 'Continuar leyendo',
  'hero.openBook': 'Abrir libro',
  'hero.channel.open': 'Abrir canal',
  'hero.addATitle': 'Agregar un título',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Ver títulos inactivos',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Encuentre su libro electrónico'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Abrir el libro electrónico',
  'hero.openEtextBookV1': 'Abrir el libro electrónico',
  'hero.heroHeading': 'Porque está estudiando <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Paquete de estudio y preparación para exámenes',
  'hero.study.help': 'Obtenga ayuda para el estudio',
  'hero.study.jumpBackIn': 'Volver a acceder',
  'hero.study.numOfUsers': 'Actualmente, <b>{num_of_users}</b> estudiantes están usando Canales.',
  'hero.heroBook.sectionHeading': 'No se quede atascado en el curso',
  'hero.heroBook.channelsSectionHeading': 'Inicie una sesión de estudio nueva',
  'hero.heroBook.sectionHeadingNewUser': 'Encuentre ayuda para el estudio aquí',
  'hero.heroBook.sectionSubHeading': 'Analice temas difíciles con preguntas de práctica y explicaciones en video adaptadas a su curso.',
  'hero.heroChannel.cardsHeading': 'Continúe donde se haya quedado',
  'hero.marketingcard.subheading': 'Estudie de una manera más eficaz con videos en los que se analizan conceptos y problemas de práctica adicionales adaptados a su curso.',
  'hero.marketingcard1.copy.title': 'Aprenda con videos',
  'hero.marketingcard1.copy.description': 'Vea videos explicativos breves en los que se analizan temas complicados.',
  'hero.marketingcard2.copy.title': 'Problemas de práctica',
  'hero.marketingcard2.copy.description': 'Prepárese para su examen con miles de preguntas de examen y videos con soluciones.',
  'hero.marketingcard3.copy.title': 'Obtenga ayuda experimentada',
  'hero.marketingcard3.copy.description': 'Publique preguntas y reciba respuestas de expertos en el tema.',
  'hero.popularTopics.heading': 'Otros temas populares de este canal',
  'hero.popularChannels.heading': 'Canales populares',
  'hero.popularChannels.headingv1': '¿Está haciendo otro curso?',
  'hero.popularChannels.description': 'Obtenga ayuda para el estudio con los principales Canales.',
  'hero.popularChannels.descriptionv1': 'Seleccione un canal para obtener ayuda con otro curso.',
  'hero.popularChannels.descriptionv2': 'Explore temas populares de ayuda con el estudio.',
  'hero.startReading': 'Comenzar a leer',
  'hero.practice': 'Practicar',
  'hero.noTitleNoChannel.sectionHeading': 'Prepárese para los exámenes en tiempo récord',
  'hero.herobook.mappedChannelHeading': 'Ayuda con el estudio recomendada en este canal',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Atrás',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Videos breves que explican temas complicados',
  'hero.promotionalcard.list2': 'Miles de preguntas similares a las del examen',
  'hero.promotionalcard.list3': 'Reciba respuestas de expertos en la materia',
  'hero.studyandexamprep': 'Estudio y preparación para exámenes',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Leer más',
  'hero.subscriptionStatusTitle': 'Estado de suscripción a Pearson+',
  'hero.eTextbook': 'Libro electrónico',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Ir a la página',
  'subscription.eTextbook': 'Libro electrónico',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Paquete de estudio y preparación para exámenes',
  'subscription.studyNoBundle': 'Estudio y preparación para exámenes',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Continuar estudiando',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Encuentre su libro electrónico',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': '¿Desea eliminar el curso del panel?',
  'hero.removecourse.description': 'Siempre puede añadir este curso de nuevo al panel. Para ello, haga clic en "Añadir curso".',
  'hero.removecourse.primaryCtaText': 'Eliminar',
  'hero.removecourse.secondaryCtaText': 'Cancelar',
  'hero.search': 'Buscar',
  'hero.notes': 'Notas y puntos destacados',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Buscar libros de texto electrónicos',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Aprenda un nuevo idioma gratis',
  'hero.mondly.learnLang.title2': 'Aprenda un nuevo idioma gratis!',
  'hero.mondly.subTitle': 'Los usuarios de libros de texto electrónicos de Pearson obtienen tres meses gratis de Mondly de Pearson.',
  'hero.mondly.description': 'Most language learning apps only let you learn from English. But it\'s best to learn from your native language, so Mondly allows you to learn from any of our <strong>41 languages</strong>.',
  'hero.mondly.startLearningCTA': 'Comenzar a aprender',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Aprenda un idioma',
  'hero.subscriptionStatusTitle2': 'Sus recursos de Pearson+',
  'hero.extractingExamDetails': 'Extrayendo detalles de exámenes',
  'hero.finishingTheProcess': 'Finalizando el proceso',
  'hero.hereWhatWeGotFromYourSyllabus': 'Esto es lo que obtuvimos de su temario',
  'hero.school': 'Escuela:',
  'hero.class': 'Clase:',
  'hero.textbook': 'Libro de texto:',
  'hero.examSchedule': 'Calendario de exámenes:',
  'hero.confirm': 'Confirmar',
  'onboard.studyMaterials': 'Seleccione su materia y crearemos un tablero con material de estudio personalizado.',
  'hero.personalizedStudyPlan': 'Utilizaremos IA para ofrecerle un plan de estudio personalizado.',
  'hero.welcomeToP': '¡Le damos la bienvenida a P+!',
  'hero.learnYourWayAround': 'Tómese un minuto para familiarizarse, también puede retomarlo en otro momento en los ajustes de su cuenta.',
  'hero.weveCreatedADashboard': 'Hemos creado un tablero para su curso aquí.',
  'hero.youCanAddYourPearsonCourse': 'También puede añadir sus cursos que no sean de Pearson y probar material de estudio gratis.',
  'hero.getExamreadyWithVideoLessons': 'Prepárese para los exámenes con videos de lecciones y prácticas personalizadas para su curso.',
  'hero.youreTexbook': 'Puede encontrar su libro electrónico y todas sus herramientas aquí.',
  'hero.VideosAndPractice': 'Siempre hay más videos y preguntas de práctica disponibles en Estudio y preparación para exámenes.',
  'hero.pleaseSelectTheChapters': 'Seleccione los capítulos que se incluyen en este examen:',
  'hero.doYouWantToAddACourse': '¿Quiere añadir un curso?',
  'hero.whatsAvailableForYou': 'Opciones disponibles para usted',
  'hero.whatsAvailableForYouInPearson': 'Opciones disponibles para usted en Pearson+',
  'hero.startStudying': 'Comenzar a estudiar',
  'hero.tableOfContents': 'Índice',
  'hero.unlockYourStudyExamPrep': '¡Desbloquee su Paquete de estudio y preparación para exámenes para otros cursos ahora mismo!',
  'hero.getExamreadyForYou': 'Prepárese para los exámenes de sus otros cursos con Estudio y preparación para exámenes.',
  'hero.weVideoLessons': 'Tenemos videos de lecciones y prácticas personalizadas para sus necesidades.',
  'hero.tryForFree': 'Probar gratis',
  'hero.heroBook.sectionSubHeadingV1': 'Analice temas difíciles con preguntas de práctica y explicaciones en video adaptadas a su curso.',
  'hero.addCourseUsingStudyExamPrep': 'Añadir curso con Estudio y preparación para exámenes',
  'hero.for': 'Para',
  'header.menu.moreAtPearson': 'Más en Pearson',
  'hero.studyExamPrepPackForAllCourse': 'Paquete de estudio y preparación para exámenes para todos sus cursos',
  'hero.myETextbooks': 'Mis libros electrónicos',
  'header.continueInTheApp': 'Continuar en la aplicación',
  'hero.goToMyLabMastering': 'Ir a MyLab/Mastering',
  'hero.learnTxt': 'Aprender',
  'hero.aITutorTxt': 'Tutor con IA',
  'hero.exploreTxt': 'Explorar',
  'hero.studyExamPrepAllYourCourses': 'Estudio y preparación para exámenes para todos sus cursos',
  'hero.examSchedules': 'Calendario de exámenes',
  'hero.examSchedules2': 'Calendario de exámenes',
  'hero.uploadYourSyllabus': 'Cargar el temario',
  'hero.addAnExam': 'Añadir un examen',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Cargue su temario para aprovechar el Paquete de estudio y preparación para exámenes al máximo',
  'hero.UploadYourSyllabus.studyExamPrep': 'Cargue su temario para aprovechar el Estudio y preparación para exámenes al máximo',
  'hero.getRecommendationsOnWhat': 'Reciba recomendaciones sobre qué estudiar y cuándo hacerlo',
  'hero.noSyllabusSharingExamSchedule': '¿No tiene temario? También puede compartir su calendario de exámenes',
  'hero.selectDropSyllabusFile': 'Seleccione o suelte un documento con el temario en formato PDF o DOCX',
  'hero.uploadTxt': 'Cargar',
  'hero.examName': 'Nombre del examen',
  'hero.completeTxt': 'Completar',
  'hero.exactTopicsChapter': 'Podrá especificar los temas exactos de cada capítulo más adelante.',
  'hero.uploadingDocument': 'Cargando el documento',
  'hero.activatingAIComponents': 'Activando componentes de IA',
  'hero.extractingClassSchoolData': 'Extrayendo información de la clase y la escuela',
  'hero.extractingTextbookChapters': 'Extrayendo capítulos del libro de texto',
  'hero.insideStudyExamPrepPack': 'Dentro del Paquete de estudio y preparación para exámenes',
  'hero.userSubsGuidence': 'Tiene acceso ilimitado a videos de lecciones, planes de estudio y prácticas diseñadas para su calendario de exámenes. Además, puede hacer preguntas y recibir comentarios de expertos en la materia.',
  'hero.examPrepTxt': 'Preparación para exámenes',
  'hero.extractingDataFromSyllabus': 'Extrayendo información del temario...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'hero.insideStudyExamPrep': 'Dentro de Estudio y preparación para exámenes',
  'hero.testVideoLessons': 'Pruebe videos de lecciones, planes de estudio y prácticas diseñadas para su calendario de exámenes.',
  'header.qrCode.subHeaderTxt': 'Utilice este código QR para iniciar sesión en la aplicación Pearson+ de manera rápida y segura en su dispositivo móvil.',
  'header.qrCode.dontHaveApp': '¿No tiene la aplicación?',
  'header.qrCode.footerTxt': 'Escanee este código para ir a la App Store o a Google Play. Una vez que descargue la aplicación, inicie sesión volviendo a escanear el código.',
  'header.qrCode.errorMsg': 'No se cargó el código QR. Cierre e intente nuevamente.',
  'hero.yourETextbook': 'Su libro de texto electrónico',
  'onboard.addCourseWithEtextBundle': 'Añadir curso con el Paquete de estudio y preparación para exámenes',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': 'Acceder a estudio y preparación para exámenes',
  'channel.genericBundle': 'Use su paquete de estudio y preparación para exámenes como apoyo para sus otros cursos',
  'channel.relatedBundle': 'Use también su paquete de estudio y preparación para exámenes como apoyo para sus otros cursos',
  'onboard.courseAdded': 'Se añadió el curso.',
  'hero.flashcards': 'Tarjetas didácticas',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use this QR code to open the Pearson+ app on your mobile device.',
  'print.qrCode.footer': 'Scanning this code will redirect you to the App store or Google Play. Once the app is installed, sign in to download and access your title.'
};

export default esmx;
